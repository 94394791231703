import { Cookies } from 'react-cookie';
import moment from 'moment';
import $ from "jquery";

///////////For production/////////////////////////////////////////

var tenantName="platform_admin";
// var tenantName=localStorage.getItem("realm");
// var Expirytime = localStorage.getItem("_ET");
function ExpirytimeCheck () 
{


  
    const cookies = new Cookies();
    const RefreshToken = cookies.get("_RK");
    const Expirytime = cookies.get("_ET");
   
    //////////validate the life span of the token with current time///////////
 
    var currentTime = new moment(new Date()).unix();
   

        if (currentTime >=Expirytime) 
        {
     
          fetch('https://auth.dotstow.com/auth/realms/platform_admin/protocol/openid-connect/token', {
          //  fetch('http://keycloak-dotstow-prod-alb-1839268278.us-east-2.elb.amazonaws.com/auth/realms/platform_admin/protocol/openid-connect/token', {
            // fetch('http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth/realms/platform_admin/protocol/openid-connect/token', {
                method: 'POST',
                headers: {
                    //'Authorization': 'Bearer token',
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                body: "client_id=platform_admin_ui&grant_type=refresh_token&refresh_token=" + RefreshToken
            }).then((response) => response.json())
            
                .then((responseData) => 
                {
                    // cookies.remove("_AK");
                    // cookies.remove("_RK");
                    // cookies.remove("_ET");
                    cookies.set("_AK", responseData.access_token,{"path":"/",maxAge:"1704085200"});
                    cookies.set("_RK", responseData.refresh_token,{"path":"/",maxAge:"1704085200"});
                    
                    
                    let expiresInMS = responseData.expires_in;
                    const ms = expiresInMS * 1000;   // let say expiration in 60 s / 1 min
                    let now = new Date();
                    let expiresDateTime = new Date(now.getTime() + ms);

                    var expTime = new moment(expiresDateTime).unix();
                    cookies.set("_ET", expTime,{"path":"/",maxAge:"1704085200"});
                    // localStorage.setItem("_AT", responseData.access_token);
                     localStorage.setItem("_ET", expTime);
                     window.location.reload();
                    //  setCookie('secureKey', responseData.ac);
                }).catch(err => { console.log(err) })




        } else 
        {
          // console.log("Expirytime="+Expirytime)
     
  
          // cookies.set("_ET", parseInt(localStorage.getItem("_ET")),{"path":"/",maxAge:"1704085200"});
          cookies.remove("_AK");
          cookies.remove("_RK");
          cookies.remove("_ET");
         
          sessionStorage.clear();
          localStorage.clear();
    
          window.location.href = "/login";
        }
      
   
}
class Service {

   
  //////////////////////get customer list from cloud//////////////////////////
  listofcustomers(requestJson) {
  
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/customer/listCustomers", requestJson)
      .then(response => {  
        

        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
           if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }

        })
      .then(responseData => { return responseData;
       })
      .catch(error => 
      {
        
  
      //  if(error.messag.match("Unexpected token < in JSON at position 0"))
      //  {
      //   sessionStorage.clear();
      //   localStorage.clear();
      //  //navigate("/login");
      //    window.location.href="/login";
      //  }
        return error;
      });
  }
  //////////////////////get states from cloud//////////////////////////
  getstates(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/customer/getStates", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
       
        return error;
      });

  }
  //////////////////////get contact roles from cloud//////////////////////////
  getcontactRoles(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/contact/getContactRoles", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
      
        return error;
      });

  }
  //////////////////////add customer from cloud//////////////////////////
  add_customer(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/customer/addCustomer", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }
  //////////////////////get customer details from cloud//////////////////////////
  getCustomer_details(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/customer/getCustomerDetails", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////get vechill Type from cloud//////////////////////////
  getVehicles(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/getVehicleTypes", requestJson)
      .then(response => { 
         if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }
  //////////////////////update customer through the API//////////////////////////
  update_Customer_Details(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/customer/updateCustomer", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////getvechileList through the API//////////////////////////
  vechile_list(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/listVehicles", requestJson)
      .then(response => { 
         if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////getContactlist through the API//////////////////////////
  getContactList(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/contact/listContact", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////Add vehicle through the API//////////////////////////
  addVehicle(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/addVehicle", requestJson)
      .then(response => { 
         if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  updateVehicle(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/updateVehicle", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////GetTruckTypes through the API//////////////////////////
  getTruckType(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/getTruckTypes", requestJson)
      .then(response => { 
         if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////getTrailerSubTypes Sub through the API//////////////////////////
  getTrailerSubTypes(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/getTrailerSubTypes", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////getTankerSubTypes Sub through the API//////////////////////////
  getTankerSubTypes(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/getTankerSubTypes", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }


  //////////////////////Add contact through the API//////////////////////////
  addContacts(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/contact/addContact ", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////Add contact through the API//////////////////////////
  // listFleetOwnerVehicles
  customer_vechile_list(requestJson) {
   // return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/listFleetOwnerVehicles ", requestJson)
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/getCustomerVehicles ", requestJson)
      .then(response => { 
         if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }


    // listFleetOwnerVehicles
    customer_vechile_list_fleetowner(requestJson) {
       return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/listFleetOwnerVehicles ", requestJson)
      // return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/getCustomerVehicles ", requestJson)
         .then(response => { 
       if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
         .then(responseData => { return responseData; })
         .catch(error => {
           
           return error;
         });
   
     }

  //////////////////////Update contact through the API//////////////////////////
  update_contactAPI(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/contact/updateContact", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////Get Vehicle through the API//////////////////////////
  getVehicle_Details(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/getVehicleDetails", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////getListOfDocuments through the API//////////////////////////
  getListOfDocuments(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/document/listDocuments ", requestJson)
      .then(response => { 
         if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////Get customer document through the API//////////////////////////
  getCustomerDocuments(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/document/getCustomerDocuments ", requestJson)
      .then(response => { 
         if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////getDocumentDetails through the API//////////////////////////
  getDocumentDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/document/getDocumentDetails ", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

   //////////////////////getuserDocumentDetails through the API//////////////////////////
   getUserDocumentDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/user/getUserDocument", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////addd documents through the API//////////////////////////
  getDocumentDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/document/getDocumentDetails ", requestJson)
      .then(response => { 
         if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////get documents category through the API//////////////////////////
  getDocumentCategories(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/document/getDocumentCategories ", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////add documents  through the API//////////////////////////
  addDocuments(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/document/addDocument", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////update documents  through the API//////////////////////////
  updatedocument(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/document/updateDocument", requestJson)
      .then(response => {
          if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////get vehicle documents  through the API//////////////////////////
  getVehicle_Docs(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/document/getVehicleDocuments", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////add inspection through the API//////////////////////////
  addInspection(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/inspection/addInspection", requestJson)
      .then(response => { 
         if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }
  //////////////////////certify inspection through the API//////////////////////////
  certifyInspection(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/inspection/certifyInspection", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }
  //////////////////////get inspection type through the API//////////////////////////
  getInspectionTypeS(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/inspection/getInspectionTypes", requestJson)
      .then(response => { 
         if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////get inspection List through the API//////////////////////////
  getInspectionList(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/inspection/listInspections", requestJson)
      .then(response => { 
         if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  getInspectionDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/inspection/getInspection", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  getInspectionDocumentDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/inspection/getInspectionDocument", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  ////////////////////get///////////////////////////////////
  getInspectionCountByType(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/dashboard/getInspectionCountByType", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  ////////////////////get///////////////////////////////////
  dashBoardAggregations(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/dashboard/dashBoardAggregations", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }
  ////////////////////get///////////////////////////////////
  getFleetDashBoardAggregations(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/dashboard/getFleetDashBoardAggregations", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }
  ////////////////////get///////////////////////////////////
  atgarageInspectionForinspection(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/listOfAssginedVehicles", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  ////////////////////get///////////////////////////////////
  upcomingremiders(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/reminder/listUpcomingReminders", requestJson)
      .then(response => { 
         if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }
  ////////////////////get///////////////////////////////////
  listofDues(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/reminder/listDue", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }


  ////////////////////assigned Vehicles///////////////////////////////////
  listOfAssginedVehicles(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/listOfAssginedVehicles", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  ////////////////////assigned Vehicles///////////////////////////////////
  getExpiringDocuments(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/document/getExpiringDocuments ", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  //////////////////////Add Dispatcher through the API//////////////////////////
  addUser(requestJson) 
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/user/addUser", requestJson)
      .then(response => 
      {  
       
        if(response.status===401)
        {
          ExpirytimeCheck() 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } 
      
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
      
  }

  updateUser(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/user/updateUser", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
      
  }

  

  getInspectionDocumentSubCategories(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/document/getInspectionDocumentSubCategories", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  listVendors(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vendor/listVendors", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  assignToGarage(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vehicle/assignToGarage", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }
  listUsersByRole(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/user/listUsersByRole", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  getVehicleDocumentSubCategories(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/document/getVehicleDocumentSubCategories", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  addVendor(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/vendor/addVendor", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }
  getAccountType(requestJson) 
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/user/getAccountType", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });

  }

  getInspectionDocumentSubCategories(requestJson) 
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/document/getInspectionDocumentSubCategories", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }

  getInspectionTypeForAssignToGarage(requestJson) 
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/inspection/getInspectionTypeForAssignToGarage", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }
  
  getUserDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/user/getUserDetails", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }

  addInspectionDocument(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/document/addInspectionDocument", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }



    DriverRole(requestJson) 
    {
      return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/user/listRoles", requestJson)
        .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
        .then(responseData => { return responseData; })
        .catch(error => {
          
          return error;
        });
   }

  listRoles(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/user/listStaffRolesByTenantType", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
    }

  remindersAll(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/reminder/listAllReminders", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
    }

    listAllGarageReminders(requestJson) {
      return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/reminder/listAllGarageReminders", requestJson)
        .then(response => {  if(response.status===401)
          {
             sessionStorage.clear();
             localStorage.clear();
             window.location.href="/login";
          }
          else if(response.status===200)
          {
            return response.json();
          }else if(response.status===500)
          {
            sessionStorage.clear();
            localStorage.clear();
            window.location.href="/login";
  
          }else 
          {
            sessionStorage.clear();
            localStorage.clear();
            window.location.href="/login";
  
          } })
        .then(responseData => { return responseData; })
        .catch(error => {
          
          return error;
        });
      }
    
  listStaff(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/user/listStaff", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }
  
  resetuserPassword(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/user/resetPassword", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }

  getUserDetails(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/user/getUserDetails", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }
  listUsers(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/user/listUsers", requestJson)
      .then(response => {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } 
      })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }
  customerCount(requestJson) {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/dashboard/customerCount", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }
  vehicleCount(requestJson) 
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/dashboard/vehicleCount", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }
  documentCount(requestJson) 
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/dashboard/documentCount", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }

  getGarageDashBoardAggregations(requestJson) 
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/dashboard/getGarageDashBoardAggregations", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }

  getTopTenReminders(requestJson) 
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/reminder/getTopTenReminders", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }
  getFleetOwnerDetails(requestJson) 
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/settings/updateFleetDetails", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }

  listInspectionsToBeApproved(requestJson) 
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/inspection/listInspectionsToBeApproved", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }

  updateGarageDetails(requestJson) 
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/settings/updateGarageDetails", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }
  
  updateFleetDetails(requestJson) 
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/settings/updateFleetDetails", requestJson)
      .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
  }


  getUserByEmailId(requestJson)
  {
    
        return fetch(process.env.React_App_AWS_API + "/tenant/dotstow/user/getUserByEmailId", requestJson)

       .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
        .then(responseData => { return responseData; })
        .catch(error => {
        
          return error;
        });
      
    

  }
  garageUpcomingOverdueList(requestJson) 
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/reminder/listUpcomingReminders", requestJson)

      .then(response => {  
        
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } 
      
      }
        
        )
      .then(responseData => { return responseData; })
      .catch(error => {
        
        return error;
      });
    }

    //Inspection Orders list

    listInspectionOrders(requestJson)
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/inspection/listInspectionOrders", requestJson)

        .then(response => { return response.json();})
        .then(responseData => { return responseData; })
        .catch(error => {
          // alert(error);
          return error;
        });

   
  }
  adcustomerNotes(requestJson)
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/notes/addNote", requestJson)

        .then(response => { return response.json();})
        .then(responseData => { return responseData; })
        .catch(error => {
          // alert(error);
          return error;
        });

   
  }
  listOfNotes(requestJson)
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/notes/listNotes", requestJson)

        .then(response => { return response.json();})
        .then(responseData => { return responseData; })
        .catch(error => {
          // alert(error);
          return error;
        });

   
  }
  sendtoCustomerAPI(requestJson)
  {
    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/document/emailDocuments", requestJson)

        .then(response => { return response.json();})
        .then(responseData => { return responseData; })
        .catch(error => {
          // alert(error);
          return error;
        });

   
  }
  getCustomerContacts(requestJson)
  {
     return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/contact/getCustomerContacts", requestJson)

        .then(response => { return response.json();})
        .then(responseData => { return responseData; })
        .catch(error => {
          // alert(error);
          return error;
        });
 
  }

  listTaskOrder(requestJson)
  {
     return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/taskOrder/listTaskOrders", requestJson)

        .then(response => { return response.json();})
        .then(responseData => { return responseData; })
        .catch(error => {
          // alert(error);
          return error;
        });
 
  }
  createTaskOrder(requestJson)
  {
     return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/taskOrder/addTaskOrder", requestJson)

        .then(response => { return response.json();})
        .then(responseData => { return responseData; })
        .catch(error => {
          // alert(error);
          return error;
        });
 
  }
  updateTaskOrders(requestJson)
  {
     return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/taskOrder/updateTaskOrder", requestJson)

        .then(response => { return response.json();})
        .then(responseData => { return responseData; })
        .catch(error => {
          // alert(error);
          return error;
        });
 
  }
  completeTaskOrders(requestJson)
  {
     return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/inspection/completeIO", requestJson)

        .then(response => { return response.json();})
        .then(responseData => { return responseData; })
        .catch(error => {
          // alert(error);
          return error;
        });
 
  }
  sendMessageToAdmin(requestJson)
  {
     return fetch("https://iuk5hrra94.execute-api.us-east-2.amazonaws.com/default/DotStowCorporateLeads", requestJson)

        .then(response => { return response.json();})
        .then(responseData => { return responseData; })
        .catch(error => {
          // alert(error);
          return error;
        });
 
  }
  /////////////Delete documets from DB////////////////////////////
  deleteDocumentsFromDb(requestJson)
  {

    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/document/deleteDocument", requestJson)

    .then(response => { return response.json();})
    .then(responseData => { return responseData; })
    .catch(error => {
      // alert(error);
      return error;
    });

  }

    /////////////Delete documets from DB////////////////////////////
    deleteInspectionDocumentsFromDb(requestJson)
    {
  
      return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/inspection/deleteInspection", requestJson)
  
      .then(response => { return response.json();})
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        return error;
      });
  
    }

    /////////////LIst All Status ////////////////////////////
    listAllStatus(requestJson)
    {
  
      return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/taskOrder/listAllStatus", requestJson)
  
      .then(response => { return response.json();})
      .then(responseData => { return responseData; })
      .catch(error => {
        // alert(error);
        return error;
      });
  
    }

     /////////////List Clients ////////////////////////////
     listofclients(requestJson)
     {
   
       return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/settings/listTenants", requestJson)
   
       .then(response => 
      { 
         
          if(response.status===401)
          {
            sessionStorage.clear();
            localStorage.clear();
            window.location.href="/login";
          }
          else if(response.status===200)
          {
            return response.json();
          }else if(response.status===500)
          {
            sessionStorage.clear();
            localStorage.clear();
            window.location.href="/login";

          }else 
          {
            sessionStorage.clear();
            localStorage.clear();
            window.location.href="/login";

          } 
      
      
      })
       .then(responseData => { 
         
        return responseData; 
      
      })
       .catch(error => {
         // alert(error);
         return error;
       });
   
     }
     
    //  getClientDetails(requestJson)
    //  {
   
    //    return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/settings/listTenants", requestJson)
   
    //    .then(response => { return response.json();})
    //    .then(responseData => { return responseData; })
    //    .catch(error => {
    //      // alert(error);
    //      return error;
    //    });
   
    //  }

     add_client(requestJson)
     {
   
       return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/settings/addTenant", requestJson)
   
       .then(response => 
      {
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } 

       })
       .then(responseData => { return responseData; })
       .catch(error => {
         // alert(error);
         return error;
       });
   
     }
     updateTenant(requestJson)
     {
   
       return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/settings/updateTenant", requestJson)
   
       .then(response => { 
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } 

       })
       .then(responseData => { return responseData; })
       .catch(error => {
         // alert(error);
         return error;
       });
   
     }

     inActivateTenant(requestJson)
     {
   
       return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/settings/inActivateTenant", requestJson)
   
       .then(response => 
      {  
        if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
       .then(responseData => { return responseData; })
       .catch(error => {
         // alert(error);
         return error;
       });
   
     }

     inactivateUser(requestJson)
     {
   
       return fetch(process.env.React_App_AWS_API + "/tenant/"+tenantName+"/user/inactivateUser", requestJson)
   
       .then(response => {  if(response.status===401)
        {
          ExpirytimeCheck () 
        }
        else if(response.status===200)
        {
          return response.json();
        }else if(response.status===500)
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        }else 
        {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href="/login";

        } })
       .then(responseData => { return responseData; })
       .catch(error => {
         // alert(error);
         return error;
       });
   
     }
     
     
  
}

export default new Service();


