import Keycloak from "keycloak-js";
var realmName="";

if(localStorage.getItem("realm")!==null)
{
  realmName=localStorage.getItem("realm");
}
else
 {
  //  realmName="platform_admin";
  //  realmName="Master";
      // realmName="Bradshaw";
    //  realmName="Master";
 }
///for logout/////////
// export const logouturl= "http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth";
 ///keycloack config setupdone////////////

//////////admin production keyclock jan 2023 updated/////////////
 export const keycloak = new Keycloak({
    url: "https://auth.dotstow.com/auth",
    realm: "platform_admin",
    clientId: "platform_admin_ui"
  })
/// qa//////////////////
  // export const keycloak = new Keycloak(
  // {
  //       url: "http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth",
  //       realm: "platform_admin",
  //       clientId: "platform_admin_ui"
  // })
  //////////UAT FOR KEYCLOACK///////////////////
// export const keycloak = new Keycloak({
//   url: "http://keycloak-dotstow-uat-alb-127029246.us-east-2.elb.amazonaws.com/auth",
//   realm:realmName,
//   clientId: "dotstow_ui"
// })

  ////////  Production FOR KEYCLOACK///////////////////
// export const keycloak = new Keycloak({
//   url: "http://keycloak-dotstow-prod-alb-1839268278.us-east-2.elb.amazonaws.com/auth",
//   realm: "platform_admin",
//   clientId: "platform_admin_ui"
// })


