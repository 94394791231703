import React, { history, useEffect, useState, useMemo } from "react";

const Terms = () => {

    return (
        <>

            <div class="pp w-100" style={{ marginTop: "-60px", background:"white" }}>
                <header >
                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <div class="container" style={{ maxWidth: "1200px", margin: "auto" }}>
                            <a class="navbar-brand" href="#"><img src="/img/logo-dotstow.svg" style={{ width: "90px" }} /></a>
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarSupportedContent">

                                <a class="btn btn-outline-primary text-uppercase" href="/login" >Home</a>
                                {/* <a href="#" data-toggle="modal" data-target="#edit_Contact" >Edit</a> */}
                            </div>
                        </div>
                    </nav>
                </header>

                <section class="home_banner_wrap">
                    <section class="cont-head">
                        <div class="container" >
                            <div class="row">
                                <div class="col-12 m-auto"  >
                                    <div>
                                        <h2>Terms and Conditions</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="container">
                       

                                <p><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><strong><span data-contrast="auto">Agreement between User and www.dotstow.com</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">Welcome to www.dotstow.com. The www.dotstow.com website (the "Site") is comprised of various web pages operated by DOTStow, LLC. www.dotstow.com is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of www.dotstow.com constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">www.dotstow.com is an E-Commerce Site.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">DotStow is a safe and secure document storage platform. DotStow provides a digital means of communication between all parties involved in the transportation industry, from fleet owners to repair facilities.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><strong><span data-contrast="auto">Electronic Communications</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">Visiting www.dotstow.com or sending emails to DOTStow, LLC constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><strong><span data-contrast="auto">Your Account</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that DOTStow, LLC is not responsible for third party access to your account that results from theft or misappropriation of your account. DOTStow, LLC and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><strong><span data-contrast="auto">Children Under Thirteen</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">DOTStow, LLC does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use www.dotstow.com only with permission of a parent or guardian.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><strong><span data-contrast="auto">Cancellation/Refund Policy</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">The request to cancel a subscription needs to be handled with a written notice 60 days prior to the end of the contract. Refunds will be handled on a case by case but are not guaranteed. Please contact info@dotstow.com with any questions.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><strong><span data-contrast="auto">Links to Third Party Sites/Third Party Services</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">www.dotstow.com may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of DOTStow, LLC and DOTStow, LLC is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. DOTStow, LLC is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by DOTStow, LLC of the site or any association with its operators.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">Certain services made available via www.dotstow.com are delivered by third party sites and organizations. By using any product, service or functionality originating from the www.dotstow.com domain, you hereby acknowledge and consent that DOTStow, LLC may share such information and data with any third party with whom DOTStow, LLC has a contractual relationship to provide the requested product, service or functionality on behalf of www.dotstow.com users and customers.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><strong><span data-contrast="auto">No Unlawful or Prohibited Use/Intellectual Property&nbsp;</span></strong><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">You are granted a non-exclusive, non-transferable, revocable license to access and use www.dotstow.com strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to DOTStow, LLC that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of DOTStow, LLC or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. DOTStow, LLC content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of DOTStow, LLC and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of DOTStow, LLC or our licensors except as expressly authorized by these Terms.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><strong><span data-contrast="auto">International Users</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">The Service is controlled, operated and administered by DOTStow, LLC from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the DOTStow, LLC Content accessed through www.dotstow.com in any country or in any manner prohibited by any applicable laws, restrictions or regulations.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><strong><span data-contrast="auto">Indemnification</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">You agree to indemnify, defend and hold harmless DOTStow, LLC, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. DOTStow, LLC reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with DOTStow, LLC in asserting any available defenses.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><strong><span data-contrast="auto">Arbitration</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator. This arbitration provision shall survive the termination of these Terms and Conditions.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><strong><span data-contrast="auto">Class Action Waiver</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you and DOTStow, LLC agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><strong><span data-contrast="auto">Liability Disclaimer</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. DOTSTOW, LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">DOTSTOW, LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. DOTSTOW, LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL DOTSTOW, LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF DOTSTOW, LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><strong><span data-contrast="auto">Termination/Access Restriction&nbsp;</span></strong><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">DOTStow, LLC reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Ohio and you hereby consent to the exclusive jurisdiction and venue of courts in Ohio in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">You agree that no joint venture, partnership, employment, or agency relationship exists between you and DOTStow, LLC as a result of this agreement or use of the Site. DOTStow, LLC's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of DOTStow, LLC's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by DOTStow, LLC with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and DOTStow, LLC with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and DOTStow, LLC with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><strong><span data-contrast="auto">Changes to Terms</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">DOTStow, LLC reserves the right, in its sole discretion, to change the Terms under which www.dotstow.com is offered. The most current version of the Terms will supersede all previous versions. DOTStow, LLC encourages you to periodically review the Terms to stay informed of our updates.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><strong><span data-contrast="auto">Contact Us</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">DOTStow, LLC welcomes your questions or comments regarding the Terms:&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">DOTStow, LLC&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">11708 Ashville Pike&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">Ashville, Ohio 43103&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">Email Address:&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">bbaker@dotstow.com&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">Telephone number:&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">16145588512&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto">Effective as of October 24, 2022&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                                <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>

                          
                    </div>
                </section>

                <footer class="footer_wrap">
                    <div class="container">
                        <div class="row d-flex justify-content-between">
                            <div class="col-sm-12 col-md-6">
                                <div class="copyrights">
                                    <p>© 2022 DotStow. All rights reserved. <a href="/privacy">Privacy Policy</a>  <a href="/terms">Terms & Conditions</a></p>
                                </div>
                            </div>
                            <div class="col-sm-auto col-md-auto ">
                                <div class="social_icons">
                                    <a href="#" class="svg_icon">
                                        <svg height="20" viewBox="0 0 9.327 20.073">
                                            <path d="M27.309,12.6H24.133V10.512a.848.848,0,0,1,.884-.965h2.242V6.108L24.171,6.1A3.909,3.909,0,0,0,19.964,10.3V12.6H17.982V16.14h1.982V26.169h4.169V16.14h2.813Z" transform="translate(-17.982 -6.096)" />
                                        </svg>
                                    </a>
                                    <a href="#" class="svg_icon">
                                        <svg width="20" viewBox="0 0 24.7 20.073">
                                            <path d="M28.274,12.7a10.083,10.083,0,0,1-2.911.8,5.077,5.077,0,0,0,2.228-2.8,10.144,10.144,0,0,1-3.218,1.23,5.072,5.072,0,0,0-8.635,4.622A14.388,14.388,0,0,1,5.294,11.254a5.074,5.074,0,0,0,1.568,6.766,5.053,5.053,0,0,1-2.3-.634c0,.021,0,.043,0,.064a5.072,5.072,0,0,0,4.065,4.969A5.1,5.1,0,0,1,7.3,22.6a5.021,5.021,0,0,1-.954-.091,5.073,5.073,0,0,0,4.734,3.519,10.226,10.226,0,0,1-7.5,2.1A14.412,14.412,0,0,0,25.76,15.981q0-.329-.014-.656A10.264,10.264,0,0,0,28.274,12.7Z" transform="translate(-3.574 -10.326)" />
                                        </svg>
                                    </a>
                                    <a href="#" class="svg_icon">
                                        <svg width="20" viewBox="0 0 20.073 20.073">
                                            <path data-name="Path 72" d="M20.58,16.7a5.18,5.18,0,1,0,5.18,5.18A5.19,5.19,0,0,0,20.58,16.7Zm0,8.5A3.319,3.319,0,1,1,23.9,21.88,3.334,3.334,0,0,1,20.58,25.2Z" transform="translate(-10.544 -11.884)" />
                                            <circle data-name="Ellipse 18" cx="1.174" cy="1.174" r="1.174" transform="translate(14.245 3.521)" />
                                            <path data-name="Path 73" d="M21.854,6.459A5.764,5.764,0,0,0,17.6,4.8H9.268A5.544,5.544,0,0,0,3.4,10.668v8.3a5.823,5.823,0,0,0,1.7,4.33,5.906,5.906,0,0,0,4.209,1.578h8.256a5.976,5.976,0,0,0,4.249-1.578A5.794,5.794,0,0,0,23.473,19V10.668A5.8,5.8,0,0,0,21.854,6.459ZM21.692,19a3.954,3.954,0,0,1-1.174,2.954,4.183,4.183,0,0,1-2.954,1.052H9.309a4.183,4.183,0,0,1-2.954-1.052,4.068,4.068,0,0,1-1.093-2.995v-8.3A4.035,4.035,0,0,1,6.354,7.714,4.115,4.115,0,0,1,9.309,6.662h8.337A4.035,4.035,0,0,1,20.6,7.754a4.176,4.176,0,0,1,1.093,2.914V19Z" transform="translate(-3.4 -4.8)" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </footer>
            </div>
        </>)
}

Terms.propTypes = {};

Terms.defaultProps = {};

export default Terms;