import React, { history, useEffect, useState, useMemo } from "react";

import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import { useLoading } from "react-hook-loading";
import Swal from "sweetalert2";
import $ from "jquery";
import Search from "../../../utility/search";
import Pagination from "../../../utility/pagination";
import userList from "./userList";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import UsNumberValidate from "../../../utility/usNumberValidate";
import { useNavigate, useParams } from "react-router-dom";
import Cropper from "react-cropper";
import { loadingComponent } from "../../../utility/Loading";
import Getstates from "../../../utility/getstates";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
const Settings = () => {
   const [states, setState] = useState([]);
   const [profileDetails, setProfileDetails] = useState("");
   const [profilePic, setProfilePic] = useState("");
   const [loading, setLoading] = useLoading("Please wait...");
   const [cropper, setCropper] = useState();
   const [image, setImage] = useState();
   const [itemName, setFieldName] = useState('');

   const [dispatcherList, setdispathcerlist] = useState();
   const [displayPage, setdisplayPage] = useState([]);
   const [ITEMS_PER_PAGE, setitemperpage] = useState(25);
   const [currentPage, setCurrentPage] = useState(1);
   const [totalItems, setTotalItems] = useState(0);
   const [totalRecors, setTotalRecord] = useState([]);
   const [totalpages, settotalPages] = useState();
   const [searchtxt, setsearchText] = useState("");

   const navigate = useNavigate();
   const [stateIdC, setStateIdC] = useState([]);
   // var navigationfrom = sessionStorage.getItem("naviationfrom");
   const [roles, setRoles] = useState("");

   $(document).ready(function () {
      $("#zipcode_e").keypress(function (e) {
         if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
            return false;
         }

      });
   });

   useEffect(() => {
      Getstates(setState);
      $('#phoneMobile').usPhoneFormat();

      IfitIsCustomer();

      setRoles(localStorage.getItem("Userroles"));
      userList("", "1", ITEMS_PER_PAGE, "", "", setdispathcerlist, setdisplayPage, "displayItems", setCurrentPage, setTotalRecord, setTotalItems, settotalPages, searchtxt);
      $('#display_pages_in_items').on('change', function () {

          setitemperpage(this.value);
          userList("", "1", this.value, "", "", setdispathcerlist, setdisplayPage, "displayItems", setCurrentPage, setTotalRecord, setTotalItems, settotalPages, searchtxt);
       });


   }, []);
   const redirect = (page) => {

      navigate(page);

   }
   // const onChange = (e) => {
   //    e.preventDefault();
   //    let files;
   //    if (e.dataTransfer) {
   //       files = e.dataTransfer.files;
   //    } else if (e.target) {
   //       files = e.target.files;
   //    }
   //    const reader = new FileReader();
   //    reader.onload = () => {
   //       setImage(reader.result);
   //    };
   //    reader.readAsDataURL(files[0]);
   // };
   const onChange = (e) => {

      // const MIN_FILE_SIZE = 2048 // 2MB
      // const fileSizeKiloBytes=Math.floor(e.target.files[0].size/1024); //

      // if(fileSizeKiloBytes <= MIN_FILE_SIZE){
         
      e.preventDefault();
      let files;
      var ext = e.target.value.split('.').pop();
      var allowedExtensions = /(\jpg|\png|\jpeg)$/i;

      if (!allowedExtensions.exec(ext)) {

         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Invalid file type",
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {

               e.target.value = '';
               return false;
            }
         });

      } else {
         $('#selectedImage').show();
         $('#DB_Image').hide();
         $('#uploadImageDisable').prop('disabled', false);
         if (e.dataTransfer) {
            files = e.dataTransfer.files;
         } else if (e.target) {
            files = e.target.files;
         }
         const reader = new FileReader();
         reader.onload = () => {
            setImage(reader.result);
         };
         reader.readAsDataURL(files[0]);

      }
   // }else{
   //    Swal.fire({
   //       position: 'center',
   //       icon: 'error',
   //       title: "",
   //       text: "Max file size is 2MB",
   //       showConfirmButton: true

   //    }).then((result) => {
   //    });   
   // }

   };
   const {
      register,
      formState: { errors },
      handleSubmit
   } = useForm({
      criteriaMode: "all", mode: "onKeyup", defaultValues: { dotNumber: "123", name: "name", ctNumber: "1234", addressLine1: "ohio", phoneMobile: "123-456-7890", email: "text@mailnator.com" },
   });

   const updateProfileImage = () => {

      $("#loadingforsubmit").show();
      document.getElementById("btncloseImage").click();

      var requestObjectCustomer =
      {


         "id": $("#accountId").val(),
         "dotNumber": $("#dotNumber").val(),
         "ctNumber": $("#ctNumber").val(),
         "name": $("#name").val(),
         "email": $("#email").val(),
         "phoneBusiness": $("#phoneMobile").val(),
         "addressLine1": $("#addressLine1").val(),
         "addressLine2": $("#addressLine2").val(),
         "city": $("#city_e").val(),
         "stateId": $("#state_e").val(),
         "zip": $("#zipcode_e").val(),
         "logByteString": cropper.getCroppedCanvas() ? cropper.getCroppedCanvas().toDataURL().replace("data:image/png;base64,", "") : "",

         "logoName": cropper.getCroppedCanvas() ? parseInt(Math.random() * 1000000000) : "",

         "logoExtension": cropper.getCroppedCanvas() ? "png" : ""

      }

      var requestOptions = requestObject.RequestHeader(requestObjectCustomer);
      services.updateGarageDetails(requestOptions).then((res) => {
         // setLoading(false);
         $("#loadingforsubmit").hide();
         if (res.success) {
            Swal.fire({
               position: 'center',
               icon: 'success',
               title: "",
               text: res.message,
               showConfirmButton: true

            }).then((result) => {
               //window.location.reload();
               IfitIsCustomer();
            });

         }
         else {

            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: res.message,
               showConfirmButton: true

            }).then((result) => {
               IfitIsCustomer();

            });

         }



      });


   }


   const updateProfile = () => {


      document.getElementById("profileModelClose").click();
      // setLoading(true);
      $("#loadingforsubmit").show();
      var customerListReq =
      {

         // "id":sessionStorage.getItem("userId"),
         "id": $("#accountId").val(),
         "dotNumber": $("#dotNumber").val(),
         "ctNumber": $("#ctNumber").val(),
         "name": $("#name").val(),
         "email": $("#email").val(),
         "phoneBusiness": $("#phoneMobile").val(),
         "addressLine1": $("#addressLine1").val(),
         "addressLine2": $("#addressLine2").val(),
         "city": $("#city_e").val(),
         "stateId": $("#state_e").val(),
         "zip": $("#zipcode_e").val(),
         "logByteString": image.replace("data:image/png;base64,", "") ? image.replace("data:image/png;base64,", "") : "",

         "logoName": image.replace("data:image/png;base64,", "") ? parseInt(Math.random() * 1000000000) : "",

         "logoExtension": image.replace("data:image/png;base64,", "") ? "png" : ""

      };



      var requestOptions = requestObject.RequestHeader(customerListReq);
      services.updateGarageDetails(requestOptions).then((res) => {
         $("#loadingforsubmit").hide();
         // setLoading(false);
         if (res.success) {
            Swal.fire({
               position: 'center',
               icon: 'success',
               title: "",
               text: res.message,

               showConfirmButton: true

            }).then((result) => {
               if (result.isConfirmed) {
                  IfitIsCustomer();
                  //   window.location.reload();

                  // $(".media-body").load(window.location.reload() + " .media-body");

               }
            });
         }
         else {
            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: res.message,

               showConfirmButton: true

            }).then((result) => {
               if (result.isConfirmed) {

               }
            });
         }

      });

   }

   const resetpassword = () => {
      var currentPassword = $("#currentPassword").val();
      var confirmPassword = $("#confirmPassword").val();
      var newPassword = $("#newPassword").val();
      if (confirmPassword.match(newPassword)) {

         var resetPwd =
         {

            "currentPassword": currentPassword,
            "newPassword": newPassword

         };

         // setLoading(true);
         $("#loadingforsubmit").show();
         var requestOptions = requestObject.RequestHeader(resetPwd);
         services.getCustomer_details(requestOptions).then((res) => {
            // setLoading(false);
            $("#loadingforsubmit").hide();
            if (res.success) {
               Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: "",
                  text: res.message,

                  showConfirmButton: true

               }).then((result) => {
                  if (result.isConfirmed) {

                  }
               });

            } else {

               Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: "",
                  text: res.message,

                  showConfirmButton: true

               }).then((result) => {
                  if (result.isConfirmed) {

                  }
               });

            }

         });

      } else {


         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Password mismatch",

            showConfirmButton: true

         }).then((result) => {
            if (result.isConfirmed) {

            }
         });

      }



   }


   const IfitIsCustomer = () => {
      var customerListReq =
      {



      };



      var requestOptions = requestObject.RequestHeader(customerListReq);
      services.getAccountType(requestOptions).then((res) => {
         $("#loading").hide();
         sessionStorage.setItem("comapnyName", res.rows ? res.rows.name : "");
         $("#comapnyName").html(res.rows ? res.rows.name : "")
         $("#dotNumber").val(res.rows ? res.rows.dotNumber : "");
         $("#accountId").val(res.rows ? res.rows.id : "");
         $("#ctNumber").val(res.rows ? res.rows.ctNumber : "");
         $("#name").val(res.rows ? res.rows.name : "");
         $("#email").val(res.rows ? res.rows.email : "");
         $("#phoneMobile").val(res.rows ? res.rows.phoneBusiness : "");
         $("#city_e").val(res.rows ? res.rows.city : "");
         $("#zipcode_e").val(res.rows ? res.rows.zip : "");
         $("#addressLine2").val(res.rows ? res.rows.addressLine2 : "");
         setStateIdC(res.rows ? res.rows.fkStateId : "");

         setProfileDetails(res.rows);
         setProfilePic(res.customValues ? res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/avatar.png" : "/images/avatar.png")
         setImage(res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/avatar.jpg");

         sessionStorage.setItem("cmpnylogo", res.customValues ? res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/avatar.png" : "/images/avatar.png");
         $("#cmpnylogo").attr("src", res.customValues ? res.customValues.fileByte ? "data:image/png;base64," + res.customValues.fileByte : "/images/avatar.png" : "/images/avatar.png");



      });
   }

   $(document).on("click", "#EditImage", function () {
      $('#selectedImage').hide();
      $('#DB_Image').show();
      $('#uploadImageDisable').prop('disabled', true);
    });

    const handlePageChange = (offset, triggerevent) => {


      userList("", offset, ITEMS_PER_PAGE, "", "", setdispathcerlist, setdisplayPage, "displayItems", setCurrentPage, setTotalRecord, setTotalItems, settotalPages, searchtxt);

   }
   /////////////////////////////emp search/////////////////////////
   const searchList = (searchText) => {
      setsearchText(searchText);
      userList("", "1", ITEMS_PER_PAGE, "", "", setdispathcerlist, setdisplayPage, "displayItems", setCurrentPage, setTotalRecord, setTotalItems, settotalPages, searchText);

   }
   return (
      <>
         {loadingComponent}
         {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block;" }}>
               <div class="row">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <h4 class="font-weight-bold my-4 text-uppercase">Company Profile</h4>
                  </div>
                  <div class="col-lg-11 col-md-12 m-auto mb-2 grid-margin stretch-card d-md-flex">
                     <div class="card profile-wrap">
                        <div class="card-body p-0 " style={{ padding: "0px;" }}>
                           <div class="row">
                              <div class="col-lg-3 col-md-12 pr-0 border-right">
                                 <ul class="nav nav-pills nav-pills-vertical nav-pills-info border-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <li class="nav-item">
                                       <a class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="false">
                                          <i class="mr-2"> <img src="/images/icons/user.png" alt="" /></i>
                                          Company Info
                                       </a>
                                    </li>
                                    {/* {roles.match("Superadmin")?
                                    <li class="nav-item">
                                       <a class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="true">
                                       <i class="mr-2"> <img src="/images/icons/user.png" alt="" /></i>
                                          Users
                                       </a>
                                    </li>
                                     :''}  */}
                                 </ul>
                              </div>
                              <div class="col-lg-9 col-md-12 pl-0 ">
                                 <div class="tab-content tab-content-vertical" id="v-pills-tabContent">
                                    <div class="tab-pane fade active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                       <div class="media-body">
                                          <h5 class="mt-0">Company Information</h5>
                                          <div class="pro-info-wrap pb-5">
                                             <div class="pro-info-item">Company Logo</div>
                                             <div class="pro-info-item d-flex flex-column">
                                                <img src="/images/dotstow-logo.svg" class="avatar-prof mb-2" alt="" id="posterURL" />
                                                {/* <small>Allowed file types: png, jpg, jpeg.</small> */}
                                             </div>
                                             {/* <div class="pro-info-item">
                                                <span class="edit-cust" id="EditImage"><a href="#" data-toggle="modal" data-target="#exampleModal">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a>
                                                </span>
                                             </div> */}
                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Name</div>
                                             <div class="pro-info-item text-capitalize">DotStow</div>
                                             {/* <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                <img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a>
                                             </span> */}
                                          </div>
                                          {/* <div class="pro-info-wrap">
                                             <div class="pro-info-item">Address line1</div>
                                             <div class="pro-info-item">{profileDetails.addressLine1 ? profileDetails.addressLine1 : "N/A"}</div>
                                              <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div> 
                                          </div> */}
                                          {/* <div class="pro-info-wrap">
                                             <div class="pro-info-item">Address line2</div>
                                             <div class="pro-info-item">{profileDetails.addressLine2 ? profileDetails.addressLine2 : "N/A"}</div>
                                              <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div> 
                                          </div> */}

                                          {/* <div class="pro-info-wrap">
                                             <div class="pro-info-item">City</div>
                                             <div class="pro-info-item">{profileDetails.city ? profileDetails.city : "N/A"}</div>
                                          </div>

                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">State</div>
                                             <div class="pro-info-item">{profileDetails.stateName ? profileDetails.stateName : "N/A"}</div>
                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Zip code</div>
                                             <div class="pro-info-item">{profileDetails.zip ? profileDetails.zip : "N/A"}</div>
                                          </div> */}
                                          {/* <div class="pro-info-wrap">
                                             <div class="pro-info-item">Phone number</div>
                                             <div class="pro-info-item">{profileDetails.phoneBusiness ? profileDetails.phoneBusiness : "N/A"}</div>
                                            <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div> 
                                          </div>
                                          <div class="pro-info-wrap position-relative">
                                             <div class="pro-info-item">Email</div>
                                             <div class="pro-info-item">{profileDetails.email ? profileDetails.email : "N/A"}</div>
                                           <div class="pro-info-item">
                                                   <span class="edit-cust enb-not-email">
                                                   <input type="checkbox" id="" name="vehicle2" value="active" /> 
                                                      <span>Enable <br/> notification</span>
                                                   </span>
                                                </div> 
                                          </div>
                                          <div class="p-3 mt-2 mb-2" style={{ marginLeft: "0px" }}>
                                             <h4>ID Numbers</h4>
                                          </div> */}
                                          {/* <div class="pro-info-wrap">
                                             <div class="pro-info-item"> <span class="lableUpper">DOT</span> number</div>
                                             <div class="pro-info-item">{profileDetails.dotNumber ? profileDetails.dotNumber : "N/A"}</div>

                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item"> <span class="lableUpper">CT</span> number</div>
                                             <div class="pro-info-item">{profileDetails.ctNumber ? profileDetails.ctNumber : "N/A"}</div>

                                          </div> */}

















                                          {/* <div class="pro-info-wrap">
                                             <div class="pro-info-item">State</div>
                                             <div class="pro-info-item">{profileDetails.stateName ? profileDetails.stateName : "N/A"}</div>
                                            <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div>
                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Zip</div>
                                             <div class="pro-info-item">{profileDetails.zip ? profileDetails.zip : "N/A"}</div>
                                              <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div>
                                          </div> */}
                                       </div>
                                    </div>

                                    <div class="tab-pane fade " id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                       <div class="media-body">
                                          <h5 class="mt-0">Users</h5>
                                          <div class="row mb-1">
                                          <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                                             <div class="card">
                                                <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                                                   <div class="search position-relative align-self-start mb-1 mt-1 mr-4">
                                                      {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search by Name/Emp ID" class="pl-5"/>
                                                            <a href="#" class="search-icon">
                                                                <img src="/images/icons/search.svg" alt=""/>
                                                            </a> */}
                                                      <Search
                                                         placeholderName="Search by EMP Id / Name / Role"
                                                         onSearch={value => {
                                                            searchList(value);
                                                         }}

                                                      />
                                                      <a href="#" class="search-icon">
                                                         <img src="/images/icons/search.svg" alt="" />
                                                      </a>
                                                   </div>
                                                   <div class="d-flex align-self-start mb-1 mt-1">
                                                      <a type="button" class="btn btn-success" onClick={() => redirect("/addusers")}>Add Employee</a>
                                                   </div>
                                                </div>
                                                <div class="card-body pt-0 pb-0">
                                                   <div class="row">
                                                      <div class="col-12">
                                                         <div class="table-responsive  border-top">
                                                            <table class="table">
                                                               <thead>
                                                                  <tr>
                                                                     <th>Emp ID</th>
                                                                     <th>Name</th>

                                                                     {/* <th>Email id</th>
                                                                     <th>Contact Number</th> */}
                                                                     <th>Role</th>
                                                                     <th style={{width:"100px"}}>Actions</th>

                                                                  </tr>
                                                               </thead>
                                                               <tbody>
                                                                  {
                                                                     dispatcherList ? dispatcherList.map((data, index) => (
                                                                        <tr>
                                                                           <td>{data.id ? data.id : "--"}</td>
                                                                           <td class="text-capitalize">{data.firstName ? data.firstName : ""} {data.lastName ? data.lastName : ""} </td>

                                                                           {/* <td>{data.email ? data.email : "--"}</td>
                                                                           <td>{data.phoneNumber ? data.phoneNumber : "--"}</td> */}
                                                                           <td>{data.securityRoleName ? data.securityRoleName : "--"}</td>
                                                                           <td><a  onClick={() => redirect("/userDetails/" + data.id)} class="btn btn-outline-primary text-uppercase">View</a>  <a  onClick={() => redirect("/EditUser/" + data.id)}  class="btn btn-outline-primary text-uppercase">Edit</a></td>
                                                                        </tr>
                                                                     )) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                                                     </div>
                                                                  }
                                                               </tbody>
                                                            </table>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between p-3">
                                                   <div class="d-flex">
                                                      <div class="dropdown show mr-3 ">
                                                         <select class="form-control custome-select btn gray-mid-color dropdown-toggle" href="#" role="button" id="display_pages_in_items" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            {
                                                               displayPage.map((data, index) => (
                                                                  <option value={data}>{data}</option>
                                                               ))
                                                            }
                                                         </select>
                                                         {/* <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                   25
                                                   </a>
                                                                <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                                    <a class="dropdown-item" href="#">25</a>
                                                                    <a class="dropdown-item" href="#">50</a>
                                                                </div> */}
                                                      </div>
                                                      <span class="d-flex align-items-center pagenation-mob">{currentPage * ITEMS_PER_PAGE <= totalRecors ? currentPage * ITEMS_PER_PAGE : totalRecors} out of {totalRecors} Records Displayed</span>
                                                   </div>
                                                   <nav aria-label="Page navigation example">
                                                      <Pagination
                                                         total={totalRecors}
                                                         itemsPerPage={ITEMS_PER_PAGE}
                                                         currentPage={currentPage}
                                                         //onPageChange={page => setCurrentPage(page)}
                                                         onPageChange={handlePageChange}
                                                      />

                                                   </nav>
                                                   {/* <nav aria-label="Page navigation example">
                                                            <ul class="pagination mb-0">
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Previous">
                                                                        <span aria-hidden="true">«</span>
                                                                        <span class="sr-only">Previous</span>
                                                                    </a>
                                                                </li>
                                                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Next">
                                                                        <span aria-hidden="true">»</span>
                                                                        <span class="sr-only">Next</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </nav> */}
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <!-- Modal --> */}
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Image</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                     <input type="file" name="upload-file" class="w-100 p-3" id="upload-file" onChange={onChange} accept="image/jpeg,image/png,image/jpeg" />                  
                        <div class="row mb-2" id="selectedImage">
                           <div class="col-12">

                              <div class="image-upload-plg">
                                 {/* <input type="file" class="w-100 p-3 mb-3" onChange={onChange} accept="image/jpeg,image/png,image/jpeg" /> */}
                                 <Cropper
                                    style={{ height: 300, width: "100%" }}
                                    zoomTo={0}
                                    aspectRatio={1}
                                    guides={false}
                                    dragMode={"move"}
                                    src={image}
                                    onInitialized={(instance) => {
                                       setCropper(instance);
                                    }}
                                 />
                              </div>


                           </div>

                           <div>
                              <div className="box" style={{ width: "100%", float: "right" }}>

                                 <div
                                    className="img-preview"
                                    style={{ float: "right" }}
                                 />
                              </div>

                           </div>
                        </div>
                        <div class="row mb-2" id="DB_Image">
                        <div className="m-auto mt-2">
                           <img src={image} style={{ width: "240px", height: "240px", marginTop:"20px" }} ></img>

                        </div>
                     </div>
                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" id="btncloseImage">Close</button>
                        <button type="button" class="btn btn-primary" id="uploadImageDisable" onClick={updateProfileImage}>Save changes</button>
                     </div>
                  </div>
               </div>
            </div>



            <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Company Info</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="profileModelClose">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <form onSubmit={handleSubmit(updateProfile)}>
                        <div class="modal-body">
                           <input type="hidden" id="accountId" />
                           <div class="row">
                              <div class="col-md-12">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Name<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="name" aria-describedby="emailHelp" {...register('name', { required: true })} />
                                    {errors.name && errors.name.type === "required" && <span class="error">Name is required</span>}
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Address Line 1<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" maxLength="50" id="addressLine1" aria-describedby="emailHelp" {...register('addressLine1', {
                                       required: true
                                    })} />
                                    {errors.addressLine1 && errors.addressLine1.type === "required" && <span class="error">Address is required</span>}

                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Address Line 2</label>
                                    <input type="text" class="form-control custome-input" maxLength="50" id="addressLine2" aria-describedby="emailHelp" />
                                 </div>
                              </div>
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">City <span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="city_e" minLength="2" maxLength="50" defaultValue='city' onChange={e => setFieldName(e.target.value)}    {...register("City", {
                                       required: "City is required",
                                       maxLength: {
                                          value: 50,
                                          message: "City shouldn't exceed the max length 50 characters"
                                       },
                                       minLength: {
                                          value: 2,
                                          message: "City atleast 2 characters"
                                       },
                                       // pattern: {
                                       //    value: /^[a-zA-Z\s]*$/,
                                       //    message: "City is allow only character's"
                                       // }
                                    })} />
                                    <ErrorMessage
                                       errors={errors}
                                       name="City"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleFormControlSelect1">State<span class="star">*</span></label>

                                    {/* <select id="inputState"   class="form-select  custome-select" name="state">
                                                <option selected value=' '>Select State</option>
                                                {
                                                    states.map((state, index) => (
                                                        <option value={state.id}>{state.name}</option>
                                                    ))
                                                }


                                          </select> */}
                                    <select class="form-control custome-select" id="state_e" defaultValue={"stateIdC"}  >

                                       <option value="">Select state</option>
                                       {
                                          states ? states.map((state, index) => (
                                             <option value={state.id} selected={state.id == stateIdC ? "slected" : ''}>{state.name}</option>
                                          )) : <option value=""></option>
                                       }
                                    </select>

                                 </div>
                              </div>
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Zip Code <span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="zipcode_e" defaultValue='12345' maxLength="5" onChange={e => setFieldName(e.target.value)}    {...register("zip", {
                                       required: "Zip code is required",
                                       pattern: {
                                          value: /^[0-9]{5}(-[0-9]{1})?$/,
                                          message: "Zip code should be 5 digits"
                                       },

                                    })} />
                                    <ErrorMessage
                                       errors={errors}
                                       name="zip"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div class="row">
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Phone number<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="phoneMobile" aria-describedby="emailHelp" {...register('phoneMobile', { required: true, maxLength: 12, minLength: 12 })} />
                                    {errors.phoneMobile && errors.phoneMobile.type === "required" && <span class="error">Phone number is required</span>}
                                    {errors.phoneMobile && errors.phoneMobile.type === "maxLength" && <p class="error">Phone number should be 10 digits</p>}
                                    {errors.phoneMobile && errors.phoneMobile.type === "minLength" && <p class="error">Phone number should be 10 digits</p>}
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Email<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="email" aria-describedby="emailHelp" {...register('email', {
                                       required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                    })} readOnly />
                                    {errors.email && errors.email.type === "required" && <span class="error">Email is required</span>}
                                    {errors.email && errors.email.type === "pattern" && <span class="error">Invalid email address</span>}
                                 </div>
                              </div>
                           </div>
                           {/* <hr/> */}
                           <div class="p-3 mt-2 mb-2" style={{ marginLeft: "-15px" }}>
                              <h4>ID Numbers</h4>
                           </div>
                           <div class="row">
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1"><span class="lableUpper">DOT</span> Number<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="dotNumber" maxLength="20" aria-describedby="emailHelp" {...register('dotNumber', { required: true })} />
                                    {errors.dotNumber && errors.dotNumber.type === "required" && <span class="error">DOT number is required</span>}
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1"><span class="lableUpper">CT</span> Number</label>
                                    <input type="text" class="form-control custome-input" id="ctNumber" maxLength="20" aria-describedby="emailHelp" />
                                    {/* {errors.ctNumber && errors.ctNumber.type === "required" && <span class="error">CT number is required</span>} */}
                                 </div>
                              </div>
                           </div>

                        </div>

                        <div class="modal-footer">
                           <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                           <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>

            {/* <!-- doc edit --> */}
            <div class="modal fade" id="exampleModaldoc" tabindex="-1" aria-labelledby="exampleModalLabeldoc" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Photo</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <div class="m-auto">
                           <div class="doc-img-wrap">
                              hhhhhhhhhhhhhhh
                           </div>

                        </div>


                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                     </div>
                  </div>
               </div>
            </div>

            <div class="modal fade" id="exampleModallic" tabindex="-1" aria-labelledby="exampleModalLabeldlic" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Document</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <div class="m-auto">
                           <div class="doc-img-wrap">

                           </div>

                        </div>

                        <div class="row">

                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Licence Number</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>



                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Expiry Date</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                 <i> <img src="/images/icons/d" alt="" /></i>

                              </div>
                           </div>





                        </div>
                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default Settings;
