// import React,{history,useEffect, useState, useMemo } from "react";
// import {useNavigate , useParams } from "react-router-dom";
// import { keycloak } from "./config";
import { Cookies } from 'react-cookie';
import moment from 'moment';
const cookies = new Cookies();
const Token =  cookies.get("_AK");;
var validate=false;
if(Token !==null) 
{
   validate=true;

}else{
 
}
const CheckAuthentication=validate;
export default CheckAuthentication;