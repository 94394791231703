import React,{useEffect, useState, useMemo,useCallback } from "react";
import UploadImage from "../../../utility/uploadImage";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate ,useParams} from "react-router-dom";
import services from "../../../services/services";
import Getstates from "../../../utility/getstates";
import Getcontactrole from "../../../utility/getcontactrole";
import UsNumberValidate from "../../../utility/usNumberValidate";
import $, { parseJSON } from 'jquery';
import getCroppedImg from "../../../utility/cropImage";
import Cropper from "react-cropper";
import {useLoading} from 'react-hook-loading';
import Swal from "sweetalert2";
import "cropperjs/dist/cropper.css";
import { useForm } from "react-hook-form";
import { requestObject } from "../../../utility/requestObject";
import CheckAuthentication from "../../../utility/checkAuthentication";
import isURL from 'validator/lib/isURL';
import { deskvalidation, faxvalidation } from "../../../utility/validation";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
const AddClient = () => {

    const[imgsrc,setImageSrc]=useState();

    const [loading, setLoading] = useLoading("Please wait...");
    const [val, setVal] = useState('');
    const [err, setErr] = useState('');
    const[states,setState]=useState([]);
    const[contactRole,setContactRole]=useState([]);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [croppedImageData, setCroppedImageData] = useState('')
    const navigate = useNavigate();
    const [itemName, setFieldName] = useState('');
    const [type, setType] = useState('');
    const[customerDetails,setcustomerDetails]=useState();
    const dummyImge = window.location.origin + "/images/icons/dotstow-icon.png";

    const [image, setImage] = useState();
    const [cropData, setCropData] = useState("");
    const [cropper, setCropper] = useState();

    const { id } = useParams();
    const {
      register,
      formState: { errors },
      handleSubmit
    } = useForm({
      criteriaMode: "all",mode: "onBlur"
    });

      $(document).ready(function () {

         $('#customerEmail').keypress(function( e ) {
            if(e.which === 32){
                return false;
            }
        });

       $("#zipcode").keypress(function (e) {
        if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
          return false;
        }

      });
    });
    const validate = (e) => {
      setVal(e.target.value);
      if (isURL(val)) {
         setErr('');
      } else {
         setErr('Invalid URL');
      }
   };
   $("#faxerror").hide();
   $("#deskerror").hide();

    const onChange = (e) => {
      const MIN_FILE_SIZE = 5120 // 5MB
      const fileSizeKiloBytes=Math.floor(e.target.files[0].size/1024); //

      if(fileSizeKiloBytes <= MIN_FILE_SIZE){
          e.preventDefault();
          let files;
           var ext =  e.target.value.split('.').pop();
            var allowedExtensions =/(\jpg|\png|\jpeg)$/i;

            if (!allowedExtensions.exec(ext))
            {

                Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: "",
                  text:"Invalid file type",
                  showConfirmButton: true

                }).then((result) =>
                {

                      if(result.isConfirmed)
                      {

                        e.target.value = '';
                        return false;
                      }
                });

            }else{
               if (e.dataTransfer) {
                  files = e.dataTransfer.files;
                } else if (e.target) {
                  files = e.target.files;
                }
                const reader = new FileReader();
                reader.onload = () => {
                  setImage(reader.result);
                };
                reader.readAsDataURL(files[0]);

            }
         }else{
            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: "Max file size is 5MB",
               showConfirmButton: true
      
            }).then((result) => {
            });   
         }

    };


    function handleChange(event) {

      setImageSrc(URL.createObjectURL(event.target.files[0]))

    }
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      showCroppedImage()
     // setCroppedAreaPixels(croppedAreaPixels)
    }, [])
    const getCropData = () => {
      if (typeof cropper !== "undefined") {
         var cropDataTest=cropper.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 350,  maxHeight: 350}).toDataURL('image/jpeg');
        setCropData(cropDataTest);
        document.getElementById("btnclose").click();
      }
    };
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imgsrc,
        croppedAreaPixels,
        rotation
      )
      // console.log('donee', { croppedImage })
      setCroppedImage(croppedImage)
      // setCroppedImageData(cropper.getCroppedCanvas().toDataURL().replace("data:image/png;base64,", ""))
      // setCroppedImageData(cropper.getCroppedCanvas().toDataURL().replace("data:image/png;base64,", ""))
    } catch (e) {
      // console.error(e)
    }
  }, [croppedAreaPixels, rotation])
    useEffect(() =>
    {

      if(CheckAuthentication)
      {
         $("#divLoading").hide();
         Getstates(setState);
         Getcontactrole(setContactRole);

         $('#faxnumber').usPhoneFormat();
         $('#primaryContactPhoneMobile').usPhoneFormat();
         $('#primaryContactPhoneBusiness').usPhoneFormat();
         $('#accountingContactPhoneMobile').usPhoneFormat();
         $('#accountingContactPhoneBusiness').usPhoneFormat();
         $('#yourphone2').usPhoneFormat();
        // getCustomer_Detils(id);

      }else{
         sessionStorage.clear();
         localStorage.clear();
        //navigate("/login");
          window.location.href="/login";
      }



    },[]);
   ///////////////////////////////////////////////
   ////////getcustomer_details from aws////////////////////////
   const getCustomer_Detils=(id)=>
   {
         var customerListReq=
         {

            "id":id,

         };

         // console.log("results="+JSON.stringify(customerListReq));

         // const requestOptions =
         // {
         //       method: 'POST',
         //       headers:{'Content-Type': 'application/json'},
         //       body: JSON.stringify(customerListReq)
         // };

         var requestOptions=requestObject.RequestHeader(customerListReq);

         services.getCustomer_details(requestOptions).then((res) =>
         {

            document.getElementById("btnclose").click();
           // $("#customername").val(res.rows?res.rows.customerName:"");
           // $("#dotNumber").val(res.rows?res.rows.dotNumber:"");
           // $("#posterURL").attr("src",res.customValues?"data:image/png;base64,"+res.customValues.fileByte:"/images/transport-logo.jpg");
           // setcustomerDetails(res.rows);

         });

   }

    //////////////submitvalues///////////////////////////
    const submitValue=()=>
    {

       $("#loadingforsubmit").show();
       var requestObjectforaddCustomer=
       {
         "name":$("#customername").val().trim(),
         "type":$("#type").val(),
         "dotNumber":$("#dotnumber").val().trim(),
          "ctNumber":$("#ctnumber").val().trim(),
          "addressLine1":$("#addressline1").val().trim(),
          "addressLine2":$("#addressline2").val().trim(),
          "city":$("#city").val().trim(),
          "stateId":$("#state").val(),
          "zip":$("#zipcode").val().trim(),
          "phoneBusiness":$("#yourphone2").val().trim(), 
          "phoneMobile":'',
          "email":$("#customerEmail").val().trim(),
          "fax":$("#faxnumber").val().trim(),
          "logoName":cropper.getCroppedCanvas()?parseInt(Math.random() * 1000000000):"",
          "logoExtension":cropper.getCroppedCanvas()?"jpeg":"",
         //  "imageByteString":cropper.getCroppedCanvas()?cropper.getCroppedCanvas().toDataURL().replace("data:image/png;base64,", ""):""
          "logByteString":cropData.replace("data:image/jpeg;base64,", "")=="/images/icons/dotstow-icon.png"?"":cropData.replace("data:image/jpeg;base64,", "")

       }
      var requestOptions=requestObject.RequestHeader(requestObjectforaddCustomer);
       services.add_client(requestOptions).then((res) =>
       {
         // setLoading(false);
         $("#loadingforsubmit").hide();

         Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text:res.message,
            showConfirmButton: true

          }).then((result) =>
          {
            if(res.message==="Unable to add client because of duplication, Duplicate Tenant")
                {
       
                }
                else
                {
                  if(result.isConfirmed)
                  {
                    navigate('/clients');
  
                  }
                }
                
          });


       });
    }

   const redirect=(page)=>
   {

         navigate(page);

    }

  return(
  <>
{LoadingComponentForSubmit}
<div class="main-panel">
               <div class="content-wrapper" style={{display: "block"}}>
               <form onSubmit={handleSubmit(submitValue)}>
                  <div class="row">
                     <div class="col-lg-8 col-md-12 m-auto mb-2">
                        <h4 class="font-weight-bold">Add Client</h4>
                     </div>
                  </div>
                  <div class="row mb-2">
                     <div class="col-lg-8 col-md-12 m-auto grid-margin stretch-card">
                        <div class="card">
                           <div class=" p-3 mt-2 mb-2">
                              <h4>Client Information</h4>
                           </div>
                           <div class="card-body pt-0 pb-2">
                              <div class="row">
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1">Client Name <span class="star">*</span></label>
                                       <input type="text" class="form-control" id="customername" maxLength="80" minLength="2" placeholder=""  onChange={e => setFieldName(e.target.value)}    {...register("customername", {
                                                required: "Client name is required",
                                                maxLength: {
                                                   value: 80,
                                                   message: "Client name shouldn't exceed the max length 80 characters"
                                                   },
                                                minLength: {
                                                      value: 2,
                                                      message: "Client name atleast 2 characters"
                                                   } ,
                                                 })}/>
                                          <ErrorMessage
                                             errors={errors}
                                             name="customername"
                                             class="invalid-feedback"
                                             render={({ messages }) => {
                                             //
                                             return messages
                                                ? Object.entries(messages).map(([type, message]) => (
                                                   <p key={type}  class="error">{message}</p>
                                                ))
                                                : null;
                                             }}
                                       />
                                    </div>
                                 </div>
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1">Type <span class="star">*</span></label>
                                       <select class="form-control custome-select" id="type" onChange={e => setType(e.target.value)}    {...register("type", {
                                                required: "Type is required",
                                                 })}>
                                       <option value="">Select</option>
                                       <option value="garage">Garage</option>
                                       <option value="fleet">Fleet</option>
                                       </select>
                                       <ErrorMessage
                                                errors={errors}
                                                name="type"
                                                class="invalid-feedback"
                                                render={({ messages }) => {

                                                return messages
                                                   ? Object.entries(messages).map(([type, message]) => (
                                                      <p key={type}  class="error">{message}</p>
                                                   ))
                                                   : null;
                                                }}></ErrorMessage>
                                    </div>
                                 </div>
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1">Address Line 1 <span class="star">*</span></label>
                                          <input type="text" class="form-control custome-input" id="addressline1" maxLength="50" onChange={e => setFieldName(e.target.value)}    {...register("address1", {
                                                required: "Address line 1 is required",
                                                 })}/>
                                             <ErrorMessage
                                                errors={errors}
                                                name="address1"
                                                class="invalid-feedback"
                                                render={({ messages }) => {

                                                return messages
                                                   ? Object.entries(messages).map(([type, message]) => (
                                                      <p key={type}  class="error">{message}</p>
                                                   ))
                                                   : null;
                                                }}
                                          />
                                    </div>
                                 </div>
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1">Address Line 2</label>
                                       <input type="text" class="form-control custome-input" id="addressline2" maxLength="50" aria-describedby="emailHelp"/>
                                    </div>
                                 </div>

                              </div>
                              <div class="row">
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1">City <span class="star">*</span></label>
                                       <input type="text" class="form-control custome-input"  id="city" minLength="2" maxLength="50" onChange={e => setFieldName(e.target.value)}    {...register("City", {

                                               required: "City is required",
                                                maxLength: {
                                                   value: 50,
                                                   message: "City shouldn't exceed the max length 50 characters"
                                                   },
                                                minLength: {
                                                      value: 2,
                                                      message: "City atleast 2 characters"
                                                   } ,
                                                   // pattern: {
                                                   //    value: /^[a-zA-Z\s]*$/,
                                                   //    message: "City is allow only character's"
                                                   // }

                                                         //  validate: (value) => {
                                                         //    return (
                                                         //      [/[a-z]/].every((pattern) =>
                                                         //        pattern.test(value)
                                                         //      ) || "City is allow only character's "
                                                         //    );
                                                         //  },

                                                 })}/>
                                             <ErrorMessage
                                                errors={errors}
                                                name="City"
                                                class="invalid-feedback"
                                                render={({ messages }) => {

                                                return messages
                                                   ? Object.entries(messages).map(([type, message]) => (
                                                      <p key={type}  class="error">{message}</p>
                                                   ))
                                                   : null;
                                                }}
                                          />
                                    </div>
                                 </div>
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleFormControlSelect1">State <span class="star">*</span></label>

                                          {/* <select id="inputState"   class="form-select  custome-select" name="state">
                                                <option selected value=' '>Select State</option>
                                                {
                                                    states.map((state, index) => (
                                                        <option value={state.id}>{state.name}</option>
                                                    ))
                                                }


                                          </select> */}
                                       <select class="form-control custome-select" id="state" onChange={e => setFieldName(e.target.value)}    {...register("State", {
                                                required: "State is required",
                                                 })}>


                                       <option value="">Select state</option>
                                       {
                                                    states?states.map((state, index) => (
                                                        <option value={state.id}>{state.name}</option>
                                                    )): <option value=""></option>
                                       }
                                       </select>
                                       <ErrorMessage
                                                errors={errors}
                                                name="State"
                                                class="invalid-feedback"
                                                render={({ messages }) => {

                                                return messages
                                                   ? Object.entries(messages).map(([type, message]) => (
                                                      <p key={type}  class="error">{message}</p>
                                                   ))
                                                   : null;
                                                }}></ErrorMessage>
                                    </div>
                                 </div>
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1">Zip Code <span class="star">*</span></label>
                                       <input type="text" class="form-control custome-input" id="zipcode" maxLength="5" onChange={e => setFieldName(e.target.value)}    {...register("zip", {
                                                required: "Zip code is required",
                                                   pattern: {
                                                      value: /^[0-9]{5}(-[0-9]{1})?$/,
                                                      message: "Zip code should be 5 digits"
                                                      },
                                                   })}  minLength="5"/>
                                             <ErrorMessage
                                                errors={errors}
                                                name="zip"
                                                class="invalid-feedback"
                                                render={({ messages }) => {

                                                return messages
                                                   ? Object.entries(messages).map(([type, message]) => (
                                                      <p key={type}  class="error">{message}</p>
                                                   ))
                                                   : null;
                                                }}
                                          />
                                    </div>
                                 </div>
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1">Phone Number <span class="star">*</span></label>
                                       <input type="text" class="form-control custome-input" id="yourphone2" placeholder="" onChange={e => setFieldName(e.target.value)} {...register("Phone", {
                                    required: "Phone number is required",
                                    maxLength: {
                                       value: 12,
                                       message: "Phone number should be 10 digits"
                                       },
                                    minLength: {
                                          value: 12,
                                          message: "Phone number should be 10 digits"
                                       } ,

                                    })}/>
                                    <ErrorMessage
                                       errors={errors}
                                       name="Phone"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                       return messages
                                          ? Object.entries(messages).map(([type, message]) => (
                                             <p key={type}  class="error">{message}</p>
                                          ))
                                          : null;
                                       }}
                                 />
                                    </div>
                                 </div>
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="faxnumber">Fax Number </label>
                                       <input type="text" class="form-control custome-input" id="faxnumber" maxLength="12"  {...register("faxNumber", {

                                    maxLength: {
                                       value: 12,
                                       message: "Fax number should be 10 digits"
                                       },
                                    minLength: {
                                          value: 12,
                                          message: "Fax number should be 10 digits"
                                       } ,

                                    })}/>
                                    <ErrorMessage
                                       errors={errors}
                                       name="faxNumber"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                       return messages
                                          ? Object.entries(messages).map(([type, message]) => (
                                             <p key={type}  class="error">{message}</p>
                                          ))
                                          : null;
                                       }}
                                 />
                                     {/* onChange={e =>faxvalidation(e)} */}
                                       {/* <span class="error" id="faxerror">Fax number should be 10 digits</span> */}
                                    </div>
                                 </div>
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1">Email Address <span class="star">*</span></label>
                                       <input type="text" class="form-control custome-input" id="customerEmail" placeholder="" onChange={e => setFieldName(e.target.value)} {...register("CompanyEmail", {
                                          required: "Email address is required",

                                          pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                          message: "Invalid email address"
                                          }
                                          })}/>
                                       <ErrorMessage
                                          errors={errors}
                                          name="CompanyEmail"
                                          class="invalid-feedback"
                                          render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type}  class="error">{message}</p>
                                             ))
                                             : null;
                                          }}
                                    />
                                    </div>
                                 </div>
                            
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="row mb-2">
                     <div class="col-lg-8 col-md-12 m-auto grid-margin stretch-card">
                        <div class="card">
                           <div class=" p-3 mt-2 mb-2">
                              <h4>ID Numbers</h4>
                           </div>
                           <div class="card-body pt-0 pb-2">
                              <div class="row">
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1" ><span  class="lableUpper">DOT</span> Number <span class="star">*</span></label>
                                       <input type="text" class="form-control custome-input" id="dotnumber" maxLength="20" placeholder="" onChange={e => setFieldName(e.target.value)}  {...register("dotnumber", {
                                    required: "DOT number is required",

                                    })}/>

                                 <ErrorMessage
                                    errors={errors}
                                    name="dotnumber"
                                    class="invalid-feedback"
                                    render={({ messages }) => {

                                    return messages
                                        ? Object.entries(messages).map(([type, message]) => (
                                            <p key={type}  class="error">{message}</p>
                                        ))
                                        : null;
                                    }}
                                />
                                    </div>
                                 </div>
                                 {/* <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1"><span  class="lableUpper">MC</span> Number</label>
                                       <input type="text" class="form-control custome-input" id="Mcnumber" maxLength="20" aria-describedby="emailHelp"/>
                                    </div>
                                 </div> */}
                                 <div class="col-6">
                                    <div class="form-group">
                                       <label for="exampleInputEmail1"><span  class="lableUpper">CT</span> NUMBER</label>
                                       <input type="text" class="form-control custome-input" id="ctnumber" maxLength="20" aria-describedby="emailHelp"/>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
              
                 <div class="row mb-2">

                  <div class="col-lg-8 col-md-12 m-auto grid-margin stretch-card">
                    <div class="card">

                      <div class="card-body pt-0 pb-2 p-3">
                        <div class="row d-flex align-items-center">
                          <div class="col-md-5 ">
                           <div class="instruction">
                            <span>Client Logo</span>
                            <p>Max File Size : 5MB</p>
                            <p>Formats: JPG/PNG</p>
                            <p>Ratio: 1:1 (width and height must be equal)</p>
                           </div>
                          </div>
                          <div class="col-md-3">
                            <div class="avatar">
                              <img  src={cropData?cropData:dummyImge} />
                                   {/* <Cropper
                                       image={imgsrc}
                                       crop={crop}
                                       rotation={rotation}
                                       zoom={zoom}
                                       aspect={4 / 3}
                                       onCropChange={setCrop}
                                       onRotationChange={setRotation}
                                       onCropComplete={onCropComplete}
                                       onZoomChange={setZoom}
                                    /> */}
                            </div>
                            {/* <UploadImage/> */}



                          </div>
                          {/* <input type="file" onChange={handleChange}/> */}
                          <div class="col-md-4"><a href="" class="btn btn-outline-dark btn-fw" data-toggle="modal" data-target="#exampleModal" data-backdrop="static" data-keyboard="false">
                          {/* <input type="file" data-bs-toggle="modal" data-bs-target="#exampleModal" onChange={handleChange}/></a> */}Upload Photo</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 </div>

                 <div class="row mb-2">
                  <div class="col-lg-8 col-md-12 m-auto ">
                    <div class="white-color p-3 d-flex">

                      <button type="submit" class="btn btn-primary mw-120 mr-2">SAVE</button>
                      <a  onClick={() => redirect("/clients")}  class="btn gray-mid-color mw-120" >CANCEL</a>
                    </div>
                  </div>
                 </div>
                 </form>
               </div>
               <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                     <h5 class="modal-title" id="exampleModalLabel">Upload Pic</h5>
                     <button type="button" class="close" data-dismiss="modal"  id="btnclose" aria-label="Close">
                     <span aria-hidden="true">×</span>
                     </button>
                     </div>
                     <div class="modal-body">
                     <div class="row mb-2">
                        <div class="col-12">

                        <div class="image-upload-plg">
                        <input type="file" class="w-100 p-3 mb-2" onChange={onChange} accept="image/jpeg,image/png,image/jpeg"/>




                {/* <Cropper
                  style={{ height: "320px", width: "320px", margin:"auto" }}
                  zoomTo={0.5}
                  initialAspectRatio={4 / 4}
                  preview=".img-preview"
                  src={image}
                  viewMode={3}


                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                /> */}
                 <Cropper
            style={{ height: 300, width: "100%" }}
            aspectRatio={1}
            guides={false}
            dragMode={"move"}
            src={image}
            onInitialized={(instance) => {
                setCropper(instance);
             }}
          />
              </div>


                        </div>

                     <div>
                     <div className="box" style={{ width: "320px", float: "right", display: "none" }}>
                        <h1>Preview</h1>
                        <div
                           className="img-preview"

                        />
                     </div>
                     {/* <div
                        className="box"
                        style={{ width: "50%", float: "right", height: "300px" }}
                     >
                        <h1>
                           <span>Crop</span>
                           <button style={{ float: "right" }} onClick={getCropData}>
                           Crop Image
                           </button>
                        </h1>
                        <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                     </div> */}
                     </div>
                 </div>
                  </div>
                  <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal"  id="btnclose">Close</button>
                  <button type="button" class="btn btn-primary" onClick={getCropData}>Save changes</button>
                  </div>
               </div>
            </div>
            </div>
            </div>



  </>
)};

AddClient.propTypes = {};

AddClient.defaultProps = {};

export default AddClient;
