export const itemperPagetoDisplay =25;

export const newStatus="NEW";
export const inprocess="InProcess";
export const Completed="Completed";
export const InProgress="In-Progress";
export const Technician="Technician";
export const Inspector="Inspector";
export const Manager="Manager";
export const Owner="Owner";
export const Driver="Driver";
export const Dispatcher="Dispatcher";
export const Approved="approved";
export const ApprovedPending="APPROVED PENDING";
export const APPROVED="APPROVED";
export const Rejected="Rejected";
