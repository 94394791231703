import React, { history, useEffect, useState, useMemo } from "react";
import EditProfile from "./edit_profile";
import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import { useLoading } from "react-hook-loading";
import Swal from "sweetalert2";
import $ from "jquery";
import Cropper from "react-cropper";
import { useForm } from "react-hook-form";
import { loadingComponent } from "../../../utility/Loading";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
const Profiles = () => {
   const [profileDetails, setProfileDetails] = useState("");
   const [profilePic, setProfilePic] = useState("");
   const [loading, setLoading] = useLoading("Please wait...");
   const [cropper, setCropper] = useState();
   const [image, setImage] = useState();
   const [documentIdsArray, setDocumentIDs] = useState([]);
   

   const {
      register,
      formState: { errors },
      handleSubmit
   } = useForm({
      criteriaMode: "all", mode: "onKeyup",
   });

   useEffect(() => {
      
      var navigationfrom = sessionStorage.getItem("naviationfrom");
      if (navigationfrom === "password") {
         document.getElementById("v-pills-profile-tab").click();
         sessionStorage.removeItem("naviationfrom");
      } else if (navigationfrom === "mydocs") {
         document.getElementById("v-pills-messages-tab").click();
         sessionStorage.removeItem("naviationfrom");
      } else {
         document.getElementById("v-pills-home-tab").click();
         sessionStorage.removeItem("naviationfrom");
      }
      IfitIsCustomer();
   }, []);
   function validateForm() {
      var valid = true;
      const newPassword = $('#newPassword').val();
      const confirmPassword = $('#confirmPassword').val();
      if (newPassword != confirmPassword) {
         //  $('#confirm').html("Does'nt Match password");
         valid = false;

      } else {
         //  $('#confirm').html("");
         valid = true;
      }

      return valid;

   }
   const resetpassword = () => {
      var currentPassword = $("#currentPassword").val();
      var confirmPassword = $("#confirmPassword").val();
      var newPassword = $("#newPassword").val();
      // if(confirmPassword.match(newPassword))
      if (validateForm()) {

         var resetPwd =
         {
            "id": sessionStorage.getItem("userId"),
            "currentPassword": currentPassword,
            "newPassword": newPassword

         };

         // setLoading(true);
         $("#loadingforsubmit").show();
         var requestOptions = requestObject.RequestHeader(resetPwd);
         services.resetuserPassword(requestOptions).then((res) => {
            // setLoading(false);
            $("#loadingforsubmit").hide();
            if (res.success) {
               Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: "",
                  text: res.message,

                  showConfirmButton: true

               }).then((result) => {

                  if (result.isConfirmed) {
                     window.location.reload();
                  }
               });

            } else {

               Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: "",
                  text: res.message,

                  showConfirmButton: true

               }).then((result) => {
                  if (result.isConfirmed) {

                  }
               });

            }

         });

      } else {


         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Password mismatch",

            showConfirmButton: true

         }).then((result) => {
            if (result.isConfirmed) {

            }
         });

      }



   }
   const updateProfile = () => {

      // setLoading(true);
      $("#loadingforsubmit").show();
      document.getElementById("btncloseImage").click();

      var requestObjectCustomer =
      {


         "id": sessionStorage.getItem("userId"),
         "firstName": $("#GfirstName").val(),
         "lastName": $("#GlastName").val(),
         "phoneNumber": $("#GPhone").val(),
         // "email":$("#GEmail").val(),
         "imageName": cropper.getCroppedCanvas() ? parseInt(Math.random() * 1000000000) : "",
         "imageExtension": cropper.getCroppedCanvas() ? "png" : "",
         "imageByteString": cropper.getCroppedCanvas() ? cropper.getCroppedCanvas().toDataURL().replace("data:image/png;base64,", "") : ""

      }

      var requestOptions = requestObject.RequestHeader(requestObjectCustomer);
      services.updateUser(requestOptions).then((res) => {
         // setLoading(false);
         $("#loadingforsubmit").hide();
         if (res.success) {
            Swal.fire({
               position: 'center',
               icon: 'success',
               title: "",
               text: res.message,
               showConfirmButton: true

            }).then((result) => {
               // window.location.reload();
               IfitIsCustomer();

            });

         }
         else {

            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: res.message,
               showConfirmButton: true

            }).then((result) => {
               //window.location.reload();

            });

         }



      });


   }
   const IfitIsCustomer = () => {
      var customerListReq =
      {

         "id": sessionStorage.getItem("userId")

      };



      var requestOptions = requestObject.RequestHeader(customerListReq);
      services.getUserDetails(requestOptions).then((res) => {



         $("#loading").hide();
         try {

            setProfileDetails(res.rows ? res.rows : "");
            setProfilePic(res.customValues ? res.customValues.userImageFileByte ? "data:image/png;base64," + res.customValues.userImageFileByte : "/images/avatar.png" : "/images/avatar.png")
            setImage(res.customValues.userImageFileByte ? "data:image/png;base64," + res.customValues.userImageFileByte : "/images/avatar.jpg");

         } catch (error) {
         }
         // setProfileDetails(res.rows ? res.rows : "");
         // setProfilePic(res.customValues ? res.customValues.userImageFileByte ? "data:image/png;base64," + res.customValues.userImageFileByte : "/images/avatar.png" : "/images/avatar.png")
         // setImage(res.customValues ? "data:image/png;base64," + res.customValues.userImageFileByte : "/images/avatar.jpg");
         //$("#posterURL").attr("src",res.customValues?res.customValues.fileByte?"data:image/png;base64,"+res.customValues.fileByte:"/images/avatar.png":"/images/avatar.png");
         //  setcustomerDetails(res.rows);

         sessionStorage.setItem("customerName", res.rows.firstName + " " + res.rows.lastName);
         if (res.customValues) {
            $("#profileDropdown").attr("src", res.customValues.userImageFileByte ? "data:image/png;base64," + res.customValues.userImageFileByte : "/images/transport-logo.jpg");
         } else {
            $("#profileDropdown").attr("src", "/images/transport-logo.jpg");
         }
         $("#customerNameH").html(res.rows ? res.rows.firstName + " " + res.rows.lastName : "");
         $("#GfirstName").val(res.rows ? res.rows.firstName : "");
         $("#GlastName").val(res.rows ? res.rows.lastName : "");
         $("#GEmail").val(res.rows ? res.rows.email : "");
         $("#GPhone").val(res.rows ? res.rows.phoneNumber : "");
         $("#doucmentId").val(res.rows ? res.rows.phoneNumber : "");
         // alert(res.rows.documents[0].id)
         // setDocumentID(res.rows.documents ? res.rows.documents[0].id : null);
         
         if(res.rows.documents!=null){
            
            var documentIds = [];
            for(var i=0;i<res.rows.documents.length;i++)
            {
            documentIds.push({ "id": res.rows.documents[i].id})
            setDocumentIDs(documentIds);
            }
         }
         // console.log("@@@@@@=="+JSON.stringify(documentIds));
      });
   }

   const onChange = (e) => {
      // const MIN_FILE_SIZE = 2048 // 2MB
      // const fileSizeKiloBytes=Math.floor(e.target.files[0].size/1024); //

      // if(fileSizeKiloBytes <= MIN_FILE_SIZE){
      e.preventDefault();
      let files;
      var ext = e.target.value.split('.').pop();
      var allowedExtensions = /(\jpg|\png|\jpeg)$/i;

      if (!allowedExtensions.exec(ext)) {

         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Invalid file type",
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {

               e.target.value = '';
               return false;
            }
         });

      } else {
         $("#DB_Image").hide();
         $('#selectedImage').show();
         $('#uploadImageDisable').prop('disabled', false);
         if (e.dataTransfer) {
            files = e.dataTransfer.files;
         } else if (e.target) {
            files = e.target.files;
         }
         const reader = new FileReader();
         reader.onload = () => {
            setImage(reader.result);
         };
         reader.readAsDataURL(files[0]);

      }
   // }else{
   //    Swal.fire({
   //       position: 'center',
   //       icon: 'error',
   //       title: "",
   //       text: "Max file size is 2MB",
   //       showConfirmButton: true

   //    }).then((result) => {
   //    });   
   // }
   };

   const document_details = (id, filePath) => {

      var extension = filePath.match('[^.]+$');

      var document_request =
      {

         "id": id

      };

      var requestOptions = requestObject.RequestHeader(document_request);

      services.getUserDocumentDetails(requestOptions).then((res) => {
         try {

            if (res.customValues.fileByte === "") {
               Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: "",
                  text: "File is not attached",

                  showConfirmButton: true

               }).then((result) => {
                  if (result.isConfirmed) {

                  }
               });
            }
            else {

               var file;

               if (extension == "pdf") {
                  var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
                  var byteNumbers = new Array(byteCharacters.length);
                  for (var i = 0; i < byteCharacters.length; i++) {
                     byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  var byteArray = new Uint8Array(byteNumbers);
                  file = new Blob([byteArray], { type: 'application/pdf;base64' });
                  var fileURL = URL.createObjectURL(file);
                  window.open(fileURL);
               }
               else if (extension == "jpg") {
                  // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                  var image = new Image();
                  image.src = "data:image/jpg;base64," + res.customValues.fileByte;

                  var w = window.open("");
                  w.document.write(image.outerHTML);
               }
               else if (extension == "png") {
                  // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                  var image = new Image();
                  image.src = "data:image/png;base64," + res.customValues.fileByte;

                  var w = window.open("");
                  w.document.write(image.outerHTML);
               }
               else if (extension == "bmp") {
                  // file = new Blob([byteArray], { type: 'data:image/jpg;base64' });
                  var image = new Image();
                  image.src = "data:image/bmp;base64," + res.customValues.fileByte;

                  var w = window.open("");
                  w.document.write(image.outerHTML);
               }
               else if (extension == "word") {

                  var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
                  var byteNumbers = new Array(byteCharacters.length);
                  for (var i = 0; i < byteCharacters.length; i++) {
                     byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  var byteArray = new Uint8Array(byteNumbers);
                  file = new Blob([byteArray], { type: 'application/msword;base64' });
                  var fileURL = URL.createObjectURL(file);
                  window.open(fileURL);
               }
               else if (extension == "docx" || extension == "doc" || extension == "docm" || extension == "dotx" || extension == "dotm") {

                  var byteCharacters = atob(res.customValues ? res.customValues.fileByte : "");
                  var byteNumbers = new Array(byteCharacters.length);
                  for (var i = 0; i < byteCharacters.length; i++) {
                     byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  var byteArray = new Uint8Array(byteNumbers);
                  file = new Blob([byteArray], { type: 'application/msword;base64' });
                  var fileURL = URL.createObjectURL(file);
                  window.open(fileURL);
               }
            }
         } catch (ex) {

         }



      });

   }

   const backbuttonHandle = (tabName) => {
      // alert(tabName);
      if (tabName === "profileInfo") {
         sessionStorage.setItem("naviationfrom", "profileInfo");

      } if (tabName === "password") {
         sessionStorage.setItem("naviationfrom", "password");
      }
      if (tabName === "mydocs") {
         sessionStorage.setItem("naviationfrom", "mydocs");
      }
   }

   $(document).on("click", "#EditImage", function () {
      $('#selectedImage').hide();
      $('#DB_Image').show();
      $('#uploadImageDisable').prop('disabled', true);
    });
   return (
      <>
         {loadingComponent}
         {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block;" }}>
               <div class="row">
                  <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <h4 class="font-weight-bold my-4 text-uppercase">My Profile</h4>
                  </div>
                  <div class="col-lg-11 col-md-12 m-auto mb-2 grid-margin stretch-card d-md-flex">
                     <div class="card profile-wrap">
                        <div class="card-body p-0 " style={{ padding: "0px;" }}>
                           <div class="row">
                              <div class="col-lg-3 col-md-12 pr-0 border-right">
                                 <ul class="nav nav-pills nav-pills-vertical nav-pills-info border-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <li class="nav-item">
                                       <a class="nav-link active" id="v-pills-home-tab" onClick={() => backbuttonHandle('profileInfo')} data-bs-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="false">
                                          <i class="mr-2"> <img src="/images/icons/user.png" alt="" /></i>
                                          Profile Info
                                       </a>
                                    </li>
                                    <li class="nav-item">
                                       <a class="nav-link" id="v-pills-profile-tab" onClick={() => backbuttonHandle('password')} data-bs-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                          <i class="mr-2"><img src="/images/icons/lock.png" alt="" /></i>
                                          Password
                                       </a>
                                    </li>
                                    <li class="nav-item">
                                       <a class="nav-link " id="v-pills-messages-tab" onClick={() => backbuttonHandle('mydocs')} data-bs-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="true">
                                          <i class="mr-2"><img src="/images/icons/document.png" alt="" /></i>
                                          My Docs
                                       </a>
                                    </li>
                                 </ul>
                              </div>
                              <div class="col-lg-9 col-md-12 pl-0 ">
                                 <div class="tab-content tab-content-vertical" id="v-pills-tabContent">
                                    <div class="tab-pane fade active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                       <div class="media-body">
                                          <h5 class="mt-0">Personal Information</h5>
                                          <div class="pro-info-wrap pb-5">
                                             <div class="pro-info-item">Profile Picture</div>
                                             <div class="pro-info-item d-flex flex-column">
                                                <img src={profilePic ? profilePic : "/images/nia.JPG"} class="avatar-prof mb-2" alt="" id="posterURL" />
                                                <small>Allowed file types: png, jpg, jpeg.</small>
                                             </div>
                                             <div class="pro-info-item">
                                                <span class="edit-cust" id="EditImage"><a href="#" data-toggle="modal" data-target="#uploadPicModal">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a>
                                                </span>
                                             </div>
                                          </div>
                                          {/* <div class="pro-info-wrap">
                                                <div class="pro-info-item">Dot Number</div>
                                                <div class="pro-info-item">{profileDetails?profileDetails.dotNumber:"N/A"}</div>

                                             </div> */}
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item ">First name</div>
                                             <div class="pro-info-item text-capitalize">{profileDetails ? profileDetails.firstName : "N/A"}</div>
                                             <div class="pro-info-item">
                                                <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a>
                                                </span>
                                             </div>
                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Last name</div>
                                             <div class="pro-info-item text-capitalize">{profileDetails ? profileDetails.lastName : "N/A"}</div>
                                             {/* <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div> */}
                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Email</div>
                                             <div class="pro-info-item">{profileDetails.email ? profileDetails.email : "N/A"}</div>
                                             {/* <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div> */}
                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Phone number</div>
                                             <div class="pro-info-item">{profileDetails.phoneNumber ? profileDetails.phoneNumber : "N/A"}</div>
                                             {/* <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div> */}
                                          </div>
                                          {/* <div class="pro-info-wrap">
                                                <div class="pro-info-item">Address</div>
                                                <div class="pro-info-item">{profileDetails.addressLine1?profileDetails.addressLine1:"N/A"}{profileDetails.addressLine2?profileDetails.addressLine2:"N/A"}</div>

                                             </div> */}
                                       </div>
                                    </div>
                                    <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                       <form onSubmit={handleSubmit(resetpassword)}>
                                          <div class="media-body">
                                             <h5 class="mt-0">Change Password</h5>
                                             <div class="p-4">
                                                <div class="p-4" style={{ backgroundColor: "#f0f0f0" }}>
                                                   <ul class="mb-0">
                                                      <h6 style={{ marginLeft: "-15px" }}>NOTE:</h6>
                                                      <li>Password must contain at least 1 special charaters</li>
                                                      <li>Minimum length 8 characters</li>
                                                   </ul>
                                                </div>
                                             </div>
                                             <div class="pro-info-wrap align-items-center">
                                                <div class="pro-info-item">Current password<span class="star">*</span></div>
                                                <div class="pro-info-item"><input type="password" class="form-control w-half" id="currentPassword" placeholder="" aria-describedby="emailHelp" {...register('currentPassword', { required: true, minLength: 8, pattern: /^(?=.*?[#?!@$%^&*-]).{8,}$/gm })} />
                                                   {errors.currentPassword && errors.currentPassword.type === "required" && <span class="error">Current password is required</span>}
                                                   {errors.currentPassword && errors.currentPassword.type === "minLength" && <span class="error">Invalid password: minimum length 8</span>}
                                                   {errors.currentPassword && errors.currentPassword.type === "pattern" && <span class="error">Invalid password: must contain at least 1 special charaters</span>}
                                                </div>

                                             </div>
                                             <div class="pro-info-wrap align-items-center">
                                                <div class="pro-info-item">New password<span class="star">*</span></div>
                                                <div class="pro-info-item"><input type="password" class="form-control w-half" id="newPassword" placeholder="" aria-describedby="emailHelp" {...register('newPassword', { required: true, minLength: 8, pattern: /^(?=.*?[#?!@$%^&*-]).{8,}$/gm })} />
                                                   {errors.newPassword && errors.newPassword.type === "required" && <span class="error">New password is required</span>}
                                                   {errors.newPassword && errors.newPassword.type === "minLength" && <span class="error">Invalid password: minimum length 8</span>}
                                                   {errors.newPassword && errors.newPassword.type === "pattern" && <span class="error">Invalid password: must contain at least 1 special charaters</span>}
                                                </div>

                                             </div>
                                             <div class="pro-info-wrap align-items-center">
                                                <div class="pro-info-item">Confirm password<span class="star">*</span></div>
                                                <div class="pro-info-item"><input type="password" class="form-control w-half" id="confirmPassword" placeholder="" aria-describedby="emailHelp" {...register('confirmPassword', { required: true, minLength: 8, pattern: /^(?=.*?[#?!@$%^&*-]).{8,}$/gm })} />
                                                   {errors.confirmPassword && errors.confirmPassword.type === "required" && <span class="error">Confirm password is required</span>}
                                                   {errors.confirmPassword && errors.confirmPassword.type === "minLength" && <span class="error">Invalid password: minimum length 8</span>}
                                                   {errors.confirmPassword && errors.confirmPassword.type === "pattern" && <span class="error">Invalid password: must contain at least 1 special charaters</span>}
                                                   {/* <span id="confirm"></span> */}
                                                </div>

                                             </div>
                                             <div class="modal-footer">
                                                <button type="submit" class="btn btn-primary" >Save changes</button>
                                             </div>
                                          </div>
                                       </form>
                                    </div>
                                    <div class="tab-pane fade " id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                       <div class="media-body">
                                          <h5 class="mt-0">My Documents
                                             <a style={{ marginRight: "10px", float: "right", marginTop: "-8px" }} class="btn btn-success" href={"/AddMydocs/" + sessionStorage.getItem("userId")}  >Add Document</a>
                                          </h5>

                                          <div>
                                             <div class="row px-3" style={{ marginBottom: "30px" }} >
                                                {
                                                   profileDetails.documents ? profileDetails.documents.map((data, index) => (

                                                      <div class="col-md-6">
                                                         <a style={{ cursor: "pointer" }} onClick={() => document_details(data.id, data.filePath)} class="ml-2">
                                                            {/* <div class="proof_doc_container mt-3">
                                                                                                            <label style={{cursor:"pointer",color:"#3f88c5"}}>{data.name ? data.name : "--"}</label>
                                                                                                           <div class="proof_doc_wp">

                                                                                                                <img src="/images/icons/pdf-icon.svg"/>
                                                                                                            </div>
                                                                                                          <div class="mb-3 d-flex flex-column">
                                                                                                                <label class="mb-1 opacity-7"><a onClick={() => document_details(data.id, data.filePath)} >{data.name ? data.name : "--"}</a></label>
                                                                                                                <b>XXXXXXXXXXX</b>
                                                                                                            </div>
                                                                                                        </div> */}

                                                            <div class="doc-added-pdf d-flex p-2 align-items-center">
                                                               <img src="" class="mr-2" />
                                                               <span><p className="filename mb-0 txt-overflow-pdf" style={{ cursor: "pointer", color: "#3f88c5" }}>
                                                                  {/* <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>  */}
                                                                  {(() => {
                                                                     if (data.filePath.split(".")[1] == 'pdf') {
                                                                        return (
                                                                           <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                        )
                                                                     } else if (data.filePath.split(".")[1] == 'jpg' || data.filePath.split(".")[1] == 'png' || data.filePath.split(".")[1] == 'jpeg' || data.filePath.split(".")[1] == 'bmp') {
                                                                        return (
                                                                           <img src="/images/icons/image-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                        )
                                                                     } else if (data.filePath.split(".")[1] == 'doc' || data.filePath.split(".")[1] == 'word' || data.filePath.split(".")[1] == 'docx' || data.filePath.split(".")[1] == 'dot' || data.filePath.split(".")[1] == 'dotm' || data.filePath.split(".")[1] == 'dotx') {
                                                                        return (
                                                                           <img src="/images/icons/word-icon.svg" class="mr-2" style={{ maxWidth: "42px" }}></img>
                                                                        )
                                                                     } else {
                                                                        return (
                                                                           <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                        )
                                                                     }
                                                                  })()}
                                                                  {data.name ? data.name + '' + data.filePath.split(".")[1] : "--"} </p></span>
                                                            </div>
                                                         </a>

                                                      </div>


                                                   )) : <div class="col-md-4">
                                                      <div class="proof_doc_container mt-3">
                                                         <label>No documents added</label>
                                                         {/* <div class="proof_doc_wp" style={{height: "100%"}}>
                                             <img src="/images/no-vehicle-image.png" />
                                             </div> */}
                                                      </div>
                                                   </div>


                                                }
                                             </div>

                                          </div>

                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <!-- Modal --> */}
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Image</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <div class="m-auto">
                           <div class="avtar-wrap"></div>
                        </div>
                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                     </div>
                  </div>
               </div>
            </div>

            <EditProfile documentIDs={documentIdsArray}/>



            {/* <!-- doc edit --> */}
            <div class="modal fade" id="exampleModaldoc" tabindex="-1" aria-labelledby="exampleModalLabeldoc" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Photo</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <div class="m-auto">
                           <div class="doc-img-wrap">

                           </div>

                        </div>


                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                     </div>
                  </div>
               </div>
            </div>

            <div class="modal fade" id="exampleModallic" tabindex="-1" aria-labelledby="exampleModalLabeldlic" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Document</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <div class="m-auto">
                           <div class="doc-img-wrap">

                           </div>

                        </div>

                        <div class="row">

                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Licence Number</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>



                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Expiry Date</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                 <i> <img src="/images/icons/d" alt="" /></i>

                              </div>
                           </div>





                        </div>
                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="modal fade" id="uploadPicModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
               <div class="modal-content">
                  <div class="modal-header">
                     <h5 class="modal-title" id="exampleModalLabel">Upload Pic</h5>
                     <button type="button" class="close" data-dismiss="modal" id="btncloseImage">
                        <span aria-hidden="true">×</span>
                     </button>
                  </div>
                  <div class="modal-body">
                     <input type="file" class="w-100 p-3" onChange={onChange} accept="image/jpeg,image/png,image/jpeg" />
                     <div class="row mb-2" id="selectedImage">
                        <div class="col-12">

                           <div class="image-upload-plg">



                              <Cropper
                                 style={{ height: 300, width: "100%" }}
                                 aspectRatio={1}
                                 guides={false}
                                 dragMode={"move"}
                                 src={image}
                                 onInitialized={(instance) => {
                                    setCropper(instance);
                                 }}
                              />
                           </div>


                        </div>

                        <div>
                           <div className="box" style={{ width: "240px", height: "240px", float: "right", display: "none" }}>
                              <h1>Preview</h1>
                              <div className="img-preview" />
                           </div>

                        </div>
                     </div>
                     <div class="row mb-2" id="DB_Image">
                        <div className="m-auto mt-2">
                           <img src={image} style={{ width: "240px", height: "240px", marginTop:"20px" }} ></img>

                        </div>
                     </div>


                  </div>
                  <div class="modal-footer">
                     <button type="button" class="btn btn-secondary" data-dismiss="modal" id="btncloseImage">Close</button>
                     <button type="button" class="btn btn-primary"  id="uploadImageDisable" onClick={updateProfile}>Save changes</button>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
};

Profiles.propTypes = {};

Profiles.defaultProps = {};

export default Profiles;
