import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import vehicle_details_api from "../modules/components/vehicle/vehicle_details_api";

import CheckAuthentication from "./checkAuthentication";
const VehicleBasicInfo = () => {
  const [vechileDetails, setVehicleDetails] = useState([]);
  const [setCustomerId] = useState([]);
  const navigate = useNavigate();
  const { vehicleId, vid, id } = useParams();
  var vehicleIds = vehicleId || vid || id;
  console.log("######" + vehicleIds);
  useEffect(() => {
    if (CheckAuthentication) {
      vehicle_details_api(
        vehicleIds,
        // localStorage.getItem("vehicleId"),
        setVehicleDetails,
        setCustomerId,
        "dontcallcontactsAPI"
      );
    } else {
      sessionStorage.clear();
      localStorage.clear();
      //navigate("/login");
      window.location.href = "/login";
    }
  }, []);

  return (
    <>
      <div class="card">
        <div class="row mb-2">
          <div class="col-lg-12 col-md-12 m-auto grid-margin stretch-card ">
            <div class="header-top-bg p-3 ">
              <h4>Vehicle Information</h4>
              <div class="info-labl">
                <img src="/images/icons/vehicle-icon.svg" alt="" />|&nbsp;
                <span id="lisencePlateNumber">

                  {vechileDetails.unitNumber
                    ? vechileDetails.unitNumber
                    : "N/A"}
                </span>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="p-3 d-flex flex-column">
              <label for="" class="opacity-7">
                <span class="lableUpper">VIN</span> Number
              </label>
              <b id="vin">
                {vechileDetails.vinNumber ? vechileDetails.vinNumber : "N/A"}
              </b>
            </div>
          </div>

          <div class="col-md-4">
            <div class="p-3 d-flex flex-column">
              <label for="" class="opacity-7">
                Serial Number
              </label>
              <b id="serialNumber">
                {vechileDetails.serialNumber
                  ? vechileDetails.serialNumber
                  : "N/A"}
              </b>
            </div>
          </div>

          <div class="col-md-4">
            <div class="p-3 d-flex flex-column">
              <label for="" class="opacity-7">
                License Plate / <span class="lableUpper">S</span>tate
              </label>
              <b>
                {vechileDetails.lisencePlateNumber
                  ? vechileDetails.lisencePlateNumber
                  : "--"} / {vechileDetails.vehicleStateName
                    ? vechileDetails.vehicleStateName
                    : "--"}
              </b>
            </div>
          </div>

          <div class="col-md-4">
            <div class="p-3 d-flex flex-column">
              <label for="" class="opacity-7">
                Make
              </label>
              <b>{vechileDetails.make ? vechileDetails.make : "N/A"}</b>
            </div>
          </div>

          <div class="col-md-4">
            <div class="p-3 d-flex flex-column">
              <label for="" class="opacity-7">
                Model
              </label>
              <b>{vechileDetails.model ? vechileDetails.model : "N/A"}</b>
            </div>
          </div>


          {(() => {
            if (vechileDetails.trailerSubTypeName == "Tanker") {
              return (<>
                <div class="col-md-4">
                  <div class="p-3 d-flex flex-column">
                    <label for="" class="opacity-7">
                      Month and Year of manufacture
                    </label>
                    <b>{vechileDetails.mnthYrManfacture ? vechileDetails.mnthYrManfacture : "N/A"}</b>
                  </div>
                </div>
              </>)
            }
            if (vechileDetails.trailerSubTypeName == null || vechileDetails.trailerSubTypeName == "Trailer") {
              return (<>
                <div class="col-md-4">
                  <div class="p-3 d-flex flex-column"> <label for="" class="opacity-7">
                    Year
                  </label>
                    <b>{vechileDetails.year ? vechileDetails.year : "N/A"}</b>  </div>
                </div>
              </>)
            }
          })()}



          <div class="col-md-4">
            <div class="p-3 d-flex flex-column">
              <label for="" class="opacity-7">
                Vehicle Type
              </label>
              <b id="vehicleType">
                {vechileDetails.vehicleTypeName
                  ? vechileDetails.vehicleTypeName
                  : "N/A"}
              </b>
            </div>
          </div>

          {(() => {
            if (vechileDetails.trailerSubTypeName == "Tanker") {
              return (<>
                <div class="col-md-4">
                  <div class="p-3 d-flex flex-column">
                    <label for="" class="opacity-7">
                      Tanker sub type
                    </label>
                    <b>{vechileDetails.tankerSubTypeName=='Other' ? vechileDetails.otherTankerSubType : vechileDetails.tankerSubTypeName}</b>
                  </div>
                </div>
                
                {/* {vechileDetails.tankerSubTypeName=='Other'?
                <div class="col-md-4">
                  <div class="p-3 d-flex flex-column">
                    <label for="" class="opacity-7">
                      <span className="text-uppercase">Other</span>
                    </label>
                    <b>{vechileDetails.otherTankerSubType ? vechileDetails.otherTankerSubType : "N/A"}</b>
                  </div>
                </div>:""} */}
                
              </>)
            }
            else {
              return (<></>)
            }
          })()}

         


          {(() => {
            if (vechileDetails.trailerSubTypeName == "Tanker") {
              return (<>
                {/* <div class="col-md-4">
                  <div class="p-3 d-flex flex-column">
                    <label for="" class="opacity-7">
                      Tanker sub type
                    </label>
                    <b>{vechileDetails.tankerSubTypeName ? vechileDetails.tankerSubTypeName : "N/A"}</b>
                  </div>
                </div> */}
                <div class="col-md-4">
                  <div class="p-3 d-flex flex-column">
                    <label for="" class="opacity-7">
                      <span className="text-uppercase">MAWP (PSIG)</span>
                    </label>
                    <b>{vechileDetails.mawp ? vechileDetails.mawp : "N/A"}</b>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="p-3 d-flex flex-column">
                    <label for="" class="opacity-7">
                      Test Pressure <span className="text-uppercase">(PSIG)</span>
                    </label>
                    <b>{vechileDetails.testPressure ? vechileDetails.testPressure : "N/A"}</b>
                  </div>
                </div>
              </>)
            }
            else {
              return (<></>)
            }
          })()}

        </div>
      </div>
    </>
  );
};

VehicleBasicInfo.propTypes = {};

VehicleBasicInfo.defaultProps = {};

export default VehicleBasicInfo;
