import React,{history,useEffect, useState, useMemo } from "react";
// import { useKeycloak } from '@react-keycloak/web';
import { requestObject } from "../../utility/requestObject";
import $ from "jquery";
import {useNavigate , useParams } from "react-router-dom";
import services from "../../services/services";
import { keycloak } from "../../utility/config";
import CheckAuthentication from "../../utility/checkAuthentication";
import { Cookies } from 'react-cookie';
const Header = (props) => {
 // const { keycloak } = useKeycloak();
  const cookies = new Cookies();
  const [logo, setNavImage] = useState("");
  const [roles, setRoles] = useState("");
  const [profileImage, setNavImagesrc] = useState("");
  const [companyLogo, setcompanyLogo] = useState("");
  const[customerDetails,setcustomerDetails]=useState();
  const navigate = useNavigate();
  var pathArray = window.location.pathname.split('/');
  var secondLevelLocation = pathArray[1];
  const redirect=(page)=>
  {
    if(secondLevelLocation === 'fleetowner')
    {
      navigate("/fleetowner/settings");
    }
    else{
      navigate(page);
    }

  }
  useEffect(() =>
  {

  

  

  //  var userPic=sessionStorage.getItem("userProfilePic");
   // $("#posterURL").attr("src",res.customValues?res.customValues.userImageFileByte?"data:image/png;base64,"+res.customValues.userImageFileByte:"/images/transport-logo.jpg":"/images/transport-logo.jpg");

    // setNavImagesrc(res.customValues?res.customValues.userImageFileByte?"data:image/png;base64,"+res.customValues.userImageFileByte:"/images/transport-logo.jpg":"/images/transport-logo.jpg")
      // $("#profileDropdown").attr("src",userPic);

      // var companyLogo=sessionStorage.getItem("cmpnylogo");
      // var fleetcompanyLogo=sessionStorage.getItem("fleetCompanyLogo");
      // setcompanyLogo(fleetcompanyLogo);
      // var accountType=sessionStorage.getItem("accountType");
      // if(companyLogo!==null||fleetcompanyLogo!==null)
      // {

      //   $("#cmpnylogo").attr("src",companyLogo);
      //   $("#fleetCompanyLogo").attr("src",fleetcompanyLogo);
      // }else
      // {
      //   if(accountType==="garage")
      //   {

      //      $("#cmpnylogo").attr("src","/images/garage-logo/princeton-garage.jpg")

      //   }else{



      //   }


      // }

   




  }, []);

  // const IfitIsCustomer=()=>
  // {
  //   var customerListReq=
  //   {



  //   };



  //   var requestOptions=requestObject.RequestHeader(customerListReq);
  //   services.getUserDetails(requestOptions).then((res) =>
  //   {


  //     sessionStorage.setItem("userId",res.rows?res.rows.id:"");
  //     $("#posterURL").attr("src",res.customValues?res.customValues.userImageFileByte?"data:image/png;base64,"+res.customValues.userImageFileByte:"/images/transport-logo.jpg":"/images/transport-logo.jpg");

  //     setNavImagesrc(res.customValues?res.customValues.userImageFileByte?"data:image/png;base64,"+res.customValues.userImageFileByte:"/images/transport-logo.jpg":"/images/transport-logo.jpg")
  //     $("#profileDropdown").attr("src",res.customValues?res.customValues.userImageFileByte?"data:image/png;base64,"+res.customValues.userImageFileByte:"/images/transport-logo.jpg":"/images/transport-logo.jpg");
  //     setcustomerDetails(res.rows);
  //     sessionStorage.setItem("securityRoleId",res.rows?res.rows.securityRoleId:"");

  //     $("#customerNameH").html(res.rows?res.rows.firstName+" "+res.rows.lastName:"");
  //     sessionStorage.setItem("customerName",res.rows?res.rows.firstName+" "+res.rows.lastName:"");
  //     sessionStorage.setItem("securityRoleName",res.rows?res.rows.securityRoleName:"");
  //   });
  // }
  let timer = 0;
  let delay = 1000;
  let prevent = false;
  const logout=()=>
  {
    keycloak.init({initOptions: {

      pkceMethod: 'S256',
      // must match to the configured value in keycloak

      // this will solved the error
      checkLoginIframe: false
    }}).success(function(authenticated)
    {
    });
    timer = setTimeout(function()
    {
      if (!prevent)
      {

          keycloak.init({initOptions: {

            pkceMethod: 'S256',
            // must match to the configured value in keycloak
            // this will solved the error
            checkLoginIframe: false
          }}).success(function(authenticated)
          {

            cookies.remove('_AK');
              
            cookies.remove('_RK');
            cookies.remove('_ET');
          //  keycloak.logout({"redirectUri":"http://dotstow-prod.s3-website.us-east-2.amazonaws.com/login"});
            //  keycloak.logout({"redirectUri":"http://dotstowadmin-dev.s3-website.us-east-2.amazonaws.com/login"});
          // keycloak.logout({"redirectUri":"http://dotstowadmin.s3-website.us-east-2.amazonaws.com/login"});
            keycloak.logout({"redirectUri":"https://admin.dotstow.com/login"});
            //for development
           // http://dotstow-dev.s3-website.us-east-2.amazonaws.com/login
          //  keycloak.logout({"redirectUri":"http://localhost:3000/login"});
             // for production
              // keycloak.logout({"redirectUri":"http://dotstow-uat.s3-website.us-east-2.amazonaws.com/login"});
              sessionStorage.removeItem("comapnyName");
              sessionStorage.removeItem("fkAccountId");
              sessionStorage.removeItem("cmpnylogo");
              sessionStorage.removeItem("userId");
              sessionStorage.removeItem("customerName");
              sessionStorage.removeItem("email");
              sessionStorage.removeItem("accountType");
              sessionStorage.removeItem("userProfilePic");
              sessionStorage.removeItem("loggedinUser");

              localStorage.clear();
              // navigate("/");
            //  window.location.href="/";
              sessionStorage.removeItem('key');


          }).error(function() {

          });
            }
            prevent = false;
          }, delay);



  }


  return (
   <>

 <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row" >
      {/* <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">

        <a class="navbar-brand brand-logo mr-auto ml-2" href="/home">{logo=="1"?<img id="posterURL" class="mr-2" alt="logo"/>:<img src="/images/garage-logo/princeton-garage.jpg" class="mr-2" alt="logo"/>}</a>
       {logo=="1"?<b>{customerDetails?customerDetails.customerName:""}</b>:""}
      </div> */}

    
      <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center" >
        <a class="navbar-brand brand-logo mr-auto ml-2"><img src="/images/dotstow-logo.svg" class="mr-2" alt="logo" /><span><b  style={{"font-size":"14px"}}>DotStow Admin</b></span></a>
        {/* <b>{customerDetails?customerDetails.firstName:""}</b> */}
      </div>
      <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        {/* <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
          <span class="icon-menu"> <img src="/images/icons/d-arrow-icon.svg" alt=""/></span>
        </button> */}
        <ul class="navbar-nav mr-lg-2">
          {/* <!-- <li class="nav-item nav-search d-none d-lg-block">
            <div class="input-group">
              <div class="input-group-prepend hover-cursor" id="navbar-search-icon">
                <span class="input-group-text" id="search">
                  <i class="icon-search"></i>
                </span>
              </div>
              <input type="text" class="form-control" id="navbar-search-input" placeholder="Search now" aria-label="search" aria-describedby="search">
            </div>
          </li> --> */}
        </ul>

        <ul class="navbar-nav navbar-nav-right" >
          {/* <li class="nav-item dropdown">
            <a class="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
              <i>
                <img src="/images/icons/notification.svg" alt=""/>
            </i>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
              <p class="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
              <a class="dropdown-item preview-item">
                <div class="preview-thumbnail">
                  <div class="preview-icon bg-success">
                    <i class="ti-info-alt mx-0"></i>
                  </div>
                </div>
                <div class="preview-item-content">
                  <h6 class="preview-subject font-weight-normal">Application Error</h6>
                  <p class="font-weight-light small-text mb-0 text-muted">
                    Just now
                  </p>
                </div>
              </a>
              <a class="dropdown-item preview-item">
                <div class="preview-thumbnail">
                  <div class="preview-icon bg-warning">
                    <i class="ti-settings mx-0"></i>
                  </div>
                </div>
                <div class="preview-item-content">
                  <h6 class="preview-subject font-weight-normal">Settings</h6>
                  <p class="font-weight-light small-text mb-0 text-muted">
                    Private message
                  </p>
                </div>
              </a>
              <a class="dropdown-item preview-item">
                <div class="preview-thumbnail">
                  <div class="preview-icon bg-info">
                    <i class="ti-user mx-0"></i>
                  </div>
                </div>
                <div class="preview-item-content">
                  <h6 class="preview-subject font-weight-normal">New user registration</h6>
                  <p class="font-weight-light small-text mb-0 text-muted">
                    2 days ago
                  </p>
                </div>
              </a>
            </div>
          </li> */}
          <li class="nav-item nav-profile dropdown" >
             <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" data-toggle="dropdown"  >
               <img src="/images/faces/face28.png" alt="profile" id="profileDropdown" />
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
             
              {/* <a class="dropdown-item" onClick={() => redirect("/settings")} >
                <i class="ti-settings text-primary"></i>
                Company Profile
              </a> */}
              <a   class="dropdown-item" onClick={() => logout() }>
                <i class="ti-power-off text-primary"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
        <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
          <span class="icon-menu">
            <img src="/images/icons/hamburg.svg" width="30px" alt=""/>
          </span>
        </button>
      </div>
    </nav>
   </>

  );
};

export default Header;