import React,{history,useEffect, useState, useMemo } from "react";
import Tableheader from "../../../utility/tableheader";
import Pagination from "../../../utility/pagination";
import Search from "../../../utility/search";
import services from "../../../services/services";
import LoadSpinner from "../../../utility/LoadSpinner/LoadSpinner";
import {useLoading} from 'react-hook-loading';
import Swal from "sweetalert2";
import {useNavigate , useParams } from "react-router-dom";
import $ from 'jquery';
import getcustomerLists from "./customer_api";
//import { useKeycloak } from '@react-keycloak/web';
//import Keycloak from 'keycloak-js';
import { keycloak } from "../dashboard/login";
import checkAuthentication from "../../../utility/checkAuthentication";
import { loadingComponent } from "../../../utility/Loading";
const Customer = () => {
  const inlineCss={
    textAlign:"left"
}
const [roles, setRoles] = useState("");
  //const { keycloak } = useKeycloak();
  const [customerList,setCustomerList] = useState([]);
  const navigate = useNavigate();
  const [totalRecors,setTotalRecord] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalpages, settotalPages] = useState();
  const [customerName, setCustomerName] = useState("");
  const [displayPage, setdisplayPage] = useState([]);
  const [ITEMS_PER_PAGE, setitemperpage] = useState(25);
  const [loading, setLoading] = useLoading("Please wait...");
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const headers = [
    { name: "Customer Name", field: "Customer Name", sortable: true, thStyle: 'width="40%"',inlineStyle:inlineCss },
    { name: "Primary Contact", field: "Primary Contact", sortable: true,inlineStyle:inlineCss },
    { name: "Phone", field: "Phone", sortable: true,inlineStyle:inlineCss },
    { name: "Email", field: "Email", sortable: true,inlineStyle:inlineCss },
    { name: "Actions" }
];
const onSortingChanges = (field) => {
  const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

   setSortingField(field);
   setSortingOrder(order);
  getcustomerLists(currentPage,ITEMS_PER_PAGE,"",ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setCustomerList,setTotalRecord,setdisplayPage,field?field:"",order,"");

};
var fkAccountId=1;
useEffect(() =>
{
  setRoles(localStorage.getItem("Userroles"));
    if(checkAuthentication)
    {
      getcustomerLists("1",ITEMS_PER_PAGE,"",ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setCustomerList,setTotalRecord,setdisplayPage,sortingField,sortingOrder,"diplayItems");
      // $('#display_pages_in_items').on('change', function() {

      //   setitemperpage(this.value);

      //   getcustomerLists("1",ITEMS_PER_PAGE,customerName,this.value*ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setCustomerList,setTotalRecord,setdisplayPage,sortingField,sortingOrder,"");
      // });
    }
    else
    {
      sessionStorage.clear();
      localStorage.clear();
     //navigate("/login");
       window.location.href="/login";
    }



},[]);

const redirect=(page)=>
{

     navigate(page);

}
// const getcustomerLists=(pageNumber,ITEMS_PER_PAGE,customerName,pageSize)=>
// {
//   
//     var customerListReq=
//     {
//
//       "customerName":customerName,
//       "pageNumber":pageNumber,
//       "pageSize":pageSize,
//       "sortBy": "",
//       "sortDir":""
//     };


//   console.log("results="+JSON.stringify(customerListReq));

//   const requestOptions =
//   {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json'
//               },

//       body: JSON.stringify(customerListReq)
//   };

//   services.listofcustomers(requestOptions).then((res) =>
//   {
//    // setLoading(false);
//     setIsLoaded(currentIsLoaded => !currentIsLoaded);
//     if(res.success)
//     {
//       setTotalItems(res.customValues.totalNumberOfRecords);

//       settotalPages(res.customValues.totalNumberOfRecords);

//       setCurrentPage(res.customValues.currentPageNumber);
//       setCustomerList(res.rows);
//       setTotalRecord(res.customValues.totalNumberOfRecords);
//       var displaypages=[];
//       for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/ITEMS_PER_PAGE));i++)
//       {
//         displaypages.push(i);
//       }

//       setdisplayPage(displaypages);


//     }else{

//       //alert(res.message);

//     }

//   })

// }
const searchList = (customerName) =>
{
  setCustomerName(customerName);
  getcustomerLists("1",ITEMS_PER_PAGE,customerName,ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setCustomerList,setTotalRecord,setdisplayPage,sortingField,sortingOrder,"diplayItems");

}
const handlePageChange = (offset,triggerevent) =>
{

    getcustomerLists(offset,ITEMS_PER_PAGE,customerName,ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setCustomerList,setTotalRecord,setdisplayPage,sortingField,sortingOrder,"");

};
const displayPageNumbers =(e)=>
{

  setitemperpage(e.target.value);

  getcustomerLists("1",ITEMS_PER_PAGE,customerName,e.target.value,setTotalItems,settotalPages,setCurrentPage,setCustomerList,setTotalRecord,setdisplayPage,sortingField,sortingOrder,"");
}

  return(
  <>
  {loadingComponent}
   <div class="main-panel">
        <div class="content-wrapper" style={{display: "block;"}}>
         <div class="row">
              <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                <h4 class="font-weight-bold my-4 text-uppercase">Customers</h4>
             </div>
         </div>

          <div class="row">
            <div class="col-lg-11 col-md-12 m-auto grid-margin stretch-card">
              <div class="card">
                <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                    <div class="search position-relative align-self-start mb-1 mt-1">
                        {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search by name / Company" class="pl-5"/> */}
                        <Search
                                        placeholderName="Search by customer name"
                                        onSearch={value => {
                                          searchList(value);
                                        }}

                                    />
                       <a href="#" class="search-icon">
                           <img src="images/icons/search.svg" alt=""/>
                       </a>
                    </div>
                    <div class="d-flex align-self-start mb-1 mt-1">
                    {roles.match("Technician")||roles.match("Inspector")?"" : <a  onClick={() => redirect("/addCustomer/"+fkAccountId+"/")} class="btn btn-success">Add Customer</a>}
                    </div>
                </div>
                <div class="card-body pt-0 pb-0">

                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive border-top border-bottom">
                      <table class="table custome-table-mn">
                          <thead>
                          <tr>
                                {headers.map(({ name, field, sortable,thStyle,inlineStyle }) => (
                                    <th
                                        key={name}
                                        onClick={() =>
                                            sortable ? onSortingChanges(field) : null
                                        }
                                        class={thStyle} style={inlineStyle}>
                                        {name}

                                        {sortingField && sortingField === field && (

                                            sortingOrder === "asc"
                                                ? "↑"
                                                : "↓"
                                        )}

                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                        {
                                customerList?customerList.map((data, index) => (
                           <tr>
                              <td class="text-capitalize">{data.customerName?data.customerName:"--"}</td>
                              <td class="text-capitalize">{data.contactName?data.contactName:"--"} </td>
                              <td style={{maxWidth:"100px", whiteSpace:"break-spaces", lineHeight:"20px"}}>{data.contactNumber?data.contactNumber:"--"}</td>
                              <td style={{maxWidth:"200px", whiteSpace:"break-spaces", lineHeight:"20px"}}>{data.contactEmail?data.contactEmail:"--"}</td>
                              <td style={{"width":"50px"}}><a  onClick={() => redirect("/customerDetails/"+data.customerId+"/")}  class="btn btn-outline-primary text-uppercase">View</a></td>
                           </tr>
                                )):"no data"

                       }

                        </tbody>
                     </table>

                      </div>
                    </div>
                  </div>
                  </div>

                  <div class="d-flex align-items-center justify-content-between p-3">
                   <div class="d-flex">
                    <div class="dropdown show mr-3 ">
                    <select class="form-control custome-select" class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="display_pages_in_items" onChange={e => displayPageNumbers(e)}  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                       {

                                                    displayPage.map((data, index) => (
                                                        <option value={data}>{data}</option>
                                                    ))
                                       }
                                       </select>
                        {/* <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                         {ITEMS_PER_PAGE}
                        </a>

                        <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                          {
                            displayPage.map((data, index) => (

                            <a class="dropdown-item" href="#">{data}</a>

                            ))
                            }
                        </div> */}
                      </div>
                      <span class="d-flex align-items-center pagenation-mob">{currentPage*ITEMS_PER_PAGE<=totalRecors?currentPage*ITEMS_PER_PAGE:totalRecors} out of {totalRecors} Records Displayed</span>
                   </div>

                    <Pagination
                        total={totalRecors}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        //onPageChange={page => setCurrentPage(page)}
                       onPageChange={handlePageChange}
                    />
                        {/* <ul class="pagination mb-0">
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>
                          <li class="page-item"><a class="page-link" href="#">1</a></li>
                          <li class="page-item"><a class="page-link" href="#">2</a></li>
                          <li class="page-item"><a class="page-link" href="#">3</a></li>
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul> */}

                </div>

                </div>

              </div>

            </div>
        </div>

      </div>

  </>
)};

Customer.propTypes = {};

Customer.defaultProps = {};

export default Customer;
