import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import { itemperPagetoDisplay } from "../../constant";
const customerVehicle=(customerId,pageNumber,ITEMS_PER_PAGE,vehicleId,vehicleTypeId,pageSize,setTotalItems,settotalPages,setCurrentPage,setVehicleList,setTotalRecord,setdisplayPage,sortBy,sortDir,displayItems)=>
{
 
   /////////////get vechile list//////////////////////////

     var vechileReq="";
     var vechileReq="";
     if(sortBy==="Vin number")
     {

      sortBy="vinNumber";
     }
     else if(sortBy==="Unit number")
     {
      sortBy="unitNumber";
     } else if(sortBy==="Vehicle number")
     {

      sortBy="lisencePlateNumber";
     }
     else if(sortBy==="Vehicle type")
     {
      sortBy="vehicleTypeName";
     }
     else if(sortBy==="Email")
     {
      sortBy="customerEmail";
     }
     if(customerId=="")
     {
             vechileReq=
            {
                  
                  "lisencePlateNumber":vehicleId,
                  "pageNumber" : pageNumber,
                  "vehicleTypeId":"",
                  "pageSize": pageSize,
                  "sortBy": sortBy,
                  "sortDir":sortDir 
            
            };
     }else
	 {
	     vechileReq=
		  {
				
				"fkCustomerId":customerId,
				"lisencePlateNumber":vehicleId,
				// "lisencePlateNumber":"",
				"pageNumber" : pageNumber,
        "vehicleTypeId":vehicleTypeId,
				"pageSize":pageSize,
        "sortBy": sortBy,
        "sortDir":sortDir 
			 
		  }
	 }
     

      console.log("results="+JSON.stringify(vechileReq));

      // const requestOptions = 
      // {
      //       method: 'POST',
      //       headers:{'Content-Type': 'application/json'},
      //       body: JSON.stringify(vechileReq)
      // };
      var requestOptions=requestObject.RequestHeader(vechileReq);
      services.customer_vechile_list(requestOptions).then((res) => 
      {
        
            if(res.success)
            {

              setTotalItems(res.customValues.totalNumberOfRecords);
            
              settotalPages(res.customValues.totalNumberOfRecords);
             
              setCurrentPage(res.customValues.currentPageNumber);
              if(res.rows.length>0)
              {
                 
                  setVehicleList(res.rows);
              }else{

                
                setVehicleList("");
              }
             
              setTotalRecord(res.customValues.totalNumberOfRecords);
        
              var displaypages=[];
              if(displayItems=="displayItems")
              {
              
                      for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/itemperPagetoDisplay));i++)
                      {
                       
                        displaypages.push(i*itemperPagetoDisplay);
                      }
                      
                      setdisplayPage(displaypages);
              
              }
            }else{
            
              setVehicleList("");
              setdisplayPage("0");
              //alert(res.message);
             
            }
         
      });
   
   



}

export default customerVehicle;