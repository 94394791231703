import React,{history,useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useNavigate,useParams } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web';
import { ErrorMessage } from "@hookform/error-message";
//import checkAuthentication from "../../../utility/checkAuthentication";
//import { keycloak } from '../../../utility/keycloakConfig';
//import accountType from '../../../utility/keycloakConfig';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import Keycloak from 'keycloak-js';
import validator from 'validator';
import { useLoading } from "react-hook-loading";
import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import { keycloak } from "../../../utility/config";
import jwt_decode from 'jwt-decode'
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { loadingComponent } from "../../../utility/Loading";
import Swal from "sweetalert2";
import { Block } from "@material-ui/icons";
import { Carousel } from 'react-responsive-carousel';
import { useCookies } from 'react-cookie';
import CarouselComponent from "./CarouselComponent";
import UsNumberValidate from "../../../utility/usNumberValidate";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
const pageBodyWrapper={
display:"block",
marginTop:0
}

var pathName = window.location.pathname;

const Login = () =>
{


    const {
        register,
        formState: { errors },
        handleSubmit
     } = useForm({
        criteriaMode: "all", mode: "onKeyup",
     });
   const [cookies, setCookie] = useCookies(['_AK','_RK']);
   const [authenticate, setAuthenticate] = useState(false);
   const navigate = useNavigate();
   const [emailError, setEmailError] = useState('');
   const [itemName, setFieldName] = useState('');
   const [captch, setcaptcha] = useState('');
   const [loading, setLoading] = useLoading("Please wait...");
   const validateEmail = (e) =>
   {
     var email = e.target.value.replace(/^\s+|\s+$/gm,'');

     if (validator.isEmail(email.trim()))
     {
       setEmailError('');
     }
     else
     {
       setEmailError('Invalid email address')
     }

   }
//    const {
//      register,
//      formState: { errors },
//      handleSubmit
//    } = useForm({
//      criteriaMode: "all",mode: "onBlur"
//    });

function onChange(value) {
    setcaptcha(value)

  }


   useEffect(() =>
   {

    $('#edit_mobile').usPhoneFormat();
    $("#mydiv").removeClass("container-fluid page-body-wrapper");

    if (keycloak.authenticated)
    {

      if(keycloak.token!="")
      {

       

        setAuthenticate(true);

        setCookie('_AK', keycloak.token,{"path":"/",maxAge:"1704085200"});
        setCookie('_RK', keycloak.refreshToken,{"path":"/",maxAge:"1704085200"});
        setCookie('_ET', keycloak.tokenParsed.exp,{"path":"/",maxAge:"1704085200"});
alert(keycloak.tokenParsed.exp)
         localStorage.setItem("accToken",keycloak.tokenParsed.exp);
        sessionStorage.setItem("fkAccountId","1");
        // var accessTokenT=localStorage.getItem("accToken");
        // var decoded = jwt_decode(keycloak.token);
        // var rolemanage=decoded.resource_access;
        // alert(rolemanage["dotstow_ui"].roles[0]);
        // localStorage.setItem("Userroles", JSON.stringify(rolemanage["platform_admin_ui"].roles[0]));
         window.location.href='/clients';
        //  window.location.href='/garage/dashboard';
            
        // var customerListReq =
        // {

        // };

        // var requestOptions = requestObject.RequestHeader(customerListReq);
        // services.getAccountType(requestOptions).then((res) =>
        // {

        //    sessionStorage.setItem("accountType",res.rows.accountTypeName);
          
        //    window.location.href='/clients';
        // //    if(res.rows.accountTypeName=='garage')
        // //    {

        // //     window.location.href='/garage/dashboard';


        // //    }if(res.rows.accountTypeName=='fleet')
        // //    {
        // //       window.location.href="/fleetowner/dashboard";

        // //    }

        // });
      


        }
        else
        {
            
          sessionStorage.clear();
          localStorage.clear();
        }


    }else{

    }


   }, []);

   const scroolRedirect = (areaId) => {

        if(areaId=="garage_wrap" || areaId=="newsletter_wrap"){
          $('html,body').animate({
            scrollTop: $("."+areaId).offset().top},
            'smooth');
        }
        if(areaId=="garage_wrap"){
            document.getElementById("features").setAttribute("class", "nav-link active");
            $('#home').removeClass('active');
            $('#contactUs').removeClass('active');
        }if(areaId=="newsletter_wrap"){
            document.getElementById("contactUs").setAttribute("class", "nav-link active");
            $('#features').removeClass('active');
            $('#home').removeClass('active');
        }if(areaId=="home"){
            document.getElementById("home").setAttribute("class", "nav-link active");
            $('#contactUs').removeClass('active');
            $('#features').removeClass('active');
        }
    }

   const mobilevalidate=(e)=>
   {
    $('#edit_mobile').usPhoneFormat();
   }
   const loginMethod=(e)=>
   {
            var email=$("#userEmail").val();

           if(validator.isEmail(email))
            {
                setEmailError('');
                keycloak.login({loginHint:email});
                // var userDetails=
                // {

                //   "email":email,

                // };

                // const requestOptions =
                // {
                //     method: 'POST',
                //     headers: { 'Content-Type': 'application/json'},
                //     body: JSON.stringify(userDetails)
                // };

        //       services.getUserByEmailId(requestOptions).then((res) =>
        //       {
        //         document.getElementById("edit_close").click();
        //        if(res.rows.tenantName!==null)
        //        {



        //         sessionStorage.setItem("email",email);
        //        // localStorage.setItem("realm",res.rows.tenantName);
        //         // console.log(res.rows);
        //         // alert(res.rows.tenantName);
        //         // const keycloaksetup=KeycloakSetup();

        //         // alert("keycloaksetup=="+JSON.stringify(keycloaksetup))
        //        // const keycloak= KeycloakSetup()
        //     //   var authUrl="http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth"
        //       //  var authUrl=""
        //       //  switch(res.rows.tenantName)
        //       //  {
        //       //    case "Dev":
        //       //     authUrl= "http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth";
        //       //      break;
        //       //     case "dotstow":
        //       //      authUrl="http://keycloak-dotstow-qa-alb-1314093332.us-east-2.elb.amazonaws.com/auth";
        //       //         break;
        //       //       default:
        //       //         authUrl= "http://keycloak-dotstow-dev-alb-1135353619.us-east-2.elb.amazonaws.com/auth";

        //       //          break;
        //       //  }

        //     //   keycloak.authServerUrl=authUrl;
        //   ///  keycloakAuth.login({idpHint: 'facebook'});
        //         //keycloak.loginHint=email;

        //         // keycloak.realm=res.rows.tenantName;

             
        //        }
        //        else{
        //           Swal.fire({
        //           position: 'center',
        //           icon: 'error',
        //           title: "",
        //           text:"Email not registered with DotStow",
        //           showConfirmButton: true

        //         }).then((result) =>
        //         {

        //               if(result.isConfirmed)
        //               {


        //               }
        //         });

        //        }
        //         // keycloak.login('/garage/dashboard');

        //       });


            }else
            {
              setEmailError('Enter valid Email!')
            }

            // if(keycloak.authenticated)
            // {

            //     navigate('/garage/dashboard');
            // }
            // else
            // {

            //     keycloak.login('/garage/dashboard');
            // }
    }
//    const contact_us=async (event, error) =>
//    {

//        if(captch==null||captch=="")
//        {

//          Swal.fire({
//             position: 'center',
//             icon: 'error',
//             title: "",
//             text:"Please verify the captcha",
//             showConfirmButton: true

//           }).then((result) =>
//           {

//                 if(result.isConfirmed)
//                 {


//                 }
//           });

//        }else{
//         // setLoading(true);
//         $("#loadingforsubmit").show();
//         var conactUs=
//         {
//                   "firstName":$("#edit_fristName").val(),
//                   "lastName":$("#edit_lastName").val(),
//                   "phoneNumber":$("#edit_mobile").val(),
//                   "email":$("#edit_email").val(),
//                   "companyName":$("#edit_Comapny").val(),
//                   "message":$("#Message").val()

//         };
//         var requestOptions=requestObject.RequestHeader(conactUs);
//         services.sendMessageToAdmin(requestOptions).then((res)=>
//         {
//             // setLoading(false);
//            $("#loadingforsubmit").hide();
//             Swal.fire({
//              position: 'center',
//              icon: 'success',
//              title: "",
//              text:res.message,
//              showConfirmButton: true

//            }).then((result) =>
//            {

//                  if(result.isConfirmed)
//                  {
//                     navigate("/login");
//                     $("#contact_form").trigger('reset');
//                     // $('#rc-anchor-container').trigger('reset');
//                     // ReCAPTCHA.trigger('reset');
//                  }
//            });
//         })
//        }


//    }
   const handleOnEvent = async (event, error) =>
   {

    if (event === 'onAuthSuccess')
    {

      if (keycloak.authenticated)
      {

        if(keycloak.token!="")
        {

          
           $(".pp").hide();
           setAuthenticate(true);
           setCookie('_AK', keycloak.token,{"path":"/",maxAge:"1704085200"});
           setCookie('_RK', keycloak.refreshToken,{"path":"/",maxAge:"1704085200"});
           setCookie('_ET', keycloak.tokenParsed.exp,{"path":"/",maxAge:"1704085200"}); 
       
           localStorage.setItem("accToken",keycloak.tokenParsed.exp);
           sessionStorage.setItem("fkAccountId","1");

            window.location.href='/clients';
            
            //  if(res.rows.accountTypeName=='garage')
            //  {

            //   window.location.href='/clients';


            //  }
            //  if(res.rows.accountTypeName=='fleet')
            //  {
            //     window.location.href="/fleetowner/dashboard";

            //  }

  
       


          }


      }else{

      }
    }else{

    }
  }
  const InitialloadingComponent = (
    <div class="loader-wrapper" id="loading">
      <div class="truck-wrapper">
        <div class="truck">
          <div class="truck-container"></div>
          <div class="glases"></div>
          <div class="bonet"></div>

          <div class="base"></div>

          <div class="base-aux"></div>
          <div class="wheel-back"></div>
          <div class="wheel-front"></div>

          <div class="smoke"></div>
        </div>
      </div>
</div>
  )
  return(
  <>
  {authenticate?InitialloadingComponent:
     <ReactKeycloakProvider
      authClient={keycloak}

      LoadingComponent={InitialloadingComponent}
     onEvent={(event, error) => handleOnEvent(event, error)}>
         {LoadingComponentForSubmit}
  <div class="pp">
  <header class="main_header">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container">
                <a class="navbar-brand" href="#"><img src="/img/logo-dotstow.svg" style={{width: "90px"}}/></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a class="nav-link active" aria-current="page" href="#" onClick={() => scroolRedirect("home")} id="home">Home</a></li>
                        <li class="nav-item" ><a class="nav-link" href="#" onClick={() => scroolRedirect("garage_wrap")} id="features">Features</a></li>
                        {/* <li class="nav-item"><a class="nav-link" href="#" onClick={() => scroolRedirect("newsletter_wrap")} id="contactUs">Contact Us</a></li> */}

                    </ul>
                    <a class="btn btn-outline-primary" data-toggle="modal" data-target="#edit_Contact">SIGN IN</a>
                    {/* <a href="#" data-toggle="modal" data-target="#edit_Contact" >Edit</a> */}
                </div>
            </div>
        </nav>
    </header>

    <section class="home_banner_wrap">
      <CarouselComponent/>
         {/* <div class="carousel-wrapper">
            <Carousel infiniteLoop useKeyboardArrows autoPlay>
                <div>
                    <img src="../img-01.jpg" />
                </div>
                <div>
                    <img src="../img-02.jpg" />
                </div>
                <div>
                    <img src="../img-03.jpg" />
                </div>
            </Carousel>
        </div> */}
        {/* <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="/img/banner-1.png" class="img-fluid"/>
                    <div style={{position: "absolute",left: "0",top: "0",width: "100%",height: "100%"}}>
                        <div class="container" style={{position: "relative",height: "100%"}}>
                            <div class="carousel-caption d-md-block animate__animated animate__fadeInUp">
                                <h3>Simplify the vehicle'sinspection.</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <img src="/img/banner-2.png" class="img-fluid"/>
                    <div style={{position: "absolute",left: "0",top: "0",width: "100%",height: "100%"}}>
                        <div class="container" style={{position: "relative",height: "100%"}}>
                            <div class="carousel-caption d-md-block animate__animated animate__fadeInUp">
                                <h3>Lorem ipsum dolor sit consectetur elit.</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <img src="/img/banner-3.png" class="img-fluid"/>
                    <div style={{position: "absolute",left: "0",top: "0",width: "100%",height: "100%"}}>
                        <div class="container" style={{position: "relative",height: "100%"}}>
                            <div class="carousel-caption d-md-block animate__animated animate__fadeInUp">
                                <h3>Lorem ipsum dolor sit consectetur elit.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
               <span class="carousel-control-prev-icon" aria-hidden="true"></span>
               <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
               <span class="carousel-control-next-icon" aria-hidden="true"></span>
               <span class="visually-hidden">Next</span>
            </button>
        </div> */}
    </section>


    <section class="features_wrap garage_wrap">
	<div class="safe-hard">
                <img src="/img/hazard-3.png" class="sh-rt" alt=""/>
            </div>
        <div class="container">

            <div class="title_wrap">
                <h5>Garage</h5>
                <p>
                Customer service is an important role for any garage or repair facility, <br /> meeting the customer's needs or wants is vital to making a better customer experience.
                </p>
                <div class="divider">
                    <span></span><span></span><span></span>
                </div>
            </div>
            <div class="feature_tabs">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="inspections-tab" data-bs-toggle="tab" data-bs-target="#inspections" type="button" role="tab" aria-controls="inspections" aria-selected="true">Inspections</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="vehicles-tab" data-bs-toggle="tab" data-bs-target="#vehicles" type="button" role="tab" aria-controls="vehicles" aria-selected="false">Vehicles</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="documentsGarage-tab" data-bs-toggle="tab" data-bs-target="#documentsGarage" type="button" role="tab" aria-controls="documentsGarage" aria-selected="false">Documents</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="customers-tab" data-bs-toggle="tab" data-bs-target="#customers" type="button" role="tab" aria-controls="customers" aria-selected="false">Customers</button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="inspections" role="tabpanel" aria-labelledby="inspections-tab">

                        <div class="row">
                            <div class="col-sm-12 col-md-5 d-flex align-items-center">
                                <section>
                                    <div class="feature_icon">
                                        <img src="/img/inspection.svg" />
                                    </div>
                                    <div class="feature_label">Inspections</div>
                                    <h1>Optimize your vehicle's compliance</h1>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            {/* <p>Add Vehicle's details.</p> */}
                                            <p>Maintain one location for documents regarding inspection or testing.</p>
                                        </div>

                                    </div>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            {/* <p>Add the Vehicle's compliance documents.</p> */}
                                            <p>A record of all inspections saved for each specific vehicle or piece of equipment.</p>
                                        </div>

                                    </div>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            {/* <p>Add Customer information</p> */}
                                            <p>A database of completed inspections at your finger tip in case an unexpected event or audit was to happen</p>
                                        </div>

                                    </div>
                                </section>
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <div class="feature_intro">
                                    <div class="inn">
                                        <img src="/img/inspection-temp.png" class="animate__animated animate__fadeInUpSmall img_inspections_1"/>
                                        <img src="/img/vehicleinfo-2.png" class="animate__animated animate__bounceIn animate__delay-1s img_inspections_2"/>
                                        <img src="/img/inspection-pop.png" class="animate__animated animate__fadeInUpSmall animate__delay-2s img_inspections_3"/>
                                        <div class="garage_circle_bg"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="vehicles" role="tabpanel" aria-labelledby="vehicles-tab">

                        <div class="row">
                            <div class="col-sm-12 col-md-5 d-flex align-items-center">
                                <section>
                                    <div class="feature_icon">
                                        <img src="/img/icon-vehicle.svg" />
                                    </div>
                                    <div class="feature_label">Vehicles</div>
                                    <h1>Optimize your vehicle's compliance</h1>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}} />

                                            <p>Maintain and store vehicle information that is readily available to all those in the process.</p>
                                        </div>

                                    </div>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                        <p>Maintain an up to date report of current, past and upcoming vehicle inspection or service.</p>
                                        </div>

                                    </div>
                                </section>
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <div class="feature_intro">
                                    <div class="inn">
                                        <img src="/img/vehicle-temp.png" class="animate__animated animate__fadeInUpSmall img_inspections_1"/>
                                        <img src="/img/vehicleinfo-2.png" class="animate__animated animate__bounceIn animate__delay-1s img_inspections_2"/>
                                        <img src="/img/vehicle-pop.png" class="animate__animated animate__fadeInUpSmall animate__delay-2s img_inspections_3"/>
                                        <div class="garage_circle_bg"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="documentsGarage" role="tabpanel" aria-labelledby="documentsGarage-tab">

                        <div class="row">
                            <div class="col-sm-12 col-md-5 d-flex align-items-center">
                                <section>
                                    <div class="feature_icon">
                                        <img src="/img/doc.svg" style={{width:"27px"}} />
                                    </div>
                                    <div class="feature_label">Documents</div>
                                    <h1>Optimize your vehicle's compliance</h1>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            <p>All documents stored safely and protected from being lost.</p>
                                        </div>

                                    </div>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            <p>Years of documents without the large amount of boxes needed.</p>
                                        </div>
                                    </div>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            <p>Documents that you control and where you want them kept.</p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <div class="feature_intro">
                                    <div class="inn">
                                        <img src="/img/document-temp.png" class="animate__animated animate__fadeInUpSmall img_inspections_1"/>
                                        <img src="/img/vehicleinfo-2.png" class="animate__animated animate__bounceIn animate__delay-1s img_inspections_2"/>
                                        <img src="/img/document-pop.png" class="animate__animated animate__fadeInUpSmall animate__delay-2s img_inspections_3"/>
                                        <div class="garage_circle_bg"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="customers" role="tabpanel" aria-labelledby="customers-tab">

                        <div class="row">
                            <div class="col-sm-12 col-md-5 d-flex align-items-center">
                                <section>
                                    <div class="feature_icon">
                                        <img src="/img/customer.svg" />
                                    </div>
                                    <div class="feature_label">Customers</div>
                                    <h1>Optimize your vehicle's compliance</h1>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            <p>Maintain an accurate and current record of all customers and each vehicle that belongs to that customer</p>
                                        </div>

                                    </div>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            <p>Give your customer the confidence of knowing that information is available when needed and is able to be shared upon request.</p>
                                        </div>

                                    </div>
                                </section>
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <div class="feature_intro">
                                    <div class="inn">
                                        <img src="/img/customer-temp.png" class="animate__animated animate__fadeInUpSmall img_inspections_1"/>
                                        <img src="/img/vehicleinfo-2.png" class="animate__animated animate__bounceIn animate__delay-1s img_inspections_2"/>
                                        <img src="/img/customer-pop.png" class="animate__animated animate__fadeInUpSmall animate__delay-2s img_inspections_3"/>
                                        <div class="garage_circle_bg"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="features_wrap feetowner_wrap">
	<div class="safe-hard ">
                <img src="/img/hazard-1.png" class="sh-lt" alt=""/>
            </div>
        <div class="container">

            <div class="title_wrap">
                <h5>Fleet Owners</h5>
                <p>Current and accurate vehicle information and/or status of a vehicle are vital role to your fleet moving in a positive direction, <br /> which translates to positive results.</p>
                <div class="divider">
                    <span></span><span></span><span></span>
                </div>
            </div>
            <div class="feature_tabs">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="vehicleinfo-tab" data-bs-toggle="tab" data-bs-target="#vehicleinfo" type="button" role="tab" aria-controls="vehicleinfo" aria-selected="true">Vehicle Info</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="documents-tab" data-bs-toggle="tab" data-bs-target="#documents" type="button" role="tab" aria-controls="documents" aria-selected="false">Documents</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="reminders-tab" data-bs-toggle="tab" data-bs-target="#reminders" type="button" role="tab" aria-controls="reminders" aria-selected="false">Reminders</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="employees-tab" data-bs-toggle="tab" data-bs-target="#employees" type="button" role="tab" aria-controls="employees" aria-selected="false">Employees</button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="vehicleinfo" role="tabpanel" aria-labelledby="vehicleinfo-tab">

                        <div class="row">
                            <div class="col-sm-12 col-md-5 d-flex align-items-center">
                                <section>
                                    <div class="feature_icon">
                                        <img src="/img/icon-vehicle.svg" />
                                    </div>
                                    <div class="feature_label">Vehicle Info</div>
                                    <h1>Optimize your vehicle's compliance</h1>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            <p>Maintain an accurate and complete database of all vehicles or equipment in your fleet.</p>
                                        </div>

                                    </div>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            <p>An up to date timeline of what equipment needs serviced or inspected</p>
                                        </div>
                                     </div>
                                     <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            <p>Be in front of the mandate to be all digital in the future</p>
                                        </div>
                                     </div>
                                </section>
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <div class="feature_intro">
                                    <div class="inn">
                                        <img src="/img/vehicle-temp.png" class="animate__animated animate__fadeInUpSmall img_inspections_1"/>
                                        <img src="/img/vehicleinfo-2.png" class="animate__animated animate__bounceIn animate__delay-1s img_inspections_2"/>
                                        <img src="/img/vehicle-pop.png" class="animate__animated animate__fadeInUpSmall animate__delay-2s img_inspections_3"/>
                                        <div class="feetowner_circle_bg"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="documents" role="tabpanel" aria-labelledby="documents-tab">

                        <div class="row">
                            <div class="col-sm-12 col-md-5 d-flex align-items-center">
                                <section>
                                    <div class="feature_icon">
                                        <img src="/img/doc.svg" style={{width:"27px"}} />
                                    </div>
                                    <div class="feature_label">Documents</div>
                                    <h1>Optimize your vehicle's compliance</h1>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            <p>All documents stored safely and protected from being lost.</p>
                                        </div>
                                     </div>

                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            <p>Years of documents without the large amount of boxes needed.</p>
                                        </div>
                                     </div>

                                     <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            <p>Documents that you control and where you want them kept.</p>
                                        </div>
                                     </div>
                                </section>
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <div class="feature_intro">
                                    <div class="inn">
                                        <img src="/img/document-temp.png" class="animate__animated animate__fadeInUpSmall img_inspections_1"/>
                                        <img src="/img/vehicleinfo-2.png" class="animate__animated animate__bounceIn animate__delay-1s img_inspections_2"/>
                                        <img src="/img/document-pop.png" class="animate__animated animate__fadeInUpSmall animate__delay-2s img_inspections_3"/>
                                        <div class="feetowner_circle_bg"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="reminders" role="tabpanel" aria-labelledby="reminders-tab">

                        <div class="row">
                            <div class="col-sm-12 col-md-5 d-flex align-items-center">
                                <section>

                                    <div class="feature_icon">
                                        <img src="/img/reminder.svg" />
                                    </div>
                                    <div class="feature_label">Reminders</div>
                                    <h1>Optimize your vehicle's compliance</h1>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            <p>Don’t get that unexpected violation because of a pass due inspection or test</p>
                                        </div>

                                    </div>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            <p>Be in front of your anticipated events with reminders set up in the system</p>
                                        </div>

                                    </div>
                                </section>
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <div class="feature_intro">
                                    <div class="inn">
                                        <img src="/img/reminder-temp.png" class="animate__animated animate__fadeInUpSmall img_inspections_1"/>
                                        <img src="/img/reminder-icon.png" class="animate__animated animate__bounceIn animate__delay-1s img_inspections_2"/>
                                        <img src="/img/reminder-pop.png" class="animate__animated animate__fadeInUpSmall animate__delay-2s img_inspections_3"/>
                                        <div class="feetowner_circle_bg"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="employees" role="tabpanel" aria-labelledby="employees-tab">

                        <div class="row">
                            <div class="col-sm-12 col-md-5 d-flex align-items-center">
                                <section>
                                    <div class="feature_icon">
                                        <img src="/img/employee.svg" />
                                    </div>
                                    <div class="feature_label">Employee</div>
                                    <h1>Optimize your vehicle's compliance</h1>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            <p>Do you need quick access to employees information? Accurate employee information can be kept and ready for access when requested</p>
                                        </div>

                                    </div>
                                    <div class="feature_list">
                                        <div class="list_title"><img src="/img/tick.png" style={{width: "20px"}}/>
                                            <p>Protected information and available to a designated person</p>
                                        </div>

                                    </div>

                                </section>
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <div class="feature_intro">
                                    <div class="inn">
                                        <img src="/img/employee-temp.png" class="animate__animated animate__fadeInUpSmall img_inspections_1"/>
                                        <img src="/img/vehicleinfo-2.png" class="animate__animated animate__bounceIn animate__delay-1s img_inspections_2"/>
                                        <img src="/img/employee-pop.png" class="animate__animated animate__fadeInUpSmall animate__delay-2s img_inspections_3"/>
                                        <div class="feetowner_circle_bg"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>

{/* 
    <section class="newsletter_wrap">
	 <div class="safe-hard ">
                <img src="/img/hazard-2.png" class="sh-lt" alt=""/>
            </div>
        <div class="container">

            <div class="row justify-content-center">
                <div class="col-sm-12 col-md-auto">
                    <div class="newsletter_inner">
                        <div class="news_header">
                            <h3>Contact Us</h3>
                            <p>Let us know your questions. We're here to help!</p>
                        </div>
                        <div class="news_content">
                            <form onSubmit={handleSubmit(contact_us)} id="contact_form">
                                <div class="row">
                                    <div class="col-sm-12 col-md-4">
                                        <label class="form-label">First name<span class="star">*</span></label>
                                        <input type="text" class="form-control" placeholder="First name"  id="edit_fristName" maxLength="20" minLength="2"  onChange={e => setFieldName(e.target.value)}    {...register("edit_Firstname", {
                                       required: "First name is required",
                                       maxLength: {
                                        value: 20,
                                        message: "First name shouldn't exceed the max length 20 characters"
                                        },
                                     minLength: {
                                           value: 2,
                                           message: "First name atleast 2 characters"
                                        }
                                        })} />
                                        <ErrorMessage
                                        errors={errors}
                                        name="edit_Firstname"
                                        class="invalid-feedback"
                                        render={({ messages }) => {

                                            return messages
                                                ? Object.entries(messages).map(([type, message]) => (
                                                    <span key={type} class="error">{message}</span>
                                                ))
                                                : null;
                                        }}
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-4">
                                        <label class="form-label">Last name<span class="star">*</span></label>
                                        <input type="text" class="form-control" placeholder="Last name" id="edit_lastName" maxLength="20" minLength="2"  onChange={e => setFieldName(e.target.value)}    {...register("edit_Lastname", {
                                       required: "Last name is required",
                                       maxLength: {
                                        value: 20,
                                        message: "Last name shouldn't exceed the max length 20 characters"
                                        },
                                     minLength: {
                                           value: 2,
                                           message: "Last name atleast 2 characters"
                                        }
                                    })} />
                                    <ErrorMessage
                                       errors={errors}
                                       name="edit_Lastname"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                    </div>
                                    <div class="col-sm-12 col-md-4">
                                        <label class="form-label">Phone number<span class="star">*</span></label>
                                        <input type="text" class="form-control" placeholder="Phone number" onKeyPress={mobilevalidate} id="edit_mobile" aria-describedby="emailHelp" {...register("MobileNumber", {
                                       required: "Phone number is required",
                                       maxLength: {
                                        value: 12,
                                        message: "Phone number should be 10 digits"
                                        },
                                     minLength: {
                                           value: 12,
                                           message: "Phone number should be 10 digits"
                                        } ,
                                    })}/>
                                    <ErrorMessage
                                       errors={errors}
                                       name="MobileNumber"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6">
                                        <label class="form-label">Email address<span class="star">*</span></label>
                                        <input type="text" class="form-control" placeholder="Email address"  id="edit_email"   onChange={e => setFieldName(e.target.value)} {...register("edit_companyEmail", {
                                       required: "Email is required",

                                       pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                          message: "Invalid email address"
                                       }
                                    })} />
                                    <ErrorMessage
                                       errors={errors}
                                       name="edit_companyEmail"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <label class="form-label">Company name<span class="star">*</span></label>
                                        <input type="text" class="form-control" placeholder="Company name" id="edit_Comapny" onChange={e => setFieldName(e.target.value)}    {...register("edit_Comapny", {
                                       required: "Company name is required",
                                       maxLength: {
                                        value: 50,
                                        message: "Company name shouldn't exceed the max length 20 characters"
                                        },
                                     minLength: {
                                           value: 2,
                                           message: "Company name atleast 2 characters"
                                        }
                                        })} />
                                        <ErrorMessage
                                        errors={errors}
                                        name="edit_Comapny"
                                        class="invalid-feedback"
                                        render={({ messages }) => {

                                            return messages
                                                ? Object.entries(messages).map(([type, message]) => (
                                                    <p key={type} class="error">{message}</p>
                                                ))
                                                : null;
                                        }}
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <label class="form-label">Message </label>
                                        <textarea class="form-control" rows="4" id="Message"></textarea>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                    <ReCAPTCHA
                                        sitekey="6LcoWs8gAAAAAJZT_brbW1TlJHGsoppfRnW2p407"
                                        onChange={onChange}
                                    />
                                        <button type="submit" class="btn btn-primary w-100 mt-3">SUBMIT</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    <footer class="footer_wrap">
        <div class="container">
            <div class="row d-flex justify-content-between">
                <div class="col-sm-12 col-md-6">
                    <div class="copyrights">
                        <p>© 2022 DotStow. All rights reserved. <a href="/privacy">Privacy Policy</a><a href="/terms">Terms & Conditions</a></p>
                    </div>
                </div>
                <div class="col-sm-auto col-md-auto ">
                    <div class="social_icons">
                        <a href="#" class="svg_icon">
                            <svg height="20" viewBox="0 0 9.327 20.073">
                           <path d="M27.309,12.6H24.133V10.512a.848.848,0,0,1,.884-.965h2.242V6.108L24.171,6.1A3.909,3.909,0,0,0,19.964,10.3V12.6H17.982V16.14h1.982V26.169h4.169V16.14h2.813Z" transform="translate(-17.982 -6.096)" />
                        </svg>
                        </a>
                        <a href="#" class="svg_icon">
                            <svg width="20" viewBox="0 0 24.7 20.073">
                           <path d="M28.274,12.7a10.083,10.083,0,0,1-2.911.8,5.077,5.077,0,0,0,2.228-2.8,10.144,10.144,0,0,1-3.218,1.23,5.072,5.072,0,0,0-8.635,4.622A14.388,14.388,0,0,1,5.294,11.254a5.074,5.074,0,0,0,1.568,6.766,5.053,5.053,0,0,1-2.3-.634c0,.021,0,.043,0,.064a5.072,5.072,0,0,0,4.065,4.969A5.1,5.1,0,0,1,7.3,22.6a5.021,5.021,0,0,1-.954-.091,5.073,5.073,0,0,0,4.734,3.519,10.226,10.226,0,0,1-7.5,2.1A14.412,14.412,0,0,0,25.76,15.981q0-.329-.014-.656A10.264,10.264,0,0,0,28.274,12.7Z" transform="translate(-3.574 -10.326)" />
                         </svg>
                        </a>
                        <a href="#" class="svg_icon">
                            <svg width="20" viewBox="0 0 20.073 20.073">
                           <path data-name="Path 72" d="M20.58,16.7a5.18,5.18,0,1,0,5.18,5.18A5.19,5.19,0,0,0,20.58,16.7Zm0,8.5A3.319,3.319,0,1,1,23.9,21.88,3.334,3.334,0,0,1,20.58,25.2Z" transform="translate(-10.544 -11.884)" />
                           <circle data-name="Ellipse 18" cx="1.174" cy="1.174" r="1.174" transform="translate(14.245 3.521)"/>
                           <path data-name="Path 73" d="M21.854,6.459A5.764,5.764,0,0,0,17.6,4.8H9.268A5.544,5.544,0,0,0,3.4,10.668v8.3a5.823,5.823,0,0,0,1.7,4.33,5.906,5.906,0,0,0,4.209,1.578h8.256a5.976,5.976,0,0,0,4.249-1.578A5.794,5.794,0,0,0,23.473,19V10.668A5.8,5.8,0,0,0,21.854,6.459ZM21.692,19a3.954,3.954,0,0,1-1.174,2.954,4.183,4.183,0,0,1-2.954,1.052H9.309a4.183,4.183,0,0,1-2.954-1.052,4.068,4.068,0,0,1-1.093-2.995v-8.3A4.035,4.035,0,0,1,6.354,7.714,4.115,4.115,0,0,1,9.309,6.662h8.337A4.035,4.035,0,0,1,20.6,7.754a4.176,4.176,0,0,1,1.093,2.914V19Z" transform="translate(-3.4 -4.8)" />
                         </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </footer>
    </div>
    <div class="modal fade" id="edit_Contact" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div class="modal-dialog">

                     <div class="modal-content">
                        <div class="modal-header">
                           <h5 class="modal-title" id="exampleModalLabel">Log in to DotStow</h5>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="edit_close">
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="modal-body">
                            <label>Username or email</label>
                        <input type="text" class="my-2 w-100" id="userEmail"
                            onChange={(e) => validateEmail(e)}></input> <br />
                            {/* <span class="error" style={{
                            fontWeight: 'bold',
                            color: 'red',
                            marginTop:'10px'
                            }}>{emailError}</span> */}
                            <p class="error">{emailError}</p>
                    {/* <p className="my-3">Don't have an account? Start a <b><a href="#" class="freet">FREE TRIAL</a></b> </p> */}
                        </div>
                        <div class="modal-footer">
                           <button type="button" class="btn btn-secondary" data-dismiss="modal" id="edit_close">Close</button>
                           <button type="button" class="btn btn-primary" id="loginCall"   onClick={() => loginMethod() }>Proceed</button>
                        </div>
                     </div>

               </div>
            </div>

    {/* </div> */}
    </ReactKeycloakProvider>
}
  </>
)};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;