import React, { history, useEffect, useState, useMemo } from "react";
import Tableheader from "../../../utility/tableheader";
import Pagination from "../../../utility/pagination";
import Search from "../../../utility/search";
import services from "../../../services/services";
import LoadSpinner from "../../../utility/LoadSpinner/LoadSpinner";
import { useLoading } from 'react-hook-loading';
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import $ from 'jquery';
import getClientLists from "./client_list_api";
//import { useKeycloak } from '@react-keycloak/web';
//import Keycloak from 'keycloak-js';
import { keycloak } from "../dashboard/login";
import checkAuthentication from "../../../utility/checkAuthentication";
import { loadingComponent } from "../../../utility/Loading";
const Clients = () => {
  const inlineCss = {
    textAlign: "left"
  }
  const [roles, setRoles] = useState("");
  //const { keycloak } = useKeycloak();
  const [customerList, setCustomerList] = useState([]);
  const navigate = useNavigate();
  const [totalRecors, setTotalRecord] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalpages, settotalPages] = useState();
  const [customerName, setCustomerName] = useState("");
  const [displayPage, setdisplayPage] = useState([]);
  const [ITEMS_PER_PAGE, setitemperpage] = useState(25);
  const [loading, setLoading] = useLoading("Please wait...");
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [type, setType] = useState("");
  const [inactiveStatus, setInactiveStatus] = useState(true);
  const headers = [
    { name: "DOT Number", field: "DOT Number", sortable: true, thStyle: 'width="40%"', inlineStyle: inlineCss },
    { name: "Type", field: "type", sortable: true, inlineStyle: inlineCss },
    { name: "Business Name", field: "Business Name", sortable: true, inlineStyle: inlineCss },
    { name: "Address", field: "Address", sortable: false, inlineStyle: inlineCss },
    { name: "Email", field: "Email", sortable: false, inlineStyle: inlineCss },
    { name: "Actions" }
  ];

  var fkAccountId = 1;
  useEffect(() => {



    getClientLists("1", ITEMS_PER_PAGE, "", ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setCustomerList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "diplayItems","",inactiveStatus);
    // $('#display_pages_in_items').on('change', function() {

    //   setitemperpage(this.value);

    //   getcustomerLists("1",ITEMS_PER_PAGE,customerName,this.value*ITEMS_PER_PAGE,setTotalItems,settotalPages,setCurrentPage,setCustomerList,setTotalRecord,setdisplayPage,sortingField,sortingOrder,"");
    // });




  }, []);
  const onSortingChanges = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

    setSortingField(field);

    setSortingOrder(order);

    getClientLists("1", ITEMS_PER_PAGE, customerName, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setCustomerList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "diplayItems",type,inactiveStatus);

  };
  const redirect = (page) => {

    navigate(page);

  }

  const typefilter = (type) => 
  {
    setType(type)
    getClientLists("1", ITEMS_PER_PAGE, customerName, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setCustomerList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "diplayItems",type,inactiveStatus);

  }
  const searchList = (customerName) => {
    setCustomerName(customerName);
    getClientLists("1", ITEMS_PER_PAGE, customerName, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setCustomerList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "diplayItems",type,inactiveStatus);

  }
  const handlePageChange = (offset, triggerevent) => {

    getClientLists(offset, ITEMS_PER_PAGE, customerName, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setCustomerList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "",type,inactiveStatus);

  };
  const displayPageNumbers = (e) => {

    setitemperpage(e.target.value);

    getClientLists("1", ITEMS_PER_PAGE, customerName, e.target.value, setTotalItems, settotalPages, setCurrentPage, setCustomerList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "",type,inactiveStatus);
  }

  var activestatus="false";
    const activeInctive = (event) => 
    {
        
        if(event.target.checked)
        {

        }else{
            activestatus="true";
        }
        getClientLists("1", ITEMS_PER_PAGE, customerName, ITEMS_PER_PAGE, setTotalItems, settotalPages, setCurrentPage, setCustomerList, setTotalRecord, setdisplayPage, sortingField, sortingOrder, "diplayItems",type,activestatus);

        setInactiveStatus(activestatus);
    }

  return (
    <>
      {loadingComponent}
      <div class="main-panel">
        <div class="content-wrapper" style={{ display: "block;" }}>
          <div class="row">
            <div class="col-lg-11 col-md-12 m-auto mb-2 ">
              <h4 class="font-weight-bold my-4 text-uppercase">Clients</h4>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-11 col-md-12 m-auto grid-margin stretch-card">
              <div class="card">
                <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                  <div class="search position-relative align-self-start mb-1 mt-1 d-flex align-items-center">

                   <div class="mr-4">
                   <Search
                      placeholderName="Search by business name"
                      onSearch={value => {
                        searchList(value);
                      }}

                    />
                    <a href="#" class="search-icon">
                      <img src="images/icons/search.svg" alt="" />
                    </a>
                   </div>

                   <div className="d-flex align-items-center">
                 <label for="exampleInputEmail1" className="mx-2">Type </label>
                    <select class="form-control custome-select" id="type" onChange={e => typefilter(e.target.value)} >
                      <option value="">Select</option>
                      <option value="Garage">Garage</option>
                      <option value="Fleet">Fleet</option>
                    </select>
                    
                 </div>

                 
                   
                  </div>
                  <div class="d-flex align-items-center">
                  <input type="checkbox"
                                                                           onChange={(e) => activeInctive(e) }
                                                                            id="inactive"
                                                                            name="inactive"
                                                                           
                                                                            style={{marginLeft:"21px"}} /> <span style={{ marginLeft: "10px"}}>
                                                                           Inactive records only
                                                                          </span>
                                                                          </div>
                
                  <div class="d-flex align-self-start mb-1 mt-1 ml-auto">
                    <a onClick={() => redirect("/addClient")} class="btn btn-success">Add Client</a>
                  </div>
                </div>
                <div class="card-body pt-0 pb-0">

                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive border-top border-bottom">
                        <table class="table custome-table-mn">
                          <thead>
                            <tr>
                              {headers.map(({ name, field, sortable, thStyle, inlineStyle }) =>
                              (
                                <th
                                  key={name}
                                  onClick={() =>
                                    sortable ? onSortingChanges(field) : null
                                  }
                                  class={thStyle} style={inlineStyle}>
                                  {name}

                                  {sortingField && sortingField === field && (

                                    sortingOrder === "asc"
                                      ? "↓"
                                      : "↑"
                                  )}

                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              customerList ? customerList.map((data, index) => (
                                <tr>
                                  <td class="text-capitalize">{data.dotNumber}</td>
                                  <td class="text-capitalize">{data.type}</td>
                                  <td style={{ maxWidth: "100px", whiteSpace: "break-spaces", lineHeight: "20px", paddingRight:"10px" }}>{data.tenantDisplayName}</td>
                                  <td style={{ maxWidth: "140px", whiteSpace: "break-spaces", lineHeight: "20px", paddingRight:"10px" }}>
                                    {/* 370 Tipple Road,Philadelphia,New York,12601 */}
                                    <span>{data ? data.addressLine1 : ""}{data ? data.addressLine1 ? "," : "" : ""}<span>&nbsp;
                                    </span>{data ? data.addressLine2 : ""}{data ? data.addressLine2 ? "," : "" : ""}<span>
                                        &nbsp;</span><br />{data ? data.city : ""}{data ? data.city ? "," : "" : ""}<span> <span>
                                          {data ? data.stateName : ""}{data ? data.stateName ? "," : "" : ""}</span>
                                        &nbsp;</span>{data ? data.zip : ""}{data ? data.zip ? "." : "" : ""}</span>
                                  </td>
                                  <td style={{ maxWidth: "145px", whiteSpace: "break-spaces", lineHeight: "20px" }}>{data.email}</td>
                                  <td style={{ "width": "50px" }}><a onClick={() => redirect("/clientDetails/" + data.id + "/" + data.type)} class="btn btn-outline-primary text-uppercase">View</a></td>
                                </tr>

                              )) : "no data"

                            }

                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex align-items-center justify-content-between p-3">
                  <div class="d-flex">
                    <div class="dropdown show mr-3 ">
                      <select class="form-control custome-select" class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="display_pages_in_items" onChange={e => displayPageNumbers(e)} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {

                          displayPage.map((data, index) => (
                            <option value={data}>{data}</option>
                          ))
                        }
                      </select>
                      {/* <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                         {ITEMS_PER_PAGE}
                        </a>

                        <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                          {
                            displayPage.map((data, index) => (

                            <a class="dropdown-item" href="#">{data}</a>

                            ))
                            }
                        </div> */}
                    </div>
                    <span class="d-flex align-items-center pagenation-mob">{currentPage * ITEMS_PER_PAGE <= totalRecors ? currentPage * ITEMS_PER_PAGE : totalRecors} out of {totalRecors} Records Displayed</span>
                  </div>

                  <Pagination
                    total={totalRecors}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    //onPageChange={page => setCurrentPage(page)}
                    onPageChange={handlePageChange}
                  />


                </div>

              </div>

            </div>

          </div>
        </div>

      </div>

    </>
  )
};

Clients.propTypes = {};

Clients.defaultProps = {};

export default Clients;
