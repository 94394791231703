import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import { itemperPagetoDisplay } from "../../constant";
import $ from "jquery";
const customer_doc_api = (
  customerId,
  pageNumber,
  ITEMS_PER_PAGE_DOCS,
  vehicleId,
  pageSize,
  setTotalDocsItems,
  settotalDocsPages,
  setCurrentDocsPage,
  setDocumentList,
  setTotalDocsRecord,
  setdisplayDocsPage,
  sortBy,
  sortDir,
  displayItems
) => {

  var documentReq = "";
  if (sortBy === "Doc category") {
    sortBy = "docSubCategory";
  } else if (sortBy === "Title") {
    sortBy = "title";
  } else if (sortBy === "Vehicle number") {
    sortBy = "lisencePlateNumber";
  } else if (sortBy === "Unit Number") {
    sortBy = "unitNumber";
  } else if (sortBy === "Expiry Date") {
    sortBy = "expiryDate";
  } else {
    sortBy = "";
  }

  if (customerId == "") {
    documentReq = {
      lisencePlateNumber: vehicleId,
      pageNumber: pageNumber,
      vehicleTypeId: "",
      pageSize: pageSize,
      sortBy: sortBy,
      sortDir: sortDir,
    };
    // const requestOptions =
    // {
    //       method: 'POST',
    //       headers:{'Content-Type': 'application/json'},
    //       body: JSON.stringify(documentReq)
    // };
    var requestOptions = requestObject.RequestHeader(documentReq);
    services.getListOfDocuments(requestOptions).then((res) => {
      if (res.success) {
        setTotalDocsItems(res.customValues.totalNumberOfRecords);
        settotalDocsPages(res.customValues.totalNumberOfPages);
        setCurrentDocsPage(res.customValues.currentPageNumber);
        if (res.rows.length > 0) {
          setDocumentList(res.rows);
        } else {
          setDocumentList("");
          $("#DocumentID").hide();
        }

        setTotalDocsRecord(res.customValues.totalNumberOfRecords);
        var displaypages = [];

        if (displayItems === "displayItems") {
          for (
            var i = 1;
            i <=
            parseInt(
              Math.ceil(
                res.customValues.totalNumberOfRecords / itemperPagetoDisplay
              )
            );
            i++
          ) {
            displaypages.push(i * itemperPagetoDisplay);
          }

          setdisplayDocsPage(displaypages);
        }
      } else {
      }
    });
  } else {
    documentReq = {
      fkCustomerId: customerId,
      lisencePlateNumber: vehicleId,
      pageNumber: pageNumber,
      vehicleTypeId: "",
      pageSize: pageSize,
      sortBy: sortBy,
      sortDir: sortDir,
    };

    var requestOptions = requestObject.RequestHeader(documentReq);
    services.getListOfDocuments(requestOptions).then((res) =>
      // services.getCustomerDocuments(requestOptions).then((res) =>
      {
        if (res.success) {
          setTotalDocsItems(res.customValues.totalNumberOfRecords);
          settotalDocsPages(res.customValues.totalNumberOfPages);
          setCurrentDocsPage(res.customValues.currentPageNumber);
          if (res.rows.length > 0) {
            setDocumentList(res.rows);
          } else {
            setDocumentList("");
            $("#DocumentID").hide();
          }

          setTotalDocsRecord(res.customValues.totalNumberOfRecords);
          var displaypages = [];

          if (displayItems === "displayItems") {
            for (
              var i = 1;
              i <=
              parseInt(
                Math.ceil(
                  res.customValues.totalNumberOfRecords / ITEMS_PER_PAGE_DOCS
                )
              );
              i++
            ) {
              displaypages.push(i * ITEMS_PER_PAGE_DOCS);
            }

            setdisplayDocsPage(displaypages);
          }
        } else {
          setDocumentList("");
        }
      }
    );
  }
};

export default customer_doc_api;
