import services from "../../../services/services";
import { accessTokenTo } from "../../../http-common";
import { requestObject } from "../../../utility/requestObject";
import $ from "jquery";
const getClientLists=(pageNumber,ITEMS_PER_PAGE,customerName,pageSize,setTotalItems,settotalPages,setCurrentPage,setCustomerList,setTotalRecord,setdisplayPage,sortBy,sortDir,displayItems,type,activestatus)=>
{
  
    if(sortBy==="DOT Number")
    {
      sortBy="dotNumber";
    }
    else if(sortBy==="Type")
    {
      sortBy="type";
    }
    else if(sortBy==="Business Name")
    {
      sortBy="tenantDisplayName";
    }
    
    var customerListReq=
    {
  
    //   "customerName":customerName,
      "pageNumber":pageNumber,
    //   "vehicleTypeId":"",
      "pageSize":pageSize,
      "sortBy": sortBy,
      "sortDir":sortDir,
      "id":'',
      "type":type,
      "tenantDisplayName":customerName,
      "isActive":activestatus

    };


  var requestOptions=requestObject.RequestHeader(customerListReq);


  services.listofclients(requestOptions).then((res) => 
  {
    console.log(res.rows);
    $("#loading").hide();
   // setLoading(false);
   // setIsLoaded(currentIsLoaded => !currentIsLoaded);
    if(res.success)
    {
      setTotalItems(res.customValues.totalNumberOfRecords);
    
      settotalPages(res.customValues.totalNumberOfRecords);
     
      setCurrentPage(res.customValues.currentPageNumber);
      setCustomerList(res.rows);
      setTotalRecord(res.customValues.totalNumberOfRecords);
      var displaypages=[];
      if(displayItems==="diplayItems")
      {
        for(var i=1;i<=parseInt(Math.ceil(res.customValues.totalNumberOfRecords/ITEMS_PER_PAGE));i++)
        {
          displaypages.push(i*ITEMS_PER_PAGE);
        }
        
        setdisplayPage(displaypages);

      }


    }else{
    
      //alert(res.message);
      setCustomerList("");
      //setdisplayPage([0]);
      setTotalItems(0);
    
      settotalPages("1");
     
      setCurrentPage("1");
      setCustomerList("");
      setTotalRecord(0);
    }
   
  })

}

export default getClientLists;