import React, { history, useEffect, useState, useMemo } from "react";

const Privacy = () => {

    return (
        <>

            <div class="pp w-100" style={{ marginTop: "-60px", background:"white" }}>
                <header >
                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <div class="container" style={{ maxWidth: "1200px", margin: "auto" }}>
                            <a class="navbar-brand" href="#"><img src="/img/logo-dotstow.svg" style={{ width: "90px" }} /></a>
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarSupportedContent">

                                <a class="btn btn-outline-primary text-uppercase" href="/login" >Home</a>
                                {/* <a href="#" data-toggle="modal" data-target="#edit_Contact" >Edit</a> */}
                            </div>
                        </div>
                    </nav>
                </header>

                <section>
                    <section class="cont-head">
                        <div class="container" >
                            <div class="row">
                                <div class="col-12 m-auto"  >
                                    <div>
                                        <h2>Privacy Policy</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="container">
                        <p><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:2,&quot;335551620&quot;:2,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">Protecting your private information is our priority. This Statement of Privacy applies to 11708 Ashville Pike, and DOTStow, LLC and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to DOTStow, LLC include 11708 Ashville Pike and www.dotstow.com. The DOTStow, LLC website is a ecommerce site. By using the DOTStow, LLC website, you consent to the data practices described in this statement.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><strong><span data-contrast="auto">Collection of your Personal Information</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">In order to better provide you with products and services offered, DOTStow, LLC may collect personally identifiable information, such as your:&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> -</span> <span data-contrast="auto">First and Last Name&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> -</span> <span data-contrast="auto">Mailing Address&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> -</span> <span data-contrast="auto">E-mail Address&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> -</span> <span data-contrast="auto">Phone Number&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> -</span> <span data-contrast="auto">Employer&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> -</span> <span data-contrast="auto">Job Title&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">If you purchase DOTStow, LLC's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><strong><span data-contrast="auto">Use of your Personal Information&nbsp;</span></strong><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">DOTStow, LLC collects and uses your personal information to operate and deliver the services you have requested.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">DOTStow, LLC may also use your personally identifiable information to inform you of other products or services available from DOTStow, LLC and its affiliates.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><strong><span data-contrast="auto">Sharing Information with Third Parties</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">DOTStow, LLC does not sell, rent or lease its customer lists to third parties.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">DOTStow, LLC may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to DOTStow, LLC, and they are required to maintain the confidentiality of your information.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">DOTStow, LLC may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on DOTStow, LLC or the site; (b) protect and defend the rights or property of DOTStow, LLC; and/or (c) act under exigent circumstances to protect the personal safety of users of DOTStow, LLC, or the public.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><strong><span data-contrast="auto">Automatically Collected Information</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">Information about your computer hardware and software may be automatically collected by DOTStow, LLC. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the DOTStow, LLC website.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><strong><span data-contrast="auto">Security of your Personal Information</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">DOTStow, LLC secures your personal information from unauthorized access, use, or disclosure. DOTStow, LLC uses the following methods for this purpose:&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> -</span> <span data-contrast="auto">SSL Protocol&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><strong><span data-contrast="auto">Right to Deletion</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <ul>
                            <li><span data-contrast="auto">Delete your personal information from our records; and&nbsp;</span></li>
                            <li><span data-contrast="auto">Direct any service providers to delete your personal information from their records.&nbsp;</span></li>
                        </ul>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <ul>
                            <li><span data-contrast="auto">Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;&nbsp;</span></li>
                            <li><span data-contrast="auto">Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;&nbsp;</span></li>
                            <li><span data-contrast="auto">Debug to identify and repair errors that impair existing intended functionality;&nbsp;</span></li>
                            <li><span data-contrast="auto">Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;&nbsp;</span></li>
                            <li><span data-contrast="auto">Comply with the California Electronic Communications Privacy Act;&nbsp;</span></li>
                            <li><span data-contrast="auto">Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;&nbsp;</span></li>
                            <li><span data-contrast="auto">Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;&nbsp;</span></li>
                            <li><span data-contrast="auto">Comply with an existing legal obligation; or&nbsp;</span></li>
                            <li><span data-contrast="auto">Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.&nbsp;</span></li>
                        </ul>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><strong><span data-contrast="auto">Children Under Thirteen</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">DOTStow, LLC does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><strong><span data-contrast="auto">E-mail Communications</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">From time to time, DOTStow, LLC may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><strong><span data-contrast="auto">External Data Storage Sites</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">We may store your data on servers provided by third party hosting vendors with whom we have contracted.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><strong><span data-contrast="auto">Changes to this Statement</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">DOTStow, LLC reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><strong><span data-contrast="auto">Contact Information</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">DOTStow, LLC welcomes your questions or comments regarding this Statement of Privacy. If you believe that DOTStow, LLC has not adhered to this Statement, please contact DOTStow, LLC at:&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">DOTStow, LLC&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">_________________&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">Ashville, _________________ 43103&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">Email Address:&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">bbaker@dotstow.com&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">Telephone number:&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">16145588512&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto">Effective as of October 24, 2022&nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                        <p><span data-contrast="auto"> &nbsp;</span><span data-ccp-props="{&quot;134233117&quot;:false,&quot;134233118&quot;:false,&quot;335551550&quot;:1,&quot;335551620&quot;:1,&quot;335559739&quot;:0}">&nbsp;</span></p>
                    </div>
                </section>

                <footer class="footer_wrap">
                    <div class="container">
                        <div class="row d-flex justify-content-between">
                            <div class="col-sm-12 col-md-6">
                                <div class="copyrights">
                                    <p>© 2022 DotStow. All rights reserved. <a href="/privacy">Privacy Policy</a>  <a href="/terms">Terms & Conditions</a></p>
                                </div>
                            </div>
                            <div class="col-sm-auto col-md-auto ">
                                <div class="social_icons">
                                    <a href="#" class="svg_icon">
                                        <svg height="20" viewBox="0 0 9.327 20.073">
                                            <path d="M27.309,12.6H24.133V10.512a.848.848,0,0,1,.884-.965h2.242V6.108L24.171,6.1A3.909,3.909,0,0,0,19.964,10.3V12.6H17.982V16.14h1.982V26.169h4.169V16.14h2.813Z" transform="translate(-17.982 -6.096)" />
                                        </svg>
                                    </a>
                                    <a href="#" class="svg_icon">
                                        <svg width="20" viewBox="0 0 24.7 20.073">
                                            <path d="M28.274,12.7a10.083,10.083,0,0,1-2.911.8,5.077,5.077,0,0,0,2.228-2.8,10.144,10.144,0,0,1-3.218,1.23,5.072,5.072,0,0,0-8.635,4.622A14.388,14.388,0,0,1,5.294,11.254a5.074,5.074,0,0,0,1.568,6.766,5.053,5.053,0,0,1-2.3-.634c0,.021,0,.043,0,.064a5.072,5.072,0,0,0,4.065,4.969A5.1,5.1,0,0,1,7.3,22.6a5.021,5.021,0,0,1-.954-.091,5.073,5.073,0,0,0,4.734,3.519,10.226,10.226,0,0,1-7.5,2.1A14.412,14.412,0,0,0,25.76,15.981q0-.329-.014-.656A10.264,10.264,0,0,0,28.274,12.7Z" transform="translate(-3.574 -10.326)" />
                                        </svg>
                                    </a>
                                    <a href="#" class="svg_icon">
                                        <svg width="20" viewBox="0 0 20.073 20.073">
                                            <path data-name="Path 72" d="M20.58,16.7a5.18,5.18,0,1,0,5.18,5.18A5.19,5.19,0,0,0,20.58,16.7Zm0,8.5A3.319,3.319,0,1,1,23.9,21.88,3.334,3.334,0,0,1,20.58,25.2Z" transform="translate(-10.544 -11.884)" />
                                            <circle data-name="Ellipse 18" cx="1.174" cy="1.174" r="1.174" transform="translate(14.245 3.521)" />
                                            <path data-name="Path 73" d="M21.854,6.459A5.764,5.764,0,0,0,17.6,4.8H9.268A5.544,5.544,0,0,0,3.4,10.668v8.3a5.823,5.823,0,0,0,1.7,4.33,5.906,5.906,0,0,0,4.209,1.578h8.256a5.976,5.976,0,0,0,4.249-1.578A5.794,5.794,0,0,0,23.473,19V10.668A5.8,5.8,0,0,0,21.854,6.459ZM21.692,19a3.954,3.954,0,0,1-1.174,2.954,4.183,4.183,0,0,1-2.954,1.052H9.309a4.183,4.183,0,0,1-2.954-1.052,4.068,4.068,0,0,1-1.093-2.995v-8.3A4.035,4.035,0,0,1,6.354,7.714,4.115,4.115,0,0,1,9.309,6.662h8.337A4.035,4.035,0,0,1,20.6,7.754a4.176,4.176,0,0,1,1.093,2.914V19Z" transform="translate(-3.4 -4.8)" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </footer>
            </div>
        </>)
}

Privacy.propTypes = {};

Privacy.defaultProps = {};

export default Privacy;