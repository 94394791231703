import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import { itemperPagetoDisplay } from "../../constant";
import $ from "jquery";
const userList = (securityRoleName, pageNumber, ITEMS_PER_PAGE, sortBy, sortDir, setdispathcerlist, setdisplayPage, displayItems, setCurrentPage, setTotalRecord, setTotalItems, settotalPages, searchTxt, clientTenantName,inactiveStatus) => {
      // alert(clientTenantName)
      var request =
      {

            "firstName": searchTxt,
            "pageNumber": pageNumber,

            "isActive": inactiveStatus,

            "pageSize": ITEMS_PER_PAGE,

            "sortBy": sortBy,

            "sortDir": sortDir,
            "tenantName": clientTenantName,

      }
      var requestOptions = requestObject.RequestHeader(request);
      services.listUsers(requestOptions).then((res) =>
      // services.listUsersByRole(requestOptions).then((res)=>
      {
            $("#loading").hide();
            setdispathcerlist(res.rows);
            var displaypages = [];
            if (res.customValues == null) {

            } else {
                  setTotalItems(res.customValues.totalNumberOfRecords);

                  settotalPages(res.customValues.totalNumberOfRecords);

                  setCurrentPage(res.customValues.currentPageNumber);
                  setTotalRecord(res.customValues.totalNumberOfRecords);
                  if (displayItems == "displayItems") {

                        for (var i = 1; i <= parseInt(Math.ceil(res.customValues.totalNumberOfRecords / itemperPagetoDisplay)); i++) {
      
                              displaypages.push(i * itemperPagetoDisplay);
                        }
      
                        setdisplayPage(displaypages);
      
                  }
            }
            if (res.rows) {

                  if (res.rows.length > 0) {

                        setdispathcerlist(res.rows);
                  } else {


                        setdispathcerlist("");
                        setTotalItems("0");
                        setCurrentPage("1");
                  }

            }

    
            

      });

}

export default userList;