import React,{history,useEffect, useState, useMemo } from "react";
import { useNavigate ,useParams} from "react-router-dom";
import CheckAuthentication from "./checkAuthentication";
import $ from "jquery";
import Swal from "sweetalert2";
const AdddocsDispatcher = ({inputList, setInputList,setBase64file,setfileName}) => 
{
      var fileNamesArray=[];
     
      const [ fileName, setfileNames ] = useState([]);
      const navigate = useNavigate();
      useEffect(() => 
      {
        if(CheckAuthentication)
        {
        }
        else
        {
          sessionStorage.clear();
          localStorage.clear();
          //navigate("/login");
              window.location.href="/login";
        }

      },[]);  
      // handle input change
   const handleInputChange = (e, index) => 
    {
     
      // const MIN_FILE_SIZE = 2048 // 2MB
      // const fileSizeKiloBytes=Math.floor(e.target.files[0].size/1024); //
      
      const { name, value,filename } = e.target;
      const list = [...inputList];
      setfileNames(value);
      list[index][name] = value;
      list[index][filename] = value;
      setInputList(list);
      // alert(list);
    };
     
    // handle click event of the Remove button
    const handleRemoveClick = index => {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    };
     
    // handle click event of the Add button
    const handleAddClick = (e,index) => {
      const { name, value,filename } = e.target;
      const list = [...inputList];
     
  
      if(list[index][filename]==""||list[index][filename]=="undefined"||list[index][filename]==undefined)
      { 
        
        Swal.fire({  
          position: 'center',  
          icon: 'error',  
          title: "",  
          text:"Please Enter Doc Name",
          showConfirmButton: true 
          
        }).then((result) => 
        {
              
              if(result.isConfirmed) 
              {
                
              
              }
        });
        return false;

      }else{
        setInputList([...inputList, {}]);
      }
      
    };
    const fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
          cb(null, reader.result)
        }
        reader.onerror = function (error) {
          cb(error, null)
        }
      }
    const onUploadFileChange = ( event,index) => 
    {
      const MIN_FILE_SIZE = 51200 // 50MB
      const fileSizeKiloBytes=Math.floor(event.target.files[0].size/1024); //
      if(fileSizeKiloBytes <= MIN_FILE_SIZE){
    
        var target=event.target;
        if (target.files < 1 || !target.validity.valid) {
          return
        }
        fileToBase64(target.files[0], (err, result) => {
          if (result) {
            const { name, value,fileName } = event.target;
            const list = [...inputList];
            list[index][name] = result;
            list[index]["fileName"] = target.files[0].name;
            setBase64file(result);
           // setFile(result)
       
        
            setInputList(inputList);
     
           // setFileName(target.files[0])
          }
        })
      }else{
        Swal.fire({
           position: 'center',
           icon: 'error',
           title: "",
           text: "Max file size is 50MB",
           showConfirmButton: true

        }).then((result) => {
        });   
     }
      }

    //   const checkLength=()=>{

    //     var notesValue = $("#notes").val();

    //     if(notesValue!=''){
    //       alert(notesValue.length);
    //       if(notesValue.length <= 200 && notesValue.length >= 0){
    //           return true;
    //       }
    //       else{
    //           $("#error_notes").html("maximum length of 200 characters");
    //           return false;
    //       }

    //     }else{

    //       $("#error_notes").html("Notes field is required");
    //     }
  
    // }

      return(<>
                             <div class="row p-3">
                                <div class="col-lg-12">
                                    {/* <h4 class="mb-4">Additional Documents</h4> */}
                                    {/* <div class="note"> <img src="images/icons/note-icon.png" alt="" class="mr-2"/> <small>Support Files : JPG / PDF / Word / BMP / PNG</small></div> */}
                                    <div class="table-1">
                                  {inputList?inputList.map((x, i) => 
                                                {
                                                return (
                                                   <div class="d-flex justify-content-between">
                                                        <div className="box">
                                                        <input
                                                            id={"docName"+i}
                                                            name="title"
                                                            placeholder="Enter Doc Name"
                                                            value={x.docName}
                                                            onChange={e => handleInputChange(e, i)}
                                                        />
                                                   {/* <div style={{"color":"red"}} class="fileNameDt"> {inputList[i].fileName}</div> */}
                                                     {/* <img src="/images/icons/pdf-icon.svg" alt=""/> <input type="file" size="60" />  */}
                                                     <label class="mb-0 tt" data-bs-toggle="tooltip" data-bs-placement="bottom" title={inputList[i].fileName==null?"upload Document":inputList[i].fileName}>
                                                       {/* {inputList[i].fileName==null?<img src="/images/icons/upload.svg" alt="" />:inputList[i].fileName.split('.').pop()=='pdf'?<img src="/images/icons/pdf-icon.svg" class="mr-2" />:inputList[i].fileName.split('.').pop()=='doc'?<img src="/images/icons/word-icon.svg" class="mr-2" style={{ width: "42px !important"  }}/>:<img src="/images/icons/upload.svg" alt="" />} */}
                                                       {(() => {
                                                                            if (inputList[i].fileName==null) {
                                                                                return (
                                                                                  <img src="/images/icons/upload.svg" alt="" />
                                                                                )
                                                                            }else if(inputList[i].fileName.split('.').pop() == 'pdf') {
                                                                              return (
                                                                                <img style={{ width:"42px !important"}} src="/images/icons/pdf-icon.svg" class="mr-2 w-42" />
                                                                              )
                                                                          } else if(inputList[i].fileName.split('.').pop() == 'jpg' || inputList[i].fileName.split('.').pop() == 'png' || inputList[i].fileName.split('.').pop() == 'jpeg' || inputList[i].fileName.split('.').pop() == 'bmp') {
                                                                              
                                                                            return (
                                                                                    <img style={{ width:"42px !important"}} src="/images/icons/image-icon.svg" class="mr-2 w-42" />
                                                                                )
                                                                            } 
                                                                            // else if(inputList[i].fileName.split('.').pop() == 'doc' || inputList[i].fileName.split('.').pop() == 'word' || inputList[i].fileName.split('.').pop() == 'docx' || inputList[i].fileName.split('.').pop() == 'dot' || inputList[i].fileName.split('.').pop() == 'dotm' || inputList[i].fileName.split('.').pop() == 'dotx') {

                                                                            //     return (
                                                                            //         <img style={{ width:"42px !important"}} src="/images/icons/word-icon.svg" class="mr-2 w-42" />
                                                                            //     )
                                                                            // } 
                                                                            else {
                                                                                return (
                                                                                    // <img src="/images/icons/pdf-icon.svg" class="mr-2" ></img>
                                                                                    <img src="/images/icons/upload.svg" alt="" ></img>
                                                                                )
                                                                            }
                                                                        })()}
                                                      <input type="file" name="filetobase64" id="file-input"   onChange={e => onUploadFileChange(e, i)}  accept="image/jpeg,image/png,application/pdf" /></label>
                                                      {/* <input type="file" name="filetobase64" id="file-input"   onChange={e => onUploadFileChange(e, i)}  accept="image/jpeg,image/png,image/bmp,application/pdf,application/msword,application/word,application/msword" /></label> */}
                                                        {/* <input type="file" name="filetobase64"  onChange={onUploadFileChange} accept="application/pdf" /> */}
                                                        
 
                                                                    <div className="btn-box ml-2">
                                                                        {inputList.length !== 1 && 
                                                                        <img src="/images/icons/remove-minus.svg"  onClick={() => handleRemoveClick(i)} alt="upload"/>
                                                                        // <button
                                                                        // className="mr10"
                                                                        // onClick={() => handleRemoveClick(i)}>Remove</button>}
                                                                        }
                                                                        
                                                                        {inputList.length - 1 === i &&  <img src="/images/icons/add-plus.svg"  onClick={(e) =>handleAddClick(e, i)} />}
                                                                    </div>
                                                                    </div>
                                                   </div>
                                                                );
                                                                }):""}
                                                 
                                    </div>
                                </div>
                                <div class="col-lg-12">
        
         
      </div>
                            </div>
      </>)
}

AdddocsDispatcher.propTypes = {};

AdddocsDispatcher.defaultProps = {};

export default AdddocsDispatcher;