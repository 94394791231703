import React, { history, useEffect, useState, useMemo } from "react";

import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import { useLoading } from "react-hook-loading";
import Swal from "sweetalert2";
import $ from "jquery";
import Search from "../../../utility/search";
import Pagination from "../../../utility/pagination";
import userList from "../settings/userList";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import UsNumberValidate from "../../../utility/usNumberValidate";
import { useNavigate, useParams } from "react-router-dom";
import Cropper from "react-cropper";
import { loadingComponent } from "../../../utility/Loading";
import Getstates from "../../../utility/getstates";
import { LoadingComponentForSubmit } from "../../../utility/loadingforsubmit";
import ToggleSwitch from "../../ToggleSwitch";
const ClientDetails = () => 
{
   const { clientId,type } = useParams();

   sessionStorage.setItem("clientId", clientId);
   sessionStorage.setItem("type", type);
   const [roletype, setType] = useState('');
   const [states, setState] = useState([]);
   const [profileDetails, setProfileDetails] = useState("");
   const [profilePic, setProfilePic] = useState("");
   const [loading, setLoading] = useLoading("Please wait...");
   const [cropper, setCropper] = useState();
   const [image, setImage] = useState();
   const [itemName, setFieldName] = useState('');

   const [dispatcherList, setdispathcerlist] = useState();
   const [displayPage, setdisplayPage] = useState([]);
   const [ITEMS_PER_PAGE, setitemperpage] = useState(25);
   const [currentPage, setCurrentPage] = useState(1);
   const [totalItems, setTotalItems] = useState(0);
   const [totalRecors, setTotalRecord] = useState([]);
   const [totalpages, settotalPages] = useState();
   const [searchtxt, setsearchText] = useState("");

   const navigate = useNavigate();
   const [stateIdC, setStateIdC] = useState([]);

   const [roles, setRoles] = useState("");
   const [clientTenantName, setTenantName] = useState("");
   const [clientTenantDisplayName, setTenantDisplayName] = useState("");
   const [inactiveStatus, setInactiveStatus] = useState(true);

   $(document).ready(function () {
      $("#zipcode_e").keypress(function (e) {
         if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
            return false;
         }

      });
   });

   useEffect(() => {
      $('#phoneMobile').usPhoneFormat();
      $('#faxnumber').usPhoneFormat();
      Getstates(setState);

      IfitIsCustomer();

      // setRoles(localStorage.getItem("Userroles"));

      var navigationfrom = sessionStorage.getItem("naviationfrom");

      if (navigationfrom == "users") {

         document.getElementById("v-pills-messages-tab").click();
         sessionStorage.removeItem("naviationfrom");
      } else {

         document.getElementById("v-pills-home-tab").click();
         sessionStorage.removeItem("naviationfrom");
      }

   }, []);
   const redirect = (page) => {
      navigate(page);

   }
   // const onChange = (e) => {
   //    e.preventDefault();
   //    let files;
   //    if (e.dataTransfer) {
   //       files = e.dataTransfer.files;
   //    } else if (e.target) {
   //       files = e.target.files;
   //    }
   //    const reader = new FileReader();
   //    reader.onload = () => {
   //       setImage(reader.result);
   //    };
   //    reader.readAsDataURL(files[0]);
   // };
   const onChange = (e) => {

      const MIN_FILE_SIZE = 5120 // 5MB
      const fileSizeKiloBytes=Math.floor(e.target.files[0].size/1024); //

      if(fileSizeKiloBytes <= MIN_FILE_SIZE){

      e.preventDefault();
      let files;
      var ext = e.target.value.split('.').pop();
      var allowedExtensions = /(\jpg|\png|\jpeg)$/i;

      if (!allowedExtensions.exec(ext)) {

         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Invalid file type",
            showConfirmButton: true

         }).then((result) => {

            if (result.isConfirmed) {

               e.target.value = '';
               return false;
            }
         });

      } else {
         $('#selectedImage').show();
         $('#DB_Image').hide();
         $('#uploadImageDisable').prop('disabled', false);
         if (e.dataTransfer) {
            files = e.dataTransfer.files;
         } else if (e.target) {
            files = e.target.files;
         }
         const reader = new FileReader();
         reader.onload = () => {
            setImage(reader.result);
         };
         reader.readAsDataURL(files[0]);

      }
      }else{
         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: "Max file size is 5MB",
            showConfirmButton: true

         }).then((result) => {
         });   
      }

   };
   const {
      register,
      formState: { errors },
      handleSubmit
   } = useForm({
      criteriaMode: "all", mode: "onKeyup", defaultValues: { dotNumber: "123", name: "name", ctNumber: "1234", addressLine1: "addressLine1", phoneMobile: "123-456-7890", email: "text@mailnator.com" },
   });

   const updateProfileImage = () => {

      $("#loadingforsubmit").show();
      
      document.getElementById("btncloseImage").click();
      var requestObjectCustomer =
      {


         "id": $("#accountId").val(),
         "dotNumber": $("#dotNumber").val().trim(),
         "ctNumber": $("#ctNumber").val().trim(),
         "name": $("#name").val().trim(),
         "email": $("#email").val().trim(),
         "phoneBusiness": $("#phoneMobile").val().trim(),
         "addressLine1": $("#addressLine1").val().trim(),
         "addressLine2": $("#addressLine2").val().trim(),
         "city": $("#city_e").val().trim(),
         "stateId": $("#state_e").val(),
         "zip": $("#zipcode_e").val().trim(),
         "isEmailNotificationsEnabled": "true",
         "logByteString": cropper.getCroppedCanvas() ? cropper.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 350,  maxHeight: 350}).toDataURL('image/jpeg').replace("data:image/jpeg;base64,", "") : "",
         "fax": $("#faxNumber").val(),
         "logoName": cropper.getCroppedCanvas() ? parseInt(Math.random() * 1000000000) : "",

         "logoExtension": cropper.getCroppedCanvas() ? "jpeg" : ""

      }

      var requestOptions = requestObject.RequestHeader(requestObjectCustomer);
      services.updateGarageDetails(requestOptions).then((res) => {
         // setLoading(false);
         $("#loadingforsubmit").hide();
         if (res.success) {
            Swal.fire({
               position: 'center',
               icon: 'success',
               title: "",
               text: res.message,
               showConfirmButton: true

            }).then((result) => {
               //window.location.reload();
               if(res.message==="Unable to update garage details because of duplicate dot number")
                {
                  
                }
                else
                {
                  
                  if(result.isConfirmed)
                  {
                    
                     getProfileData();
  
                  }
                }
               
            });

         }
         else {

            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: res.message,
               showConfirmButton: true

            }).then((result) => {
               IfitIsCustomer();

            });

         }



      });


   }


   const updateProfile = () => {


      // document.getElementById("profileModelClose").click();
      // setLoading(true);
      $("#loadingforsubmit").show();

      var customerListReq =
      {

         // "id":sessionStorage.getItem("userId"),
         "id": clientId,
         "type": $('#type').val(),
         "dotNumber": $("#dotNumber").val().trim(),
         "ctNumber": $("#ctNumber").val().trim(),
         "name": $("#name").val().trim(),
         "email": $("#email").val().trim(),
         "fax": $("#faxNumber").val().trim(),

         "phoneBusiness": $("#phoneMobile").val().trim(),
         "addressLine1": $("#addressLine1").val().trim(),
         "addressLine2": $("#addressLine2").val().trim(),
         "city": $("#city_e").val().trim(),
         "stateId": $("#state_e").val(),
         "zip": $("#zipcode_e").val().trim(),
         "isEmailNotificationsEnabled": "true",
         "logByteString": image.replace("data:image/jpeg;base64,", "") ? image.replace("data:image/jpeg;base64,", "") : "",

         "logoName": image.replace("data:image/jpeg;base64,", "") ? parseInt(Math.random() * 1000000000) : "",

         "logoExtension": image.replace("data:image/jpeg;base64,", "") ? "jpeg" : ""

         // "logoName": cropper.getCroppedCanvas() ? parseInt(Math.random() * 1000000000) : "",
         // "logoExtension": cropper.getCroppedCanvas() ? "jpeg" : "",
         // "logByteString": cropper.getCroppedCanvas() ? cropper.getCroppedCanvas({  minWidth: 240,  minHeight: 240,  maxWidth: 350,  maxHeight: 350}).toDataURL('image/jpeg').replace("data:image/jpeg;base64,", "") : ""

      };



      var requestOptions = requestObject.RequestHeader(customerListReq);
      services.updateTenant(requestOptions).then((res) => {
         $("#loadingforsubmit").hide();
         // setLoading(false);
         if (res.success) {

            switch(res.message)
            {
               case "Unable to update garage details because of duplicate dot number":

                  Swal.fire({
                     position: 'center',
                     icon: 'error',
                     title: "",
                     text: res.message,
      
                     showConfirmButton: true
      
                  }).then((result) => 
                  {
                    
                    
                  });
                  break;
                default:
                  document.getElementById("profileModelClose").click();
                  Swal.fire({
                     position: 'center',
                     icon: 'success',
                     title: "",
                     text: res.message,
      
                     showConfirmButton: true
      
                  }).then((result) => 
                  {
                    
                        
                        if (result.isConfirmed) 
                        {
                          
                           getProfileData();
                           
         
                        }
                      
                    
                  });
                
                  break;

            }
          
         }
         else {
            Swal.fire({
               position: 'center',
               icon: 'error',
               title: "",
               text: res.message,

               showConfirmButton: true

            }).then((result) => {
               if (result.isConfirmed) {

               }
            });
         }

      });

   }

   // function activeClient(){
   const activeClient = (status) => 
   {
      // var active = $("#activeClient").val();
      // if ($('#activeClient').prop('checked',true)) {
 
         var resetPwd =
         {
   
            "isActive": status,
            "id": clientId,
            "type": type
   
         };
       
      //let checkState = $("#activeClient").is(":checked") ? "true" : "false";
      Swal.fire({
         title: 'Do you really want to change the status of this client?',
         // text: unitNumber+" : "+title,
      
         icon: 'error',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes',
         cancelButtonText: 'No'
     }).then(function (results) {
         // Called if you click Yes.
         if(results.isConfirmed) {
   
           
    

      
            // setLoading(true);
            $("#loadingforsubmit").show();
            var requestOptions = requestObject.RequestHeader(resetPwd);
            services.inActivateTenant(requestOptions).then((res) => 
            {
               // setLoading(false);
              
               if (res.success) {
                  Swal.fire({
                     position: 'center',
                     icon: 'success',
                     title: "",
                     text: res.message,
      
                     showConfirmButton: true
      
                  }).then((result) => 
                  {
                     if (result.isConfirmed) 
                     {
                        IfitIsCustomer();
                     }
                  });
      
               } else {
      
                  Swal.fire({
                     position: 'center',
                     icon: 'error',
                     title: "",
                     text: res.message,
      
                     showConfirmButton: true
      
                  }).then((result) => 
                  {
                     if (result.isConfirmed) 
                     {
      
                     }
                  });
      
               }
      
            });
           
         }
     },
     function (no) {
         // Called if you click No.
         if (no == 'cancel') {
             
         }
     });


   }




   const getProfileData = () => {
      var customerListReq =
      {

         "pageNumber": '1',
         "pageSize": '25',
         "sortBy": '',
         "sortDir": '',
         "id": clientId,
         "type": type,

      };



      var requestOptions = requestObject.RequestHeader(customerListReq);
      services.listofclients(requestOptions).then((res) => {
         // alert(res.rows[0].phoneMobile);
         $("#loading").hide();
         // sessionStorage.setItem("comapnyName", res.rows[0] ? res.rows[0].tenantName : "");
         $("#comapnyName").html(res.rows[0].tenantName)
         $("#dotNumber").val(res.rows[0] ? res.rows[0].dotNumber : "");
         $("#accountId").val(res.rows[0] ? res.rows[0].id : "");
         $("#ctNumber").val(res.rows[0] ? res.rows[0].ctNumber : "");
         $("#name").val(res.rows[0] ? res.rows[0].tenantDisplayName : "");
         $("#email").val(res.rows[0] ? res.rows[0].email : "");
         $("#phoneMobile").val(res.rows[0] ? res.rows[0].phoneBusiness : "");
         $("#city_e").val(res.rows[0] ? res.rows[0].city : "");
         $("#zipcode_e").val(res.rows[0] ? res.rows[0].zip : "");
         $("#addressLine1").val(res.rows[0] ? res.rows[0].addressLine1 : "");
         $("#addressLine2").val(res.rows[0] ? res.rows[0].addressLine2 : "");
         $("#faxNumber").val(res.rows[0] ? res.rows[0].fax : "");
         setStateIdC(res.rows[0] ? res.rows[0].stateId : "");
   
         // alert(res.rows[0].tenantName)
         //  clientTenantName=res.rows[0].tenantName;
         //   var clientTenantName=''
         setTenantName(res.rows[0]?res.rows[0].tenantName:"");
          setTenantDisplayName(res.rows[0].tenantDisplayName);
         setProfileDetails(res.rows[0] ? res.rows[0] : "");
         setProfilePic(res.customValues ? res.customValues.fileByte ? "data:image/jpeg;base64," + res.customValues.fileByte : "/images/icons/dotstow-icon.png" : "/images/icons/dotstow-icon.png")
         if (res.customValues.fileByte !== "") {
            setImage(res.customValues.fileByte ? "data:image/jpeg;base64," + res.customValues.fileByte : "/images/icons/dotstow-icon.png");
         } else {
            setImage("");
         }



         // sessionStorage.setItem("cmpnylogo", res.rows ? res.rows.fileByte ? "data:image/png;base64," + res.rows.fileByte : "/images/icons/dotstow-icon.png" : "/images/icons/dotstow-icon.png");
         // $("#cmpnylogo").attr("src", res.rows ? res.rows.fileByte ? "data:image/png;base64," + res.rows.fileByte : "/images/icons/dotstow-icon.png" : "/images/icons/dotstow-icon.png");

      });
   }

   const IfitIsCustomer = () => {
      var customerListReq =
      {

         "pageNumber": '1',
         "pageSize": '25',
         "sortBy": '',
         "sortDir": '',
         "id": clientId,
         "type": type,

      };



      var requestOptions = requestObject.RequestHeader(customerListReq);
      services.listofclients(requestOptions).then((res) => {
         // alert(res.rows[0].phoneMobile);
         $("#loadingforsubmit").hide();
         $("#loading").hide();
         // sessionStorage.setItem("comapnyName", res.rows[0] ? res.rows[0].tenantName : "");
         $("#comapnyName").html(res.rows[0].tenantName)
         $("#dotNumber").val(res.rows[0] ? res.rows[0].dotNumber : "");
         $("#accountId").val(res.rows[0] ? res.rows[0].id : "");
         $("#ctNumber").val(res.rows[0] ? res.rows[0].ctNumber : "");
         $("#name").val(res.rows[0] ? res.rows[0].tenantDisplayName : "");
         $("#email").val(res.rows[0] ? res.rows[0].email : "");
         $("#phoneMobile").val(res.rows[0] ? res.rows[0].phoneBusiness : "");
         $("#city_e").val(res.rows[0] ? res.rows[0].city : "");
         $("#zipcode_e").val(res.rows[0] ? res.rows[0].zip : "");
         $("#addressLine1").val(res.rows[0] ? res.rows[0].addressLine1 : "");
         $("#addressLine2").val(res.rows[0] ? res.rows[0].addressLine2 : "");
         $("#faxNumber").val(res.rows[0] ? res.rows[0].fax : "");
         $('#type').val(res.rows[0] ? res.rows[0].type : "").change()
         setStateIdC(res.rows[0] ? res.rows[0].stateId : "");
         // alert(res.rows[0].tenantName)
         //  clientTenantName=res.rows[0].tenantName;
         //   var clientTenantName=''
         setTenantName(res.rows[0].tenantName);
          setTenantDisplayName(res.rows[0].tenantDisplayName);
         setProfileDetails(res.rows[0] ? res.rows[0] : "");
         setProfilePic(res.customValues ? res.customValues.fileByte ? "data:image/jpeg;base64," + res.customValues.fileByte : "/images/icons/dotstow-icon.png" : "/images/icons/dotstow-icon.png")
         if (res.customValues.fileByte !== "") {
            setImage(res.customValues.fileByte ? "data:image/jpeg;base64," + res.customValues.fileByte : "/images/icons/dotstow-icon.png");
         } else {
            setImage("");
         }
         userList("", "1", ITEMS_PER_PAGE, "", "", setdispathcerlist, setdisplayPage, "displayItems", setCurrentPage, setTotalRecord, setTotalItems, settotalPages, searchtxt, res.rows[0].tenantName,inactiveStatus);
         $('#display_pages_in_items').on('change', function () {

            setitemperpage(this.value);
            userList("", "1", this.value, "", "", setdispathcerlist, setdisplayPage, "displayItems", setCurrentPage, setTotalRecord, setTotalItems, settotalPages, searchtxt, res.rows[0].tenantName,inactiveStatus);
         });


         // sessionStorage.setItem("cmpnylogo", res.rows ? res.rows.fileByte ? "data:image/png;base64," + res.rows.fileByte : "/images/icons/dotstow-icon.png" : "/images/icons/dotstow-icon.png");
         // $("#cmpnylogo").attr("src", res.rows ? res.rows.fileByte ? "data:image/png;base64," + res.rows.fileByte : "/images/icons/dotstow-icon.png" : "/images/icons/dotstow-icon.png");

      });
   }

   $(document).on("click", "#EditImage", function () {
      $('#selectedImage').hide();
      $('#DB_Image').show();
      $('#uploadImageDisable').prop('disabled', true);
   });

   const handlePageChange = (offset, triggerevent) => {


      userList("", offset, ITEMS_PER_PAGE, "", "", setdispathcerlist, setdisplayPage, "displayItems", setCurrentPage, setTotalRecord, setTotalItems, settotalPages, searchtxt, clientTenantName,inactiveStatus);

   }
   /////////////////////////////emp search/////////////////////////
   const searchList = (searchText) => {
      setsearchText(searchText);
      userList("", "1", ITEMS_PER_PAGE, "", "", setdispathcerlist, setdisplayPage, "displayItems", setCurrentPage, setTotalRecord, setTotalItems, settotalPages, searchText, clientTenantName,inactiveStatus);

   }
   var activestatus="false";
   const activeInctive = (event) => 
   {
       
       if(event.target.checked)
       {

       }else{
           activestatus="true";
       }
       userList("", "1", ITEMS_PER_PAGE, "", "", setdispathcerlist, setdisplayPage, "displayItems", setCurrentPage, setTotalRecord, setTotalItems, settotalPages, searchtxt, clientTenantName,activestatus);

       setInactiveStatus(activestatus);
   }

   return (
      <>
         {loadingComponent}
         {LoadingComponentForSubmit}
         <div class="main-panel">
            <div class="content-wrapper" style={{ display: "block;" }}>
               <div class="row">
                  {/* <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                     <h4 class="font-weight-bold my-4 text-uppercase" id="clientProfile">Client Name: <span style={{fontWeight:"normal"}}>{profileDetails.tenantDisplayName ? profileDetails.tenantDisplayName : "N/A"}</span></h4>
                  </div> */}
                  <div class="col-lg-11 col-md-12 m-auto mb-2 grid-margin stretch-card d-md-flex">
                     <div class="card profile-wrap">
                        <div class="card-body p-0 " style={{ padding: "0px;" }}>
                           <div class="row">
                              <div class="col-lg-3 col-md-12 pr-0 border-right">
                                 <ul class="nav nav-pills nav-pills-vertical nav-pills-info border-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <li class="nav-item">
                                       <a class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="false">
                                          <i class="mr-2"> <img src="/images/icons/user.png" alt="" /></i>
                                          Client Info
                                       </a>
                                    </li>
                                    {/* {roles.match("Superadmin")? */}
                                    <li class="nav-item">
                                       <a class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="true">
                                          <i class="mr-2"> <img src="/images/icons/user.png" alt="" /></i>
                                          Users
                                       </a>
                                    </li>
                                    {/* :''}  */}
                                 </ul>
                              </div>
                              <div class="col-lg-9 col-md-12 pl-0 ">
                                 <div class="tab-content tab-content-vertical" id="v-pills-tabContent">
                                    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                       <div class="media-body">
                                          <h5 class="mt-0">Client Name: <span style={{ fontWeight: "normal" }} className="text-capitalize">{profileDetails.tenantDisplayName ? profileDetails.tenantDisplayName : "N/A"}</span>
                                             {/* <a style={{ marginRight: "10px", float: "right", marginTop: "-8px" }}  href={"/AddMydocs/" + sessionStorage.getItem("userId")}  >Active</a> */}
                                             {/* <p for="vehicle1" style={{ marginRight: "10px", float: "right", marginTop: "2px" }}>Active</p>
                                             {(() => {

                                                if (profileDetails.isActive === true) {
                                                   return (<>
                                                      <input type="checkbox" id="activeClient" onClick={() => activeClient()} name="activeClient" checked={profileDetails.isActive} style={{ marginRight: "10px", float: "right", marginTop: "-8px" }} />
                                                   </>);
                                                } else {
                                                   return (<><input type="checkbox" id="activeClient" onClick={() => activeClient()} name="activeClient" checked={profileDetails.isActive} style={{ marginRight: "10px", float: "right", marginTop: "-8px" }} /></>);
                                                }


                                             })()} */}
                                             {/* <input type="checkbox" id="activeClient"   name="activeClient"  onChange={activeClient()} style={{ marginRight: "10px", float: "right", marginTop: "-8px" }}/> */}
                                          </h5>
                                          <div class="pro-info-wrap pb-5">
                                             <div class="pro-info-item">Client Logo</div>
                                             <div class="pro-info-item d-flex flex-column">
                                                <img src={profilePic ? profilePic : "/images/dotstow-icon.png"} class="avatar-prof mb-2" alt="" id="posterURL" />
                                                <small>Max File Size : 5MB</small>
                                                <small>Allowed file types: JPG/PNG</small>
                                             </div>
                                             <div class="pro-info-item">
                                                <span class="edit-cust" id="EditImage"><a href="#" data-toggle="modal" data-target="#exampleModal" data-backdrop="static" data-keyboard="false">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a>
                                                </span>
                                             </div>
                                          </div>
                                          <div class="pro-info-wrap align-items-center">
                                             <div class="pro-info-item">Status</div>
                                             <div class="pro-info-item text-capitalize mr-3">
                                             <ToggleSwitch id="switch" checked={profileDetails ?profileDetails.isActive ? true : false:false} onChange={checked => activeClient(checked)} />
                                                {/* {profileDetails.isActive ? "Active" : "Inactive"}  {profileDetails.isActive ?<a class="btn btn-outline-primary text-uppercase ml-4" onClick={() => activeClient("false")}>Inactive</a>:<a class="btn btn-outline-primary text-uppercase ml-4" onClick={() => activeClient("true")}>Active</a>} */}
                                                
                                                </div>
                                            
                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Name</div>
                                             <div class="pro-info-item text-capitalize">{profileDetails.tenantDisplayName ? profileDetails.tenantDisplayName : "N/A"}</div>
                                             <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                <img src="/images/icons/edit-icon.svg" alt="" class="mr-1" />Edit</a>
                                             </span>
                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Type</div>
                                             <div class="pro-info-item" className="text-capitalize">{profileDetails.type ? profileDetails.type : "N/A"}</div>
                                             {/* <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div> */}
                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Address line1</div>
                                             <div class="pro-info-item">{profileDetails.addressLine1 ? profileDetails.addressLine1 : "N/A"}</div>
                                             {/* <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div> */}
                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Address line2</div>
                                             <div class="pro-info-item">{profileDetails.addressLine2 ? profileDetails.addressLine2 : "N/A"}</div>
                                             {/* <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div> */}
                                          </div>

                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">City</div>
                                             <div class="pro-info-item">{profileDetails.city ? profileDetails.city : "N/A"}</div>
                                          </div>

                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">State</div>
                                             <div class="pro-info-item">{profileDetails.stateName ? profileDetails.stateName : "N/A"}</div>
                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Zip code</div>
                                             <div class="pro-info-item">{profileDetails.zip ? profileDetails.zip : "N/A"}</div>
                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Phone number</div>
                                             <div class="pro-info-item">{profileDetails.phoneBusiness ? profileDetails.phoneBusiness : "N/A"}</div>
                                             {/* <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div> */}
                                          </div>
                                          <div class="pro-info-wrap position-relative">
                                             <div class="pro-info-item">Fax number</div>
                                             <div class="pro-info-item">{profileDetails.fax ? profileDetails.fax : "N/A"}</div>
                                             {/* <div class="pro-info-item">
                                                   <span class="edit-cust enb-not-email">
                                                   <input type="checkbox" id="emailNotification" name="emailNotification" /> 
                                                      <span>Enable <br/> notification</span>
                                                   </span>
                                                </div> */}
                                          </div>
                                          <div class="pro-info-wrap position-relative">
                                             <div class="pro-info-item">Email</div>
                                             <div class="pro-info-item">{profileDetails.email ? profileDetails.email : "N/A"}</div>
                                             {/* <div class="pro-info-item">
                                                   <span class="edit-cust enb-not-email">
                                                   <input type="checkbox" id="emailNotification" name="emailNotification" /> 
                                                      <span>Enable <br/> notification</span>
                                                   </span>
                                                </div> */}
                                          </div>
                                          <div class="p-3 mt-2 mb-2" style={{ marginLeft: "0px" }}>
                                             <h4>ID Numbers</h4>
                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item"> <span class="lableUpper">DOT</span> number</div>
                                             <div class="pro-info-item">{profileDetails.dotNumber ? profileDetails.dotNumber : "N/A"}</div>

                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item"> <span class="lableUpper">CT</span> number</div>
                                             <div class="pro-info-item">{profileDetails.ctNumber ? profileDetails.ctNumber : "N/A"}</div>

                                          </div>

















                                          {/* <div class="pro-info-wrap">
                                             <div class="pro-info-item">State</div>
                                             <div class="pro-info-item">{profileDetails.stateName ? profileDetails.stateName : "N/A"}</div>
                                            <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div>
                                          </div>
                                          <div class="pro-info-wrap">
                                             <div class="pro-info-item">Zip</div>
                                             <div class="pro-info-item">{profileDetails.zip ? profileDetails.zip : "N/A"}</div>
                                              <div class="pro-info-item">
                                                   <span class="edit-cust"><a href="#" data-toggle="modal" data-target="#exampleModal2">
                                                   <img src="/images/icons/edit-icon.svg" alt="" class="mr-1"/>Edit</a>
                                                   </span>
                                                </div>
                                          </div> */}
                                       </div>
                                    </div>

                                    <div class="tab-pane fade " id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                       <div class="media-body">
                                          <h5 class="mt-0">Users</h5>
                                          <div class="row mb-1">
                                             <div class="col-lg-11 col-md-12 m-auto mb-2 ">
                                                <div class="card">
                                                   <div class="d-flex flex-column flex-md-row justify-content-md-between justify-content-start align-items-center p-3">
                                                      {/* <div class="search position-relative align-self-start mb-1 mt-1 mr-4"> */}
                                                         {/* <input type="search" id="gsearch" name="gsearch" placeholder="Search by Name/Emp ID" class="pl-5"/>
                                                            <a href="#" class="search-icon">
                                                                <img src="/images/icons/search.svg" alt=""/>
                                                            </a> */}
                                                            <div class="d-flex justify-content-start">
                                                     <div class="search position-relative">
                                                         <Search
                                                            placeholderName="Search by EMP Id / Name / Role"
                                                            onSearch={value => {
                                                               searchList(value);
                                                            }}

                                                         />
                                                         <a href="#" class="search-icon">
                                                            <img src="/images/icons/search.svg" alt="" />
                                                         </a>
                                                      </div>
                                                      <div class="d-flex align-items-center">
                  <input type="checkbox"
                                                                           onChange={(e) => activeInctive(e) }
                                                                            id="inactive"
                                                                            name="inactive"
                                                                           
                                                                            style={{marginLeft:"21px"}} /> <span style={{ marginLeft: "10px"}}>
                                                                           Inactive records only
                                                                          </span>
                                                                          </div>
                                                                          </div>
                                                      <div class="d-flex align-self-start mb-1 mt-1">
                                                      <a type="button" class="btn btn-success" onClick={() => redirect("/addusers/"+clientTenantName+"/"+clientTenantDisplayName+"/"+type)}>Add Employee</a>
                                                      </div>
                                                   </div>
                                                   <div class="card-body pt-0 pb-0">
                                                      <div class="row">
                                                         <div class="col-12">
                                                            <div class="table-responsive  border-top">
                                                               <table class="table">
                                                                  <thead>
                                                                     <tr>
                                                                        <th>Emp ID</th>
                                                                        <th>Name</th>

                                                                        {/* <th>Email id</th>
                                                                     <th>Contact Number</th> */}
                                                                        <th>Role</th>
                                                                        <th style={{ width: "100px" }}>Actions</th>

                                                                     </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                     {
                                                                        dispatcherList ? dispatcherList.map((data, index) => (
                                                                           <tr>
                                                                              <td>{data.id ? data.id : "--"}</td>
                                                                              <td class="text-capitalize">{data.firstName ? data.firstName : ""} {data.lastName ? data.lastName : ""} </td>

                                                                              {/* <td>{data.email ? data.email : "--"}</td>
                                                                           <td>{data.phoneNumber ? data.phoneNumber : "--"}</td> */}
                                                                              <td>{data.securityRoleName ? data.securityRoleName : "--"}</td>
                                                                              <td><a  onClick={() => redirect("/userDetails/" + data.id +"/"+ clientTenantName+"/"+clientTenantDisplayName+"/"+type)} class="btn btn-outline-primary text-uppercase">View</a>  <a  onClick={() => redirect("/EditUser/" + data.id +"/"+clientTenantName+"/"+clientTenantDisplayName+"/"+type)}  class="btn btn-outline-primary text-uppercase">Edit</a></td>
                                                                           </tr>
                                                                        )) : ""
                                                                     }
                                                                  </tbody>
                                                               </table>
                                                            </div>
                                                            {dispatcherList ? dispatcherList.map((data, index) => { }) : <div class="no-data"><div class="m-auto"><img src="/images/no-data.png" alt="" /></div><span class="mt-2">No Data Available to Display</span>
                                                            </div>}
                                                         </div>
                                                      </div>
                                                   </div>
                                                   {dispatcherList ? <div class="d-flex align-items-center justify-content-between p-3">
                                                      <div class="d-flex">
                                                         <div class="dropdown show mr-3 ">
                                                            <select class="form-control custome-select btn gray-mid-color dropdown-toggle" href="#" role="button" id="display_pages_in_items" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                               {
                                                                  displayPage.map((data, index) => (
                                                                     <option value={data}>{data}</option>
                                                                  ))
                                                               }
                                                            </select>
                                                            {/* <a class="btn gray-mid-color dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                   25
                                                   </a>
                                                                <div class="dropdown-menu noi" aria-labelledby="dropdownMenuLink">
                                                                    <a class="dropdown-item" href="#">25</a>
                                                                    <a class="dropdown-item" href="#">50</a>
                                                                </div> */}
                                                         </div>
                                                         <span class="d-flex align-items-center pagenation-mob">{currentPage * ITEMS_PER_PAGE <= totalRecors ? currentPage * ITEMS_PER_PAGE : totalRecors} out of {totalRecors} Records Displayed</span>
                                                      </div>
                                                      <nav aria-label="Page navigation example">
                                                         <Pagination
                                                            total={totalRecors}
                                                            itemsPerPage={ITEMS_PER_PAGE}
                                                            currentPage={currentPage}
                                                            //onPageChange={page => setCurrentPage(page)}
                                                            onPageChange={handlePageChange}
                                                         />

                                                      </nav>
                                                      {/* <nav aria-label="Page navigation example">
                                                            <ul class="pagination mb-0">
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Previous">
                                                                        <span aria-hidden="true">«</span>
                                                                        <span class="sr-only">Previous</span>
                                                                    </a>
                                                                </li>
                                                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                                <li class="page-item">
                                                                    <a class="page-link" href="#" aria-label="Next">
                                                                        <span aria-hidden="true">»</span>
                                                                        <span class="sr-only">Next</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </nav> */}
                                                   </div> : ""}
                                                </div>
                                             </div>
                                          </div>

                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <!-- Modal --> */}
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Client Logo</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <input type="file" name="upload-file" class="w-100 p-3" id="upload-file" onChange={onChange} accept="image/jpeg,image/png,image/jpeg" />
                        <div class="row mb-2" id="selectedImage">
                           <div class="col-12">

                              <div class="image-upload-plg">
                                 {/* <input type="file" class="w-100 p-3 mb-3" onChange={onChange} accept="image/jpeg,image/png,image/jpeg" /> */}
                                 <Cropper
                                    style={{ height: 300, width: "100%" }}
                                    zoomTo={0}
                                    aspectRatio={1}
                                    guides={false}
                                    dragMode={"move"}
                                    src={image ? image : "/images/icons/dotstow-icon.png"}
                                    onInitialized={(instance) => {
                                       setCropper(instance);
                                    }}
                                 />
                              </div>


                           </div>

                           <div>
                              <div className="box" style={{ width: "100%", float: "right" }}>

                                 <div
                                    className="img-preview"
                                    style={{ float: "right" }}
                                 />
                              </div>

                           </div>
                        </div>
                        <div class="row mb-2" id="DB_Image">
                           <div className="m-auto mt-2">
                              <img src={image ? image : "/images/icons/dotstow-icon.png"} style={{ width: "240px", height: "240px", marginTop: "20px" }} ></img>

                           </div>
                        </div>
                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" id="btncloseImage">Close</button>
                        <button type="button" class="btn btn-primary" id="uploadImageDisable" onClick={updateProfileImage}>Save changes</button>
                     </div>
                  </div>
               </div>
            </div>



            <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Client Info</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="profileModelClose">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <form onSubmit={handleSubmit(updateProfile)}>
                        <div class="modal-body">
                           <input type="hidden" id="accountId" />
                           <div class="row">
                              <div class="col-md-12">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Name<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="name" aria-describedby="emailHelp" {...register('name', { required: true })} />
                                    {errors.name && errors.name.type === "required" && <span class="error">Name is required</span>}
                                 </div>
                              </div>
                              <div class="col-6">
                              <div class="form-group">
                                       <label for="exampleInputEmail1">Type <span class="star">*</span></label>
                                            <select class="form-control custome-select" disabled id="type" >
                                                <option value="">Select</option>
                                                <option value="garage">Garage</option>
                                                <option value="fleet">Fleet</option>
                                            </select>
                                   
                                    </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Address Line 1<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" maxLength="50" id="addressLine1" aria-describedby="emailHelp" {...register('addressLine1', {
                                       required: true
                                    })} />
                                    {errors.addressLine1 && errors.addressLine1.type === "required" && <span class="error">Address is required</span>}

                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Address Line 2</label>
                                    <input type="text" class="form-control custome-input" maxLength="50" id="addressLine2" aria-describedby="emailHelp" />
                                 </div>
                              </div>
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">City <span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="city_e" minLength="2" maxLength="50" defaultValue='city' onChange={e => setFieldName(e.target.value)}    {...register("City", {
                                       required: "City is required",
                                       maxLength: {
                                          value: 50,
                                          message: "City shouldn't exceed the max length 50 characters"
                                       },
                                       minLength: {
                                          value: 2,
                                          message: "City atleast 2 characters"
                                       },
                                       // pattern: {
                                       //    value: /^[a-zA-Z\s]*$/,
                                       //    message: "City is allow only character's"
                                       // }
                                    })} />
                                    <ErrorMessage
                                       errors={errors}
                                       name="City"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleFormControlSelect1">State<span class="star">*</span></label>

                                    {/* <select id="inputState"   class="form-select  custome-select" name="state">
                                                <option selected value=' '>Select State</option>
                                                {
                                                    states.map((state, index) => (
                                                        <option value={state.id}>{state.name}</option>
                                                    ))
                                                }


                                          </select> */}
                                    <select class="form-control custome-select" id="state_e" defaultValue={"stateIdC"}  >

                                       <option value="">Select state</option>
                                       {
                                          states ? states.map((state, index) => (
                                             <option value={state.id} selected={state.id == stateIdC ? "selected" : ''}>{state.name}</option>
                                          )) : <option value=""></option>
                                       }
                                    </select>

                                 </div>
                              </div>
                              <div class="col-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Zip Code <span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="zipcode_e" defaultValue='12345' maxLength="5" onChange={e => setFieldName(e.target.value)}    {...register("zip", {
                                       required: "Zip code is required",
                                       pattern: {
                                          value: /^[0-9]{5}(-[0-9]{1})?$/,
                                          message: "Zip code should be 5 digits"
                                       },

                                    })} />
                                    <ErrorMessage
                                       errors={errors}
                                       name="zip"
                                       class="invalid-feedback"
                                       render={({ messages }) => {

                                          return messages
                                             ? Object.entries(messages).map(([type, message]) => (
                                                <p key={type} class="error">{message}</p>
                                             ))
                                             : null;
                                       }}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div class="row">
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Phone number<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="phoneMobile" aria-describedby="emailHelp" {...register('phoneMobile', { required: true, maxLength: 12, minLength: 12 })} />
                                    {errors.phoneMobile && errors.phoneMobile.type === "required" && <span class="error">Phone number is required</span>}
                                    {errors.phoneMobile && errors.phoneMobile.type === "maxLength" && <p class="error">Phone number should be 10 digits</p>}
                                    {errors.phoneMobile && errors.phoneMobile.type === "minLength" && <p class="error">Phone number should be 10 digits</p>}
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Fax number</label>
                                    <input type="text" class="form-control custome-input" id="faxNumber" maxLength={12} onChange={(e) => {
                                       if (e.target.value.length < 13) {
                                          var cleaned = ("" + e.target.value).replace(/\D/g, "");

                                          let normValue = `${cleaned.substring(0, 3)}${cleaned.length > 3 ? "-" : ""
                                             }${cleaned.substring(3, 6)}${cleaned.length > 6 ? "-" : ""
                                             }${cleaned.substring(6, 11)}`;

                                          $("#faxNumber").val(normValue);
                                       }
                                    }} />

                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1">Email<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="email" aria-describedby="emailHelp" {...register('email', {
                                       required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                    })} readOnly />
                                    {errors.email && errors.email.type === "required" && <span class="error">Email is required</span>}
                                    {errors.email && errors.email.type === "pattern" && <span class="error">Invalid email address</span>}
                                 </div>
                              </div>
                           </div>
                           {/* <hr/> */}
                           <div class="p-3 mt-2 mb-2" style={{ marginLeft: "-15px" }}>
                              <h4>ID Numbers</h4>
                           </div>
                           <div class="row">
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1"><span class="lableUpper">DOT</span> Number<span class="star">*</span></label>
                                    <input type="text" class="form-control custome-input" id="dotNumber" maxLength="20" aria-describedby="emailHelp" {...register('dotNumber', { required: true })} />
                                    {errors.dotNumber && errors.dotNumber.type === "required" && <span class="error">DOT number is required</span>}
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="form-group">
                                    <label for="exampleInputEmail1"><span class="lableUpper">CT</span> Number</label>
                                    <input type="text" class="form-control custome-input" id="ctNumber" maxLength="20" aria-describedby="emailHelp" />
                                    {/* {errors.ctNumber && errors.ctNumber.type === "required" && <span class="error">CT number is required</span>} */}
                                 </div>
                              </div>
                           </div>

                        </div>

                        <div class="modal-footer">
                           <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                           <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>

            {/* <!-- doc edit --> */}
            <div class="modal fade" id="exampleModaldoc" tabindex="-1" aria-labelledby="exampleModalLabeldoc" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Photo</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <div class="m-auto">
                           <div class="doc-img-wrap">
                              hhhhhhhhhhhhhhh
                           </div>

                        </div>


                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                     </div>
                  </div>
               </div>
            </div>

            <div class="modal fade" id="exampleModallic" tabindex="-1" aria-labelledby="exampleModalLabeldlic" aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Edit Document</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <div class="m-auto">
                           <div class="doc-img-wrap">

                           </div>

                        </div>

                        <div class="row">

                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Licence Number</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                              </div>
                           </div>



                           <div class="col-6">
                              <div class="form-group">
                                 <label for="exampleInputEmail1">Expiry Date</label>
                                 <input type="email" class="form-control custome-input" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                 <i> <img src="/images/icons/d" alt="" /></i>

                              </div>
                           </div>





                        </div>
                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
};

ClientDetails.propTypes = {};

ClientDetails.defaultProps = {};

export default ClientDetails;
