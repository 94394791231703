import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export default function CarouselComponent() {
    return (
        <div class="carousel-wrapper">
            <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false}>
                <div>
                   <img src="/img/banner-1.png" class="img-fluid"/>
                   <div style={{position: "absolute",left: "0",top: "0",width: "100%",height: "100%"}}>
                        <div class="container" style={{position: "relative",height: "100%"}}>
                            <div class="carousel-caption d-md-block animate__animated animate__fadeInUp">
                                <h3>Simplify the vehicle management process</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                  <img src="/img/banner-2.png" class="img-fluid"/>
                   <div style={{position: "absolute",left: "0",top: "0",width: "100%",height: "100%"}}>
                        <div class="container" style={{position: "relative",height: "100%"}}>
                            <div class="carousel-caption d-md-block animate__animated animate__fadeInUp">
                                <h3>Keep documents available to those who need them</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div>

                   <img src="/img/banner-3.png" class="img-fluid"/>
                    <div style={{position: "absolute",left: "0",top: "0",width: "100%",height: "100%"}}>
                        <div class="container" style={{position: "relative",height: "100%"}}>
                            <div class="carousel-caption d-md-block animate__animated animate__fadeInUp">
                                <h3>A compliant and safe operating fleet is the goal</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel>
        </div>
    );
}