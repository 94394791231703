import React, { history, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
import services from "../../../services/services";
import { requestObject } from "../../../utility/requestObject";
import CheckAuthentication from "../../../utility/checkAuthentication";

// const HeaderBlock = ({cid}) => {
const HeaderBlock = ({ cids }) => 
{

  const [posterImage, setPosterImage] = useState([]);
  const [address, setAddress] = useState([]);
  //   var id=localStorage.getItem("customerId");
  //   if(id==="null"||id===null)
  //   {
  //    id=localStorage.getItem("cid");
  //   }

  const { cid, customerId, id } = useParams();

  var getCustomerId = "";
  if (cids == undefined) {
    getCustomerId = cid || customerId || id;

    if (getCustomerId == undefined) {
      getCustomerId = localStorage.getItem("customerId");
    }
  } else {
    getCustomerId = cids || localStorage.getItem("customerId");
  }

  var cusId = getCustomerId;

  const navigate = useNavigate();

  useEffect(() => {
    if (CheckAuthentication) {
      const myTimeout = setTimeout(
      getCustomer_Detils(cusId)
    , 500);
    } else {
      sessionStorage.clear();
      localStorage.clear();
      //navigate("/login");
      window.location.href = "/login";
    }
  }, []);

  ////////getcustomer_details from aws////////////////////////
  const getCustomer_Detils = (cusId) => 
  {
    var customerListReq = 
    {
      id: cusId,
    };

    var requestOptions = requestObject.RequestHeader(customerListReq);
    services.getCustomer_details(requestOptions).then((res) => 
    {
      $("#loading").hide();
      // var address=res.rows.addressLine1!==""?res.rows.addressLine1:"N/A"+"<br/>"+res.rows.addressLine2!==""?res.rows.addressLine2:"N/A"+"<br/>"+res.rows.city!==""?res.rows.city:"N/A";
     
    
       if(res.rows!=null)
       {
       
        $("#customerNames").html(res.rows.customerName);
        $("#dotNumber").html(res.rows.dotNumber);
        $("#customerPhone").html(res.rows.phoneMobile);
        $("#messages").html(res.rows.email);
        setAddress(res.rows);
       }else{
        $("#customerNames").html("N/A");
        $("#dotNumber").html("N/A");
        $("#customerPhone").html("N/A");
        $("#messages").html("N/A");
        setAddress("");
       }
        
     
    
      // $("#address").html(address);
      setPosterImage(res.customValues);
      $("#vehicleImage").attr(
        "src",
        res.customValues
          ? res.customValues.vehicleImageFileByte
            ? "data:image/png;base64," + res.customValues.vehicleImageFileByte
            : "/images/no-vehicle-image.png"
          : "/images/no-vehicle-image.png"
      );
      // $("#posterURL").attr("src",res.customValues?"data:image/png;base64,"+res.customValues.fileByte:"/images/transport-logo.jpg");

      $("#customerImage").attr(
        "src",
        res.customValues
          ? res.customValues.customerLogoFileByte
            ? "data:image/png;base64," + res.customValues.customerLogoFileByte
            : "/images/transport-logo.jpg"
          : "/images/transport-logo.jpg"
      );
      //$("#posterURL").attr("src",res.customValues?"data:image/png;base64,"+res.customValues.fileByte:"/images/transport-logo.jpg");
    });
  };
  return (
    <>
      <div class="card p-3">
        <div class="banner-owner">
          <div class="owner-logo">
            <img id="customerImage" />
            <div class="owner-name">
              <h4 id="customerNames"></h4>
            </div>
          </div>
          <div class="banner-contact-info bg-white d-flex flex-column flex-sm-row">
            <div class="row clearfix w-100">
              <div class="col-md-5">
                <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                  <img src="/images/icons/map-icon.svg" alt="" class="mr-2" />
                  <div style={{ minWidth: "240px" }}>
                    {/* <span id="address"></span> */}

                    <span>
                      {address ? address.addressLine1 : ""}
                      {address ? (address.addressLine1 ? "," : "") : ""}
                      <span>&nbsp;</span>
                      {address ? address.addressLine2 : ""}
                      {address ? (address.addressLine2 ? "," : "") : ""}
                      <span>&nbsp;</span>
                      <br />
                      {address ? address.city : ""}
                      {address ? (address.city ? "," : "") : ""}
                      <span>
                        {" "}
                        <span>
                          {address ? address.stateName : ""}
                          {address ? (address.stateName ? "," : "") : ""}
                        </span>
                        &nbsp;
                      </span>
                      {address ? address.zip : ""}
                      {address ? (address.zip ? "." : "") : ""}
                     
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                  <img src="/images/icons/phone-icon.svg" alt="" class="mr-2" />
                  <span id="customerPhone"></span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="text-left d-flex align-items-center banner-info mb-1 mt-1">
                  <img
                    src="/images/icons/message-icon.svg"
                    alt=""
                    class="mr-2"
                  />
                  <span id="messages"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

HeaderBlock.propTypes = {};

HeaderBlock.defaultProps = {};

export default HeaderBlock;
