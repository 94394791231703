import $ from 'jquery';
import Swal from "sweetalert2";
import { requestObject } from './requestObject';
import services from '../services/services';
import { offset } from '@popperjs/core';

export const Sendtocustomer= (checked,naviationfrom) => 
{
   var itemIdsForGlobal=[];
   var contactEmails=[];
   
   $('input:checkbox[name=documentIds]:checked').each(function() 
   {
     
     itemIdsForGlobal.push($(this).val());
  
   });

   $('input:checkbox[name=contactListemails]:checked').each(function() 
   {
     
      contactEmails.push($(this).val());
  
   });
  
  if(itemIdsForGlobal.length>0)
  { 
     
  if(contactEmails.length>0||checked.length>0)
  {


 
   //  $("#loading").show();
   var requestParam=
   {

      "fkCustomerId":localStorage.getItem("customerId")=="null"||localStorage.getItem("customerId")==null?localStorage.getItem("cid"):localStorage.getItem("customerId"),
      "emails":contactEmails,
      "documentIds":itemIdsForGlobal
   }


   $("#loadingforsubmit").show();
   var requestOptions = requestObject.RequestHeader(requestParam);

   services.sendtoCustomerAPI(requestOptions).then((res) => 
   {

      $("#loadingforsubmit").hide();
      // $("#loading").hide();
      $('input:checkbox[name=documentIds]').prop('checked', false).attr('checked', 'checked');
      $('input:checkbox[name=contactListemails]').prop('checked', false).attr('checked', 'checked');
      if(res.success)
      {
         Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text: res.message,
   
            showConfirmButton: true
   
         }).then((result) => {
            if (result.isConfirmed) {
               window.location.reload();
               sessionStorage.setItem("naviationfrom",naviationfrom);
            }
         });

      }else{
         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: res.message,
   
            showConfirmButton: true
   
         }).then((result) => {
            if (result.isConfirmed) {
               
            }
         });
      }
   

   });
}else{
   Swal.fire({
      position: 'center',
      icon: 'error',
      title: "",
      text: "Please choose the at Least one contact",

      showConfirmButton: true

   }).then((result) => {
      if (result.isConfirmed) {

      }
   });
}
  }else{

   Swal.fire({
      position: 'center',
      icon: 'error',
      title: "",
      text: "Please choose the document",

      showConfirmButton: true

   }).then((result) => {
      if (result.isConfirmed) {

      }
   });
  }
  
   
}

export const SendtocustomerTO= (checked,taskOrderSelectedID) => 
{
   // alert(taskOrderSelectedID);
   // var itemIdsForGlobal=taskOrderSelectedID;
   // alert(itemIdsForGlobal);
   // var itemIdsForGlobal=[];
   var contactEmails=[];
   
   // $('input:checkbox[name=documentIds]:checked').each(function() 
   // {
     
   //   itemIdsForGlobal.push($(this).val());
  
   // });

   $('input:checkbox[name=contactListemails]:checked').each(function() 
   {
     
      contactEmails.push($(this).val());
  
   });
  
  if(taskOrderSelectedID!=null)
//   if(itemIdsForGlobal.length>0)
  { 
     
  if(contactEmails.length>0||checked.length>0)
  {


 
   //  $("#loading").show();
   var requestParam=
   {

      "fkCustomerId":localStorage.getItem("customerId")=="null"||localStorage.getItem("customerId")==null?localStorage.getItem("cid"):localStorage.getItem("customerId"),
      "emails":contactEmails,
      // "documentIds":itemIdsForGlobal
      "taskOrderId":taskOrderSelectedID
   }


   $("#loadingforsubmit").show();
   var requestOptions = requestObject.RequestHeader(requestParam);

   services.sendtoCustomerAPI(requestOptions).then((res) => 
   {

      $("#loadingforsubmit").hide();
      // $("#loading").hide();
      $('input:checkbox[name=documentIds]').prop('checked', false).attr('checked', 'checked');
      $('input:checkbox[name=contactListemails]').prop('checked', false).attr('checked', 'checked');
      if(res.success)
      {
         Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text: res.message,
   
            showConfirmButton: true
   
         }).then((result) => {
            if (result.isConfirmed) {
               // window.location.reload();
            }
         });

      }else{
         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: res.message,
   
            showConfirmButton: true
   
         }).then((result) => {
            if (result.isConfirmed) {
   
            }
         });
      }
   

   });
}else{
   Swal.fire({
      position: 'center',
      icon: 'error',
      title: "",
      text: "Please choose the at least one contact",

      showConfirmButton: true

   }).then((result) => {
      if (result.isConfirmed) {

      }
   });
}
  }else{

   Swal.fire({
      position: 'center',
      icon: 'error',
      title: "",
      text: "Please choose the task order",

      showConfirmButton: true

   }).then((result) => {
      if (result.isConfirmed) {

      }
   });
  }
  
   
}

export const sendtocustomerCheck = (documentIds) => 
{
      
         $('.itemid'+documentIds).attr('checked', true);
}

////////////////Delete Dcouments//////////////////////////
export const deleteDocuments = (documentIds,unitNumber,title) => 
{

   Swal.fire({
      title: 'Do you really want to delete this document?',
      text: unitNumber+" : "+title,
   
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
  }).then(function (results) {
      // Called if you click Yes.
      if(results.isConfirmed) {

         $("#loadingforsubmit").show();
         var requestParam=
         {
      
            "id":documentIds
         }
         var requestOptions = requestObject.RequestHeader(requestParam);
      
         services.deleteDocumentsFromDb(requestOptions).then((res) => 
         {
            $("#loadingforsubmit").hide();
            if(res.success)
            {
               Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: "",
                  text: res.message,
         
                  showConfirmButton: true
         
               }).then((result) => {
                  if (result.isConfirmed) {
                     window.location.reload();
                  }
               });
      
            }else{
               Swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: "",
                  text: res.message,
         
                  showConfirmButton: true
         
               }).then((result) => {
                  if (result.isConfirmed) {
      
                     window.location.reload();
         
                  }
               });
            }
         });
        
      }
  },
  function (no) {
      // Called if you click No.
      if (no == 'cancel') {
          
      }
  });


  
       
}

/////////////////deleteInspection/////////////////////////////////
export const deleteInspectionDocuments = (inspectionIds) => 
{
   var requestParam=
   {

      "id":inspectionIds
   }


   $("#loadingforsubmit").show();
   var requestOptions = requestObject.RequestHeader(requestParam);

   services.deleteInspectionDocumentsFromDb(requestOptions).then((res) => 
   {
      $("#loadingforsubmit").hide();
      if(res.success)
      {
         Swal.fire({
            position: 'center',
            icon: 'success',
            title: "",
            text: res.message,
   
            showConfirmButton: true
   
         }).then((result) => {
            if (result.isConfirmed) {
               window.location.reload();
            }
         });

      }else{
         Swal.fire({
            position: 'center',
            icon: 'error',
            title: "",
            text: res.message,
   
            showConfirmButton: true
   
         }).then((result) => {
            if (result.isConfirmed) {

               window.location.reload();
   
            }
         });
      }
   });
       
}